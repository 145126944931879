import React from "react";
import { Text, View, StyleSheet , Image } from "@react-pdf/renderer";

import procurement from '../../../../assets/quality.png';
import livinus from '../../../../assets/livinus_signature.png';
import { Typography } from "@material-ui/core";

import moment from "moment"

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginBottom: 5,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    footer: {
        marginTop: 30,
        fontSize: 11,
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.2,
        position:"absolute",
        bottom: 15,
        left: 20,
    },
    rightSection: {
        textAlign: "right",
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.7,
        display:"block",
        width: "100%",
    },
    caps: {
        fontWeight: 800,
        fontSize: 11,
    },
    mb_10: {
        fontSize: 10,
        marginBottom: 5,
        fontWeight:"bold",
    },

    textLeft: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 0,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 12,
    },
    textRight: {
        textAlign: "right",
        fontSize: 9,
    },
    textBold: {
        fontWeight: "bold",
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
    shipTo: {
        textAlign: "right",
        fontSize: 10,
        marginTop: -60,
    },
    table: {
        padding: 0,
        margin: 0,
        fontSize: 10,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    bodyrow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        // borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    tablerow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        margin: 0,
    },
    th: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#aaa",
        borderLeft: '1px solid #000',
        color: "#000"
    },
    thtransparent: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        paddingLeft: 0,
        margin: 0,
        color: "#000"
    },
    thd: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        color: "#444",
        textAlign:'right',
        borderRight: '1px solid #000',
    },
    thtrans: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #000',
    },
    foot: {
        backgroundColor: "#fff",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    td: {
        overflow: 'hidden',
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        border: "1px solid #000",
        borderTopWidth: 0,
    },
    desc: {
        width: "20%",
    },
    desc10: {
        width: "10%",
    },
    desc90: {
        width: "90%",
        borderRight: '1px solid #000',
    },
    desc100: {
        width: "100%",
    },
    desc25: {
        width: "25%",
    },
    desc2: {
        width: "30%",
    },
    desc3: {
        width: "80%",
    },
    desc60: {
        width: "60%",
    },
    desc20right: {
        width: "20%",
        textAlign: "right",
    },
    desc4: {
        width: "40%",
        textAlign: "right",
    },
    desc5: {
        width: "50%",
        textAlign: "right",
    },
    desc40: {
        width: "40%",
    },
    desc30: {
        width: "30%",
    },
    desc15: {
        width: "15%",
        textAlign:"center",
    },
    desc12: {
        width: "15%",
        textAlign:"right",
    },
    desc30left: {
        width: "50%",
        textAlign:"left",
    },
    desc70left: {
        width: "50%",
        textAlign:"left",
    },
});


const TableHead = () => {
    return (
        <>
            <View style={[styles.bodyrow]}>
                <Text style={[styles.th, {width: '100%', backgroundColor: '#CCEEFF', fontSize: 12, fontWeight: 'bold', borderRight:'1px solid #444'}]}>
                    WORK COMPLETION DETAILS
                </Text>
            </View>
            <View style={[styles.bodyrow]}>
                <Text style={[styles.th, styles.desc10]}>SN</Text>
                <Text style={[styles.th, styles.desc90]}>Description</Text>
            </View>
        </>
    )
}

export const WCPage = ({ workcompletion }) => {

    const ReceivingPartOne = () => {
        return (
            <>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Project Description:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{workcompletion && workcompletion?.projectDescription}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Purchase Order Number:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{workcompletion && workcompletion?.purchaseOrderNo}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Vendor Name:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{workcompletion && workcompletion?.vendorName}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Contact Name:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{workcompletion && workcompletion?.vendorContactPerson}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Vendor Phone Number:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{workcompletion && workcompletion?.vendorPhone}</Text>
                </View>
                
            </>
        )
    }

    return (
        <>
            <View style={{position: "relative"}}>
                <Image
                    src={procurement}
                    style={{
                        height: 130,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 20,
                        paddingLeft: 20,
                        marginBottom: 10,
                    }} 
                />
                
                <View style={styles.section}>
                    <Text style={{width: '100%', textAlign:'left', fontSize: 13, fontWeight: 'bold', }}>
                        RS-BSD-QHS-QAM-P-10500-2 WORK COMPLETION
                    </Text>
                </View>
                
                <View style={styles.section}>
                    <ReceivingPartOne />
                </View>
                
                {/* Items */}
                {workcompletion && workcompletion.items && workcompletion.items.length > 0 ? (
                    <View style={styles.section}>
                        <View style={styles.table}>
                            
                            <TableHead />

                            {(workcompletion.items).map((item, i) => (
                                <View key={i}>
                                    <View  style={[styles.bodyrow]}>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {i + 1}
                                        </Text>
                                        <Text style={[styles.td, styles.desc90]}>
                                            {item.description && item.description}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                            <View  style={[styles.bodyrow]}>
                                <Text style={[styles.td, styles.desc100, { padding: 10 }]}>
                                    <Text style={[styles.text, {fontSize: 11}]}>
                                        In respect of the above, RUSSELSMITH hereby issues to the VENDOR this certificate of work completion.
                                    </Text>

                                    <Text style={[styles.text, {fontSize: 11}]}>
                                        On receipt of this certificate the VENDOR shall invoice RUSSELSMITH for release of the retention monies in accordance RusselSmith 
                                        PURCHASE ORDER TERMS AND CONDITIONS as stated in <b>RS-SCD-PMG-PUR-P-10106-4 PURCHASE ORDER FORM</b>.
                                    </Text>

                                    <br />

                                    <Text style={[styles.text, {fontSize: 11}]}>
                                        This certificate does not relieve the VENDOR of any continuing obligation to RUSSELSMITH under the CONTRACT, nor does it affect 
                                        any statutory or common-law rights held by RUSSELSMITH.
                                    </Text>
                                </Text>
                            </View>

                        </View>
                    </View>
                ) : null}

                <View style={[styles.section, {fontSize: 12}]}>
                    
                    <Text style={[styles.text, {fontSize: 13, fontWeight:'bold', marginTop: 10 }]}>
                        <Typography style={{fontSize: 13, fontWeight:'bold', }}>FOR RUSSELSMITH USE</Typography>
                    </Text>

                    {workcompletion && workcompletion.createdAt ? (
                        <>
                            <Text style={[styles.textLeft, styles.textBold, {fontSize: 11}]}><strong>Completion Date:</strong> {" "}
                                <Text style={[styles.text, {fontSize: 11}]}>
                                    {workcompletion && moment().format('DD/MM/YYYY LTS') }
                                </Text>
                            </Text>
                        </>
                    ) : null}

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 11}]}><strong>Requester:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 11}]}>
                            {workcompletion && workcompletion.createdBy ? workcompletion.createdBy?.userName : null}
                        </Text>
                    </Text>

                    {/* prepared by */}
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 11}]}><strong>Prepared By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 11}]}>
                            {workcompletion && workcompletion.verifiedBy ? workcompletion.verifiedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {(workcompletion && workcompletion.verifiedBy && workcompletion?.verifiedBy?.signature) ? 
                            <Image  
                                src={(workcompletion && workcompletion.verifiedBy?.signature) ? workcompletion && workcompletion.verifiedBy?.signature : livinus}
                            /> : 
                            <Image 
                                src={livinus} 
                            /> 
                            }
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 11}]}>
                            {workcompletion && workcompletion.verifiedAt ? (workcompletion.verifiedAt).slice(0, -12) : null}
                        </Text>
                    </Text>

                    {/* reviewed by */}
                    {(workcompletion && workcompletion.reviewedBy && workcompletion.reviewedAt) ? (
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 11}]}><strong>Reviewed By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 11}]}>
                            {workcompletion && workcompletion.reviewedBy ? workcompletion.reviewedBy?.userName : null}
                        </Text>
                        {" "} 
                        <View style={[styles.text]}>
                            {(workcompletion && workcompletion.reviewedBy && workcompletion?.reviewedBy?.signature) ? 
                                <Image 
                                    src={(workcompletion && workcompletion.reviewedBy?.signature) ? workcompletion && workcompletion.reviewedBy?.signature : livinus}
                                /> : 
                                <Image 
                                    src={livinus} 
                                /> 
                            }
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 11}]}>
                            {workcompletion && workcompletion.reviewedAt ? 
                            (workcompletion.reviewedAt).slice(0, -12) : null} 
                        </Text>
                    </Text>
                    ) : null}

                    {/* approved by */}
                    {(workcompletion && workcompletion.approvedBy && workcompletion.approvedAt) ? (
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 11}]}><strong>Approved By: </strong> {" "}
                        <Text style={[styles.text, {fontSize: 11}]}>
                            {workcompletion && workcompletion.approvedBy ? workcompletion.approvedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {(workcompletion && workcompletion.approvedBy && workcompletion?.approvedBy?.signature) ? 
                            <Image 
                                src={(workcompletion && workcompletion.approvedBy?.signature) ? workcompletion && workcompletion.approvedBy?.signature : livinus}
                            /> : 
                            <Image 
                                src={livinus} 
                            /> 
                            }
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, {fontSize: 11}]}>
                            {workcompletion && workcompletion.approvedAt ? (workcompletion.approvedAt).slice(0, -12) : null}
                        </Text>
                    </Text>
                    ) : null}

                </View>
                
            </View>
        </>
    )
}

export default WCPage;
