import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    Fab,
    Radio,
    RadioGroup,
    FormControlLabel, 
    FormControl,
    FormLabel,
    Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { createAssetRequisition, getAssetRequisitions } from "../../../../actions/assetRequisitionActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    capitalBudget: "",
    description: "",
    justification: "",
    expectedLife: "",
    paymentMode: "",
    requestDate: "",
    quotations: [],
};

const INIT_QUOTATION = {
    description: "",
    terms: "",
    amount: "",
    newUsed: "",
    vendor: "",
}

const CreateAssetRequisitionModal = ({ openCreate, handleCloseCreate, createAssetRequisition }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [requestDate, setRequestDate] = useState(new Date());
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const [quotation, setQuotation] = useState(INIT_QUOTATION);
    
    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.assetRequisition);
    
    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            requestDate: moment(requestDate).toDate(),
        }));
    }, [profile, requestDate, year, month]);

    const handleChangeDate = (date) => {
        setRequestDate(date);
    };

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setQuotation((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (quotation) => {
        if (quotation.amount > "0" && quotation.description !== "" && quotation.terms !== "" && quotation.vendor !== "" && quotation.newUsed !== "") {
            setState((prev) => ({
                ...prev,
                quotations: prev.quotations.concat([quotation]),
            }));
            setQuotation(INIT_QUOTATION);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            quotations: prev.quotations.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createAssetRequisition(data));
        });
        
        if (res) {
            dispatch(getAssetRequisitions());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Capital Asset Requisition Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/asset/requisition`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.month || 
        !state.year || 
        !state.requestDate ||
        !state.capitalBudget ||
        !state.description || 
        !state.justification || 
        !state.expectedLife || 
        !state.paymentMode || 
        (state.quotations.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Capital Asset Requisition Request
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Request Date"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={requestDate}
                                            onChange={handleChangeDate}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Capital Budget"
                                            name="capitalBudget"
                                            value={state.capitalBudget || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Complete item description including accessories"
                                            name="description"
                                            value={state.description || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Reason or justification for request, including use and capability"
                                            name="justification"
                                            value={state.justification || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Expected life in years"
                                            name="expectedLife"
                                            value={state.expectedLife || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel style={{color: "currentcolor", }}> 
                                                Payment Mode: 
                                            </FormLabel>
                                            <RadioGroup row onChange={handleChange}>
                                                <FormControlLabel
                                                    value="Credit Card"
                                                    control={<Radio checked={(state.paymentMode === "Credit Card") ? true : false} />}
                                                    label="Credit Card"
                                                    name="paymentMode"
                                                />
                                                <FormControlLabel
                                                    value="Cheque"
                                                    control={<Radio checked={(state.paymentMode === "Cheque") ? true : false} />}
                                                    label="Cheque"
                                                    name="paymentMode"
                                                />
                                                <FormControlLabel
                                                    value="Bank Transfer"
                                                    control={<Radio checked={(state.paymentMode === "Bank Transfer") ? true : false} />}
                                                    label="Bank Transfer"
                                                    name="paymentMode"
                                                />
                                                <FormControlLabel
                                                    value="Cash"
                                                    control={<Radio checked={(state.paymentMode === "Cash") ? true : false} />}
                                                    label="Cash"
                                                    name="paymentMode"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Typography variant="overline" className={classes.title2}>
                                    Add Price or Lease Quotations
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {quotation ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    name="description"
                                                    value={quotation.description || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="New or Used"
                                                    name="newUsed"
                                                    value={(quotation.newUsed && quotation.newUsed) || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Vendor"
                                                    name="vendor"
                                                    value={(quotation.vendor && quotation.vendor) || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Terms"
                                                    name="terms"
                                                    value={(quotation.terms && quotation.terms) || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Amount"
                                                    name="amount"
                                                    type="number"
                                                    value={(quotation.amount && quotation.amount) || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#093" }}
                                                    onClick={() => handleAddItemsArray(quotation)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {(state.quotations && state.quotations.length > 0) ? <>

                                <Typography variant="overline" className={classes.title2}>
                                    Price or Lease Quotations
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {state.quotations ? state.quotations.map((sQuotation, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    name="description"
                                                    value={sQuotation.description || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="New or Used"
                                                    name="newUsed"
                                                    value={(sQuotation.newUsed && sQuotation.newUsed) || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Vendor"
                                                    name="vendor"
                                                    value={(sQuotation.vendor && sQuotation.vendor) || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Terms"
                                                    name="terms"
                                                    value={(sQuotation.terms && sQuotation.terms) || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Amount"
                                                    name="amount"
                                                    type="number"
                                                    value={(sQuotation.amount && sQuotation.amount) || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <label>&nbsp;</label>
                                                <Fab
                                                    color="secondary"
                                                    onClick={() => handleRemoveItemsArray(i)}
                                                >
                                                    <CloseIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    )) : <>
                                        <Typography variant="overline" className={classes.title2}>
                                            No Requested Items
                                        </Typography>
                                    </>}
                                </Paper> 
                                </> : null}

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createAssetRequisition, })(CreateAssetRequisitionModal);
