import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tabs,
  Box,
  Tab,
  AppBar,
  // withStyles,
  // Badge,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { connect, useDispatch } from "react-redux";
import { getDivisions } from "../../../../actions/divisionActions";
import { getGroupsByModule } from "../../../../actions/groupsAction";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../../../../utils";
import { getJobTitles } from "../../../../actions/jobTitlePpmActions";
import { createUserAccount, getUsers } from "../../../../actions/userActions";
import Feedback from "../../../atoms/Feedback";
import {
  getAllModules,
  getAllModulesAndSubmodules,
} from "../../../../actions/moduleAction";
import UserGroupsTable from "../UserGroupsTable";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 6px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

// const RippleEffect = withStyles((theme) => ({
//   root: {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     width: "10px",
//     height: "10px",
//     borderRadius: "50%",
//     marginTop: -15,
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "$ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
//   "@keyframes ripple": {
//     "0%": {
//       transform: "scale(.8)",
//       opacity: 1,
//     },
//     "100%": {
//       transform: "scale(2.4)",
//       opacity: 0,
//     },
//   },
// }))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const INIT_STATE = {
  firstname: "",
  lastname: "",
  division: "",
  divisions: [],
  email: "",
  employeeId: "",
  jobTitle: "",
  phone: "",
  countryCode: "",
  module: "",
  groups: "",
};

const CreateUserModal = ({
  getDivisions,
  divisions,
  getGroupsByModule,
  groups,
  getJobTitles,
  jobTitles,
  createUserAccount,
  error,
  openCreate,
  handleCreateClose,
  getUsers,
  getAllModules,
  getAllModulesAndSubmodules,
  allmodules,

  modules,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [state, setState] = useState(INIT_STATE);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadGroups, setLoadGroups] = useState(false);
  const [code, setCode] = useState(null);
  const [loadTitles, setLoadTitles] = useState(false);
  const [allTitles, setAllTitles] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const [errorState, setErrorState] = useState({
    success: false,
    error: "",
  });

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const defaultProps = {
    options: countries,
    getOptionLabel: (option) => option.phone,
    renderOption: (option) => (
      <React.Fragment>
        {/* <span>{countryToFlag(option.code)}</span> */}
        {option.label} ({option.code}) +{option.phone}
      </React.Fragment>
    ),
  };

  React.useEffect(() => {
    getAllModulesAndSubmodules();
  }, [getAllModulesAndSubmodules]);

  useEffect(() => {
    setLoad((prev) => !prev);
    // getDivisions()
    //   .then((data) => {
    //     setLoad(false);
    //     setState((prevState) => ({
    //       ...prevState,
    //       divisions: data.data,
    //     }));
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    getDivisions().then(() => {
      setLoad(false);
    });
  }, [getDivisions]);

  const getGroupsCallback = useCallback(() => {
    setLoadGroups((prev) => !prev);
    getGroupsByModule(state.module).then(() => {
      setLoadGroups(false);
    });
  }, [state.module, getGroupsByModule]);

  useEffect(() => {
    const abortController = new AbortController();
    getGroupsCallback();
    return () => {
      abortController.abort();
    };
  }, [getGroupsCallback]);

  const jobTitleCallback = React.useCallback(() => {
    setLoadTitles((prev) => !prev);
    getJobTitles().then(() => {
      setLoadTitles(false);
    });
  }, [getJobTitles]);

  useEffect(() => {
    jobTitleCallback();
  }, [jobTitleCallback]);

  useEffect(() => {
    setAllTitles(jobTitles);
  }, [jobTitles]);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      firstName: state.firstname,
      lastName: state.lastname,
      email: state.email,
      employeeId: state.employeeId,
      division: state.division,
      jobTitle: state.jobTitle,
      phone: state.phone,
      countryCode: `+${code.phone}`,
      groups: groupList.map((grp) => grp.group),
      path: "setuserpassword",
    };

    createUserAccount(data)
      .then(() => {
        if (errorState) {
          setLoading(false);
          setState(INIT_STATE);
          setCode(null);
          setGroupList([]);
          setFeed({
            loading: false,
            open: !feed.open,
            message: errorState.error,
            success: false,
          });

          handleCreateClose();
        } else {
          setLoading(false);
          setState(INIT_STATE);
          setCode(null);
          setGroupList([]);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `User account created Successfully & Set Password link sent to the user`,
            success: true,
          });
          dispatch(getUsers);
          handleCreateClose();
        }
      })
      .catch((err) => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Failed to create user account`,
          success: false,
        });
        handleCreateClose();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  // console.log(groupList);

  const handleAddGroups = (e) => {
    const grp = state.groups.split("%");
    const data = {
      module: grp[1],
      group: grp[0],
    };
    setGroupList((prev) => [...prev, data]);

    setState((prevState) => ({
      ...prevState,
      module: "",
      groups: "",
    }));
  };

  const handleRemoveList = (i) => {
    setGroupList((prev) => prev.filter((_, index) => index !== i));
  };

  const isValid = () => {
    return (
      !state.firstname ||
      !state.lastname ||
      !state.jobTitle ||
      !state.division ||
      !state.email ||
      !groupList.length
    );
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      {/* {errorState ? (
        <Feedback
          open={!errorState.success}
          severity="error"
          message={errorState.error}
        />
      ) : null} */}

      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        fullScreen
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCreateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="overline" className={classes.title}>
              Create User
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <AppBar position="static" color="transparent">
              <Tabs
                variant="fullWidth"
                value={page}
                onChange={handlePageChange}
                aria-label="nav tabs example"
              >
                <LinkTab
                  label={
                    <Typography variant="overline" className={classes.title}>
                      Credentials
                    </Typography>
                  }
                  href="/setup/users"
                  {...a11yProps(0)}
                />

                <LinkTab
                  label={
                    <Typography variant="overline" className={classes.title}>
                      Groups
                    </Typography>
                  }
                  href={`/setup/users`}
                  {...a11yProps(1)}
                />
              </Tabs>
            </AppBar>
            <form onSubmit={handleSubmit}>
              <Paper className={classes.paper} variant="outlined">
                <TabPanel value={page} index={0} className={classes.tabPanel}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="firstname"
                        name="firstname"
                        label="FirstName"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={state.firstname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="lastname"
                        name="lastname"
                        label="LastName"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={state.lastname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="division-label-id">Division</InputLabel>
                        <Select
                          labelId="division-label-id"
                          id="division"
                          label="Division"
                          inputProps={{
                            id: "division",
                            name: "division",
                          }}
                          onChange={handleChange}
                          value={state.division || ""}
                          name="division"
                        >
                          <MenuItem value="" disabled>
                            --- Select Division ---
                          </MenuItem>
                          {load ? (
                            <MenuItem>Loading</MenuItem>
                          ) : (
                            divisions &&
                            divisions.map((division) => (
                              <MenuItem key={division._id} value={division._id}>
                                {division.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={state.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="employeeId"
                        name="employeeId"
                        label="Employee ID"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={state.employeeId}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="title-label-id">Job Title</InputLabel>
                        <Select
                          labelId="title-label-id"
                          id="jobTitle"
                          inputProps={{
                            id: "jobTitle",
                            name: "jobTitle",
                          }}
                          label="Job Title"
                          onChange={handleChange}
                          value={state.jobTitle}
                          name="jobTitle"
                        >
                          <MenuItem value="" disabled>
                            --- Select Job Title ---
                          </MenuItem>
                          {loadTitles ? (
                            <MenuItem>Loading</MenuItem>
                          ) : (
                            allTitles &&
                            allTitles.map((title) => (
                              <MenuItem key={title._id} value={title._id}>
                                {title.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="phone"
                        name="phone"
                        label="Phone"
                        fullWidth
                        onChange={handleChange}
                        value={state.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        className={classes.textField}
                        id="country-code"
                        {...defaultProps}
                        value={code}
                        onChange={(event, newValue) => {
                          setCode(newValue);
                        }}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) +{option.phone}
                          </React.Fragment>
                        )}
                        style={{ marginTop: -15 }}
                        autoComplete
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country Code"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            // error={!code ? true : false}
                            value={code}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled",
                              name: "countryCode",
                            }}
                            helperText={
                              !code ? (
                                <Typography variant="inherit" color="secondary">
                                  * Please Select Country Code
                                </Typography>
                              ) : (
                                ""
                              )
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      paddingLeft: 30,
                      paddingRight: 30,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      // color="default"
                      fullWidth
                      onClick={() => setPage(1)}
                      size="large"
                      className={classes.saveBtn}
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      Next
                    </Button>
                  </Grid>
                </TabPanel>

                <TabPanel value={page} index={1}>
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="module-label-id">Module</InputLabel>
                        <Select
                          labelId="module-label-id"
                          id="module"
                          name="module"
                          onChange={handleChange}
                          value={state.module || ""}
                        >
                          {allmodules &&
                            allmodules.map((mod) => (
                              <MenuItem value={mod.code} key={mod._id}>
                                {mod.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="module-label-id">Groups</InputLabel>
                        <Select
                          labelId="module-label-id"
                          id="groups"
                          name="groups"
                          onChange={handleChange}
                          value={state.groups || ""}
                        >
                          <MenuItem value="select" disabled>
                            ----Select Groups----
                          </MenuItem>
                          {loadGroups ? (
                            <MenuItem>Loading...</MenuItem>
                          ) : (
                            groups.data &&
                            groups.data.map((group) => (
                              <MenuItem
                                key={group._id}
                                value={`${group._id}%${group.displayName}`}
                              >
                                {group.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddGroups}
                        fullWidth
                        disabled={!state.module || !state.groups || loadGroups}
                        className={classes.btn}
                      >
                        {loadGroups ? "Loading..." : "Add"}
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <UserGroupsTable {...{ groupList, handleRemoveList }} />
                      {/* {groupList &&
                        groupList.map((group, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <RippleEffect variant="dot" overlap="circle" />
                            <Paper className={classes.paper2}>
                              <Typography variant="overline" color="initial">
                                {group.module}
                              </Typography>
                              <IconButton onClick={() => handleRemoveList(i)}>
                                <CloseIcon color="inherit" />
                              </IconButton>
                            </Paper>
                          </div>
                        ))} */}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        className={classes.saveBtn}
                        disabled={isValid() || loading}
                        endIcon={
                          loading && (
                            <CircularProgress size={20} color="primary" />
                          )
                        }
                      >
                        {loading ? "Loading..." : "Submit"}
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  jobTitles: state.jobTitle.jobTitles.data,
  error: state.jobTitle.error,
  modules: state.module.modules,
  allmodules: state.module.allmodules,
  divisions: state.division.divisions,
});

export default connect(mapStateToProps, {
  getDivisions,
  getGroupsByModule,
  getJobTitles,
  createUserAccount,
  getUsers,
  getAllModules,
  getAllModulesAndSubmodules,
})(CreateUserModal);
