import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../assets/supplychain.png';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageSix = () => {
    return (
        <>
            <View break>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>

                    <Text style={styles.text}>
                        interfere with the business of COMPANY in any manner, and further agrees not to solicit or induce any employee or independent contractor to terminate or breach an employment, contractual, or other relationship with COMPANY. 
                    </Text>

                    <Text style={styles.boldText}>14. TERMINATION.</Text>
                    
                    <Text style={styles.text}>
                        COMPANY may terminate this Agreement upon written notice to Seller if Seller fails to perform or otherwise breaches this Agreement, files a petition in
                        bankruptcy, becomes insolvent, or dissolves. In the event of such termination, COMPANY shall pay Seller for the portion of the Services satisfactorily
                        performed and those conforming Goods delivered to COMPANY through the date of termination, less appropriate offsets, including any additional costs to be
                        incurred by COMPANY in completing the Services. COMPANY may terminate this Agreement for any other reason upon thirty (30) days' written notice to
                        Seller. Seller shall cease to perform Services and/or provide Goods under this Agreement on the date of termination specified in such notice. In the event of
                        such termination, COMPANY shall be liable to Seller only for those Services satisfactorily performed and those conforming Goods delivered to COMPANY
                        through the date of termination, less appropriate offsets. Upon the expiration or termination of this Agreement for any reason: (a) each party will be released
                        from all obligations to the other arising after the date of expiration or termination, except for those which by their terms survive such termination or expiration;
                        and (b) Seller will promptly notify COMPANY of all COMPANY Confidential Information or any Work Product in Seller's possession and, at the expense of
                        Seller and in accordance with COMPANY's instructions, will promptly deliver to COMPANY all such COMPANY Confidential Information and/or Work Product.
                    </Text>
                    
                    <Text style={styles.boldText}>15. REMEDIES.</Text>
                    <Text style={styles.text}>
                        If Seller breaches this Agreement, COMPANY shall have all remedies available by law and at equity. For the purchase of Goods, Seller's sole remedy in the
                        event of breach of this Agreement by COMPANY shall be the right to recover damages in the amount equal to the difference between market price at the time
                        of breach and the purchase price specified in the Agreement. No alternate method of measuring damages shall apply to this transaction. Seller shall have no
                        right to resell Goods for COMPANY's account in the event of wrongful rejection, revocation of acceptance, failure to make payment or reprepudiation by
                        COMPANY and any resale so made shall be for the account of Seller.
                    </Text>

                    <Text style={styles.boldText}>16. FORCE MAJEURE.</Text>
                    <Text style={styles.text}>
                        COMPANY shall not be liable for any failure to perform including failure to (i) accept performance of Services or, (ii) take delivery of the Goods as provided
                        caused by circumstances beyond its control which make such performance commercially impractical including, but not limited to, acts of God, fire, flood, acts
                        of war, government action, accident, labor difficulties or shortage, inability to obtain materials, equipment, or transportation. In the event COMPANY is so
                        excused, either party may terminate the Agreement and COMPANY shall at its expense and risk, return any Goods received to the place of shipment.
                    </Text>
                    
                    <Text style={styles.boldText}>17. ATTORNEYS' FEES.</Text>
                    <Text style={styles.text}>
                        In any action to enforce this Agreement, the prevailing party shall be entitled to recover all court costs and expenses and reasonable attorneys' fees, in
                        addition to any other relief to which it may be entitled.
                    </Text>

                    <Text style={styles.boldText}>18. SEVERABILITY.</Text>
                    <Text style={styles.text}>
                        If any provision of this Agreement shall be deemed to be invalid, illegal or unenforceable, the validity, legality, and enforceability of the remaining provisions
                        shall not in any way be affected or impaired thereby.
                    </Text>
                    
                    <Text style={styles.boldText}>19. LIMITATION OF LIABILITY.</Text>
                    <Text style={styles.text}>
                        IN NO EVENT SHALL COMPANY BE LIABLE TO SELLER OR SELLER'S ASSISTANTS, OR ANY THIRD PARTY FOR ANY 
                    </Text>
                    
                    <Text style={[styles.textUnderline]}>
                        {"________________________________________________________________________________"}
                    </Text>
                    <Text style={[styles.footerTextLeft]}>RS-SCD-PMG-PUR-P-10006 Procurement</Text>
                    <Text style={[styles.textRight]}>Unauthorized Printing Is Not Permitted</Text>

                </View>
            </View>
        </>
    )
}

export default PageSix;
