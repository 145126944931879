import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../atoms/Feedback";

import { useHistory } from "react-router-dom";
import {
  createMonthlyBudget,
  getMonthlyBudgets,
} from "../../../../actions/monthlyBudgetAction";
import { months } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    margin: "30px 0",
    width: 300,
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      // minWidth: "100%",
    },
  },
}));

const INIT_STATE = {
  employeeName: "",
  division: "",
  jobTitle: "",
  year: "",
  month: "",
  type: "",
};

const MonthlyModal = ({
  open,
  handleClose,
  createMonthlyBudget,
  getMonthlyBudgets,

  error,

  profile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(null);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    if (profile) {
      setState((prev) => ({
        ...prev,
        employeeName: profile.userName,
        division: profile.division && profile.division.name,
        jobTitle: profile.jobTitle?.name,
        year: new Date().getFullYear(),
      }));
    }
  }, [profile]);

  const handleChange = (e) => {
    // e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      month: selectedMonth.name,
      year: state.year,
      type: state.type,
    };

    createMonthlyBudget(data)
      .then((doc) => {
        if (doc) {
          setLoading(false);
          setState((prev) => ({
            ...prev,
            INIT_STATE,
          }));
          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            // message: `Monthly Budget created successfully`,
            success: true,
          }));

          dispatch(getMonthlyBudgets);
          handleClose();
          setTimeout(() => {
            navigateById("/budget/monthly/budgetline", doc.data._id);
          }, 1500);
        } else {
          setLoading(false);
          handleClose();

          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            message: error && error.error,
            success: false,
          }));
        }
      })
      .catch(() => {
        setLoading(false);
        handleClose();

        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !feed.open,
          message: error && error.error,
          success: false,
        }));
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return !selectedMonth || !state.division || !state.type;
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `Monthly Budget created successfully`}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Monthly Budget
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="close">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="employeeName"
                  name="employeeName"
                  label="Employee Name"
                  value={state.employeeName || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="division"
                  name="division"
                  label="Division/Unit"
                  value={state.division || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="jobTitle"
                  name="jobTitle"
                  label="Job Title"
                  value={state.jobTitle || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="year"
                  name="year"
                  label="Year"
                  value={state.year || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    inputProps={{
                      id: "type",
                    }}
                    value={state.type || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>--- Select Type ---</em>
                    </MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="supplementary">Supplementary</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={selectedMonth}
                  options={months}
                  fullWidth
                  onChange={(event, newValue) => {
                    setSelectedMonth(newValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Month"
                      variant="outlined"
                      value={selectedMonth}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "disabled",
                        name: "month",
                      }}
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.btn}
                  disabled={validCheck() || loading}
                >
                  {loading ? "Loading..." : "Create"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.monthlyBudget.error,
  subheads: state.budgetSubheads.subheads.data,
  divisions: state.division.divisions.data,
  profile: state.user.profile,
});

export default connect(mapStateToProps, {
  createMonthlyBudget,
  getMonthlyBudgets,
})(MonthlyModal);
