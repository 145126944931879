import React, { useState, useEffect, useCallback } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    Paper,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    MenuItem,
    Select,
    FormControl,
    Fab,
    InputLabel,
    Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InvoiceViewModal from "./InvoiceModal";

import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getAllVendors, uploadDocument } from "../../../../actions/vendorsActions";
import { getVendorPurchaseOrder, getDivisionOpenMarket } from "../../../../actions/purchasingActions";
import { createReceivingInspection, getReceivingInspections } from "../../../../actions/qualityActions";
import { getDivisions } from "../../../../actions/divisionActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        paddingBottom:0
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },
    small: {
        display: "inline-block",
        fontSize: 9,
        lineHeight: 1.5,
        letterSpacing: 1.2,
        fontFamily: "Rubik",
        color: theme.palette.warning.main,
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    vendor: "",
    costType:"",
    supplierId: "",
    deliveryLocation: "",
    purchaseOrderNo: "",
    costCenter: "",
    raifNumber: "",
    date: "",
    items: [],
    invoices:[],
};

const INIT_VENDOR = {
    id: "",
    companyName: "",
    supplierId:"",
    email: "",
}

const INIT_VENDOR_PO = {
    id: "",
    documentNo: "",
    vendor: "",
    items: [],
}

const INIT_INVOICE = {
    description: "",
    invoice: "",
}

const CreateRAIModal = ({ openCreate, handleCloseCreate, createReceivingInspection, getDivisions, getDivisionOpenMarket }) => {
    const classes = useStyles();
    const history = useHistory();
    const [theUrl, setTheUrl] = useState("");
    const [openDoc, setOpenDoc] = useState(false);

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [invoice, setInvoice] = useState(INIT_INVOICE);

    const [date, setDate] = useState(moment(new Date()));
    const [state, setState] = useState(INIT_STATE);
    const [vendor, setVendor] = useState(INIT_VENDOR);
    const [vendorPO, setVendorPO] = useState(INIT_VENDOR_PO);
    const [load, setLoad] = useState(false);
    const [invoiceFile, setInvoiceFile] = useState(undefined);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.receiving);
    const { vendors } = useSelector((state) => state.vendors);
    const { vendor_purchase_orders } = useSelector((state) => state.po);
    const { divisions } = useSelector((state) => state.division);
    const { opms } = useSelector((state) => state.openmarket);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);
    
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            date: moment(date).toDate(),
        }));
    }, [profile, date]);

    const handleChangeDate = (date) => {
        setDate(date);
    };

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllVendors());
    }, [dispatch]);

    const divisionCallback = useCallback(() => {
        setLoad((prev) => !prev);
        getDivisions().then(() => {
            setLoad(false);
        });
    }, [getDivisions]);

    useEffect(() => {
        divisionCallback();
    }, [divisionCallback]);

    const getVendorPOCallback = useCallback(() => {
        if (vendor.id !== "") {
            dispatch(getVendorPurchaseOrder(vendor.id));
        }
    }, [vendor, dispatch]);

    useEffect(() => {
        if (vendor.id !== "") {
            getVendorPOCallback();
        }
    }, [vendor, getVendorPOCallback]);

    const getDivisionOPMCallback = useCallback(() => {
        if (state.costCenter !== "" && state.type === "Open Market") {
            getDivisionOpenMarket(state.costCenter);
        }
    }, [state.costCenter, getDivisionOpenMarket, state.type]);

    useEffect(() => {
        if (state.costCenter !== "" && state.type === "Open Market") {
            getDivisionOPMCallback();
        }
    }, [state, getDivisionOPMCallback]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeVendor = (e) => {
        e.persist();
        setVendor((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // capture item changes
    const handleVendorItemChange = (i) => (e) => {
        e.persist();
        const newItems = vendorPO.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            if((e.target.name === "quotedAmount") || (e.target.name === "quantityReceived") || (e.target.name === "quantityDelivered")){
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            } else {
                return {
                    ...item,
                };
            }
        });

        setVendorPO((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };
        
    const handleSubmit = async (e) => {
        e.preventDefault();

        const newItems = vendorPO?.items.map(item => ({
            description: item.description,
            quotedAmount: (parseFloat(item.quotedAmount / item.quantityNeeded) ? parseFloat(item.quotedAmount / item.quantityNeeded) : 
                (parseFloat(item.purchaseAmount / item.quantityNeeded) || 0)),
            quantityOrdered: item.quantityNeeded,
            quantityDelivered: item.quantityDelivered || item.quantityNeeded,
            quantityReceived: item.quantityReceived || item.quantityNeeded,
            total: parseFloat(item.quantityDelivered || item.quantityNeeded) * (parseFloat(item.quotedAmount / item.quantityNeeded) ? 
                parseFloat(item.quotedAmount / item.quantityNeeded) : 
                    (parseFloat(item.purchaseAmount / item.quantityNeeded) || 0)),
            cost: (parseFloat(item.quotedAmount / item.quantityNeeded) ? parseFloat(item.quotedAmount / item.quantityNeeded) : 
            (parseFloat(item.purchaseAmount / item.quantityNeeded) || 0)),
          }));

        const data = {
            ...state,
            ...vendor,
            vendor: vendorPO?.vendor._id,
            items: newItems,
            purchaseOrderNo: vendorPO?.documentNo,
            supplierId: vendor && vendor?.supplierId,
            path: `quality/receivinginspection`,
        };
        
        if(data.invoices.length > 0 && data.items.length > 0){   
            const res = await new Promise((resolve, reject) => {
                resolve(createReceivingInspection(data));
            });
            
            if (res) {
                dispatch(getReceivingInspections());
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Receiving and Inspection Record Created Successfully.",
                    success: true,
                }));
                setTimeout(() => {
                    history.push(`/quality/receivinginspection`);
                    handleCloseCreate();
                }, 2000);
            }
        }
    };

    const handleInvoiceChange = (e) => {
        e.persist();
        setInvoice((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUploadInvoice = (e) => {
        const { files } = e.target;
        setInvoiceFile(files[0]);
    };

    useEffect(() => {
        const upload = async () => {
            if (invoiceFile) {
                const formData = new FormData();
                formData.append("doc", invoiceFile);
                let url = await new Promise((resolve, reject) => {
                        resolve(dispatch(uploadDocument(formData)));
                });
        
                if (url) {
                    setInvoice((prev) => ({ ...prev, invoice: url }));
                    setInvoiceFile(null);
                }
            }
        };
        upload();
    }, [invoiceFile, dispatch]);

    const handleAddInvoiceArray = (invoice) => {
        if (invoice.description !== "" && invoice.invoice !== "") {
            setState((prev) => ({
                ...prev,
                invoices: prev.invoices.concat([invoice]),
            }));
            setInvoice(INIT_INVOICE);
        }
    };

    const handleRemoveInvoiceArray = (i) => {
        setState((prev) => ({
            ...prev,
            invoices: prev.invoices.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleOpenDoc = (url) => {
        setTheUrl(null);
        if(url){
            setTheUrl(url);
            setOpenDoc(true);
        }
    };
    
    const handleCloseDoc = () => {
        setOpenDoc(false);
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !vendor ||
        !vendorPO.documentNo || 
        !state.date ||
        !state.deliveryLocation ||
        !state.deliveryTime ||
        !state.costCenter ||
        (vendorPO.items.length === 0) || 
        (state.invoices.length === 0);
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <InvoiceViewModal {...{ openDoc, handleCloseDoc, docs: theUrl }} />
                
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Receiving and Inspection
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="EID"
                                            name="employeeId"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.employeeId || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel id="costTypeId">Cost Type</InputLabel>
                                            <Select
                                                labelId="costTypeId"
                                                name="costType"
                                                inputProps={{
                                                    id: "costType",
                                                    name: "costType",
                                                }}
                                                onChange={handleChange}
                                                value={state.costType || ""}
                                            >
                                                <MenuItem value="project">Project</MenuItem>
                                                <MenuItem value="division">Division/Unit</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                        <InputLabel id="costCodeId">Cost Center</InputLabel>
                                        <Select
                                            labelId="costCodeId"
                                            name="costCenter"
                                            inputProps={{
                                                id: "costCenter",
                                                name: "costCenter",
                                            }}
                                            onChange={handleChange}
                                            value={state.costCenter || ""}
                                            disabled={!state.costType}
                                        >
                                            <MenuItem value="" disabled>
                                                <em>---SELECT COST CENTER----</em>
                                            </MenuItem>
                                            {divisions && divisions.map((cost) => (
                                                <MenuItem key={cost._id} value={cost._id}>
                                                    {cost.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="deliveryTime">Was The Product/Service Delivered On Time?</InputLabel>
                                            <Select
                                                labelId="deliveryTime"
                                                label="Delivery Time"
                                                name="deliveryTime"
                                                value={state.deliveryTime || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Delivery Location"
                                            name="deliveryLocation"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.deliveryLocation && state.deliveryLocation) || ""}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={date}
                                            minDate={date}
                                            disablePast
                                            onChange={handleChangeDate}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="type">Type</InputLabel>
                                            <Select
                                                labelId="type"
                                                label="Type"
                                                name="type"
                                                value={state.type || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="Vendor">Vendor</MenuItem>
                                                <MenuItem value="Open Market">Open Market</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    {state.type === "Vendor" && ( <>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            style={{marginTop: -15}}
                                            id="vendor"
                                            options={vendors}
                                            // value={(vendor && vendor.id) || ""}
                                            onChange={(event, newValue) => {
                                                setVendor((prev) => ({
                                                    ...prev,
                                                    id: newValue && newValue._id,
                                                    email: newValue && newValue.email,
                                                    supplierId: newValue && newValue.supplierId,
                                                    companyName: newValue && newValue.companyName,
                                                }));
                                            }}
                                            getOptionLabel={(option) => option.companyName || ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderOption={(option) => (
                                                <React.Fragment>{option.companyName}</React.Fragment>
                                            )}
                                            autoComplete
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Vendor/Supplier/Subcontractor"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    value={(vendor.companyName && vendor.companyName) || ""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "vendor",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            style={{marginTop: -15}}
                                            id="vendorPO"
                                            options={vendor_purchase_orders}
                                            // value={(vendorPO && vendorPO.id) || ""}
                                            onChange={(event, newValue) => {
                                                setVendorPO((prev) => ({
                                                    ...prev,
                                                    id: newValue && newValue._id,
                                                    documentNo: newValue && newValue.documentNo,
                                                    items: newValue && newValue.items,
                                                    vendor: newValue && newValue.vendor,
                                                }));
                                            }}
                                            getOptionLabel={(option) => option.vendor?.companyName || ""}
                                            getOptionSelected={(option, value) => option.id === value.id}
                                            renderOption={(option) => (
                                                <React.Fragment>{option.vendor?.companyName} - {option.documentNo}</React.Fragment>
                                            )}
                                            autoComplete
                                            autoHighlight
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="PO/Contract Number"
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    value={(vendorPO.documentNo && vendorPO.documentNo) || ""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "vendorPO",
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="PO/Contract Number"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            value={(vendorPO.documentNo && vendorPO.documentNo) || ""}
                                            required
                                            name="purchaseOrderNo"
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Vendor/Supplier Name"
                                            variant="outlined"
                                            fullWidth
                                            value={(vendor.companyName && vendor.companyName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Vendor/Supplier Email"
                                            variant="outlined"
                                            fullWidth
                                            value={(vendor.email && vendor.email) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Supplier Id"
                                            variant="outlined"
                                            fullWidth
                                            value={(vendor.supplierId && vendor.supplierId) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    </> )}


                                    {state.type === "Open Market" && ( <>
                                        <Grid item xs={12} md={4}>
                                            <Autocomplete
                                                style={{marginTop: -15}}
                                                id="vendorPO"
                                                options={opms}
                                                onChange={(event, newValue) => {
                                                    setVendorPO((prev) => ({
                                                        ...prev,
                                                        id: newValue && newValue._id,
                                                        documentNo: newValue && newValue.documentNo,
                                                        items: newValue && newValue.items,
                                                        purpose: newValue && newValue.purpose,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.purpose || ""}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.purpose} - {option.documentNo}</React.Fragment>
                                                )}
                                                autoComplete
                                                autoHighlight
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Divisional Open Market Requisitions"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        value={(vendorPO.documentNo && vendorPO.documentNo) || ""}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "disabled",
                                                            name: "vendorPO",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Name"
                                                name="vendorName"
                                                variant="outlined"
                                                fullWidth
                                                onChange={handleChangeVendor}
                                                value={(vendor.vendorName && vendor.vendorName) || ""}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Vendor Contact Person"
                                                name="vendorContactPerson"
                                                value={vendor.vendorContactPerson || ""}
                                                onChange={handleChangeVendor}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Email"
                                                variant="outlined"
                                                fullWidth
                                                type="email"
                                                name="vendorEmail"
                                                value={(vendor.vendorEmail && vendor.vendorEmail) || ""}
                                                onChange={handleChangeVendor}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Vendor Phone Number"
                                                name="vendorPhone"
                                                value={vendor.vendorPhone || ""}
                                                onChange={handleChangeVendor}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Purchase Order Number"
                                                name="purchaseOrderNo"
                                                value={state.purchaseOrderNo || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Supplied Id"
                                                name="supplierId"
                                                value={vendor.supplierId || ""}
                                                onChange={handleChangeVendor}
                                            />
                                        </Grid>
                                    </> )}
                                </Grid>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Typography variant="overline" className={classes.title2}>
                                        --- Please attach the invoice(s) for this request. <br />
                                        --- You can add as manay invoices as you have.
                                    </Typography>
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Invoices
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper} style={{ paddingBottom:0 }}>
                                    {invoice ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description of Invoice"
                                                    name="description"
                                                    value={invoice.description || ""}
                                                    onChange={handleInvoiceChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <FormControl fullWidth variant="outlined" required>
                                                    <input
                                                        type="file"
                                                        label="Invoice File"
                                                        variant="outlined"
                                                        accept="image/*,.pdf"
                                                        id="file"
                                                        defaultValue={invoice.invoice || ""}
                                                        className={classes.input}
                                                        onChange={handleUploadInvoice}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    disabled={(invoice.description === "" || invoice.invoice === "")}
                                                    onClick={() => handleAddInvoiceArray(invoice)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>


                                {(state.invoices && state.invoices.length > 0) && <>
                                
                                <Typography variant="overline" className={classes.title2}>
                                    Uploaded Invoices
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper} style={{ paddingBottom:0 }}>
                                    {state.invoices ? state.invoices.map((sinvoice, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={10}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description of Invoice"
                                                    name="description"
                                                    value={sinvoice.description || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <IconButton
                                                    disabled={sinvoice.invoice === null}
                                                    onClick={() => handleOpenDoc(sinvoice.invoice)}
                                                >
                                                    <Badge
                                                        color="secondary"
                                                        badgeContent={sinvoice.invoice ? 1 : null}
                                                    >
                                                        <AttachFileIcon />
                                                    </Badge>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <label>&nbsp;</label>
                                                <Fab
                                                    color="secondary"
                                                    onClick={() => handleRemoveInvoiceArray(i)}
                                                >
                                                    <CloseIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    )) : <>
                                    <Typography variant="overline" className={classes.title2}>
                                        No Invoice Uploaded
                                    </Typography>
                                    </>}
                                </Paper> </> }

                                {(vendorPO.items && vendorPO.items.length > 0) && <>

                                <Typography variant="overline" className={classes.title2}>
                                    Purchase Order Items
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {vendorPO.items ? vendorPO.items.map((sitem, i) => (
                                        <Paper variant="outlined" square className={classes.paper} key={i}>
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                            >
                                                <Grid item xs={12} md={4}>
                                                    <label style={{ fontWeight: "bold" }}>Product / Service Description</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name="description"
                                                        value={sitem.description || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>PO Unit Amount: </label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        name="quotedAmount"
                                                        value={(parseFloat(sitem.quotedAmount / sitem.quantityNeeded) ? 
                                                            parseFloat(sitem.quotedAmount / sitem.quantityNeeded) : 
                                                            (parseFloat(sitem.purchaseAmount / sitem.quantityNeeded) || '')) || ""}
                                                        disabled
                                                        helperText={
                                                            <Typography variant="overline" className={classes.small}>
                                                                This is the unit Amount which is Quoted Amount divided by the quantity needed
                                                            </Typography>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Ordered: ({sitem.unit || ""})</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        type="text"
                                                        name="quantityOrdered"
                                                        value={`${sitem.quantityNeeded + ' ' + sitem.unit}` || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Delivered</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        name="quantityDelivered"
                                                        value={sitem.quantityDelivered ? sitem.quantityDelivered :  (sitem.quantityNeeded || "")}
                                                        onChange={handleVendorItemChange(i)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Received</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        type="number"
                                                        name="quantityReceived"
                                                        value={sitem.quantityReceived ? sitem.quantityReceived : (sitem.quantityNeeded || "")}
                                                        onChange={handleVendorItemChange(i)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )) : <>
                                        <Typography variant="overline" className={classes.title2}>
                                            No Requested Items
                                        </Typography>
                                    </>}
                                </Paper>
                                </> }

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || load || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createReceivingInspection, getDivisions, getDivisionOpenMarket })(CreateRAIModal);
