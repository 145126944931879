import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Backdrop,
    darken,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Avatar,
    Card,
    CardContent,
    Button,
} from "@material-ui/core";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { TablePaginationActions } from "../../TablePaginationActions";
import { getTodaysAttendances, getQueryAttendance } from "../../../actions/peoplesActions";
import { getUsersForPPM } from "../../../actions/userActions";
import { ExportJsonCsv } from "react-export-json-csv";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    paper: {
        marginTop: 20,
        padding: theme.spacing(3),
        borderColor: '#ccc',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    paper2: {
        marginTop: 20,
        borderColor: '#ccc',
        borderWidth: 1,
        borderStyle: 'solid'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    currency: {
        fontWeight: 800,
        lineBreak: "anywhere",
        lineHeight: 1,
        fontSize: 21,
        maxWidth: 250,
    },
    infoText: {
        fontWeight: 600,
        color: darken(theme.palette.common.white, 0.3),
        letterSpacing: 1.5,
        fontSize: 11,
    },
    marginBottom: {
        marginBottom: 10,
        marginTop: 10
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.9),
        paddingLeft: 35,
        paddingRight: 35,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
        backgroundColor: "#4caf50",
        color: "#fff",
    },
    statusGreen: {
        borderColor: "#1de9b6",
        backgroundColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
        backgroundColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
        backgroundColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
        backgroundColor: "#fbc02d",
    },
}));


const INIT_STATE = {
    employee : "All",
    year:"",
    week:"",
    cycle:"",
    month:"",
};

const AttendanceReport = ({ getUsersForPPM, users }) => {
    const classes = useStyles();
    const [search] = useState("");
    const dispatch = useDispatch();
    const [state, setState] = useState(INIT_STATE);
    const [reports, setData] = useState([]);
    const [counts, setCounts] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const [date] = useState(new Date());
    const [query, setQuery] = useState(null);

    const { queryattendances, loading } = useSelector((state) => state.peoples);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            day: (moment(date).format("dddd")) || "",
            date: (date && moment(date).format("MMMM Do YYYY")) || "",
            year: (date && moment(date).format("YYYY")) || "",
            month: (date && moment(date).format("MMMM")) || "",
            lastmonth : (date && moment(date).subtract(1,'months').format("MMMM")) || "",
            week: (moment(date).format("WW")) || "",
            lastweek: (moment(date).subtract(7,'days').format("WW")) || "",
        }));
    }, [date]); 

    useEffect(() => {
        if(state.cycle !== "" && state.employee !== ""){
            let data;
            if(state.cycle === "thisweek"){
                data = {
                    employee: state.employee,
                    year: state.year,
                    cycle: state.cycle,
                    week: state.week,
                }
            }else if(state.cycle === "lastweek"){
                data = {
                    employee: state.employee,
                    year: state.year,
                    cycle: state.cycle,
                    week: state.lastweek,
                }
            }else if(state.cycle === "thismonth"){
                data = {
                    employee: state.employee,
                    year: state.year,
                    cycle: state.cycle,
                    month: state.month,
                }
            }else if(state.cycle === "lastmonth"){
                data = {
                    employee: state.employee,
                    year: state.year,
                    cycle: state.cycle,
                    month: state.lastmonth,
                }
            }
            setQuery(data);
        }
    }, [state]);

    useEffect(() => {
        if(query && query.employee !=="" && query.year !=="" && query.cycle !=="" && query.week !==""){
            dispatch(getQueryAttendance(query));
        }
    }, [dispatch, query]);

    useEffect(() => {
        if(queryattendances && queryattendances.sums !=="" && queryattendances.data !==""){
            setCounts(queryattendances.sums)
            setData(queryattendances.data)
        }
    }, [queryattendances]);

    useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getUsersForPPM().then((res) => {
            if(res.success){
                setOpenBackdrop(false);
            }
        });
    }, [getUsersForPPM]);

    useEffect(() => {
        dispatch(getTodaysAttendances());
    }, [dispatch]);

    let filteredRows = () =>
        queryattendances && queryattendances.data === undefined
        ? []
        : queryattendances.data.filter((row) => {
            if (search !== "") {
                return (
                (row.employee &&
                    row.employee.userName
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1) ||
                (row.division &&
                    row.division.name
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1) ||
                (row.jobTitle &&
                    row.jobTitle?.name
                    ?.toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1) ||
                (row.location &&
                    row.location.toLowerCase().indexOf(search.toLowerCase()) !==
                    -1) ||
                (row.date &&
                    row.date.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                (row.week &&
                    row.week.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                (row.timeIn &&
                    row.timeIn.toLowerCase().indexOf(search.toLowerCase()) !==
                    -1) ||
                (row.timeOut &&
                    row.timeOut.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                );
            } else {
             return row;
            }
        });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  
    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    const emptyRows =
        rowsPerPage -
            Math.min(
            rowsPerPage,
            (Array(10000).length || filteredRows().length) - page * rowsPerPage
        );

    const headers = [
        { key: 'name', name: 'Name',  },
        { key: 'position', name: 'Position', },
        { key: 'division', name: 'Division/Unit', },
        { key: 'date', name: 'Date',  },
        { key: 'week', name: 'Week No', },
        { key: 'month', name: 'Month', },
        { key: 'year', name: 'Year',  },
        { key: 'timeIn', name: 'Time In', },
        { key: 'timeOut', name: 'Time Out', },
    ];

    let sortedData = filteredRows();
    
    const exportData = sortedData.map((item, i) => (
        {
            name: item.employee?.userName,
            position: item.jobTitle?.name, 
            division: item.division?.name,
            date: item.date,
            week: item.week,
            month: item.month,
            year : item.year,
            timeIn: item.timeIn,
            timeOut: item.timeOut,   
        }),
    );

    return (
        <div>
            <Typography variant="overline" gutterBottom className={classes.title}>
                Attendance Report
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid container spacing={3} className={classes.marginBottom} >
                    <Grid item md={3} xs={6}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel>Select Employee</InputLabel>
                            <Select
                                name="employee"
                                inputProps={{id: "employee" }}
                                value={state.employee || "All"}
                                onChange={handleChange}
                            >
                                <MenuItem value="All">All Employees</MenuItem>
                                {users && users.map((user, i) => (
                                    <MenuItem key={user._id} value={user._id}>
                                        {user.firstName} {" "} {user.lastName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel>Select Year</InputLabel>
                            <Select
                                name="year"
                                inputProps={{id: "year"}}
                                value={state.year || ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="" disabled><em>--- Select ---</em></MenuItem>
                                <MenuItem value="2025">2025</MenuItem>                            
                                <MenuItem value="2024">2024</MenuItem>    
                                <MenuItem value="2023">2023</MenuItem>
                                <MenuItem value="2022">2022</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl fullWidth variant="outlined" required>
                            <InputLabel>Select Cycle</InputLabel>
                            <Select
                                name="cycle"
                                inputProps={{id: "cycle"}}
                                value={state.cycle || ""}
                                onChange={handleChange}
                            >
                                <MenuItem value="" disabled><em>--- Select ---</em></MenuItem>
                                <MenuItem value="thisweek">This Week ({"Week "} {state.week})</MenuItem>
                                <MenuItem value="lastweek">Last Week ({"Week "} {state.lastweek})</MenuItem>
                                <MenuItem value="thismonth">This Month ({state.month} {state.year})</MenuItem>
                                <MenuItem value="lastmonth">Last Month  ({state.lastmonth} {state.year})</MenuItem>
                                {/* <MenuItem value="date">Choose Date</MenuItem> */}
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={6} fullWidth>
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.backBtn}
                            fullWidth
                            disabled={(reports && reports.length > 0) ? false : true}
                        >
                            <ExportJsonCsv 
                                headers={headers} 
                                items={exportData} 
                                fileTitle={`RusselSmith_Attendance_Report_${query?.cycle || ''}_${query?.year || ''}_${query?.month || ''}`} 
                                style={{ border:'none', background:'transparent', fontSize: 20, fontWeight:'bold', color:'#444' }}
                            >
                                Export
                            </ExportJsonCsv>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {counts && counts.length > 0 ? 
            <Paper className={classes.paper}>
                <Grid
                    container
                    className={classes.gridContainer}
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    {counts && counts.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Card variant="outlined" square>
                                <CardContent>
                                    <Grid
                                        container
                                        justify="space-between"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            {loading ? (
                                                <div> <CircularProgress size={20} /> </div>
                                            ) : (
                                                <Typography className={classes.currency}>
                                                    {item.count && item.count}
                                                </Typography>
                                            )}
                                            <Typography variant="overline" className={classes.infoText}>
                                                {item.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                        <Avatar
                                            src="/img/calendar2.svg"
                                            alt="Calendar"
                                            variant="square"
                                        />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid> 
                    ))}
                </Grid>
            </Paper> : null}

            <Paper variant="outlined" square className={classes.paper2}>
                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Division/Unit</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Week</TableCell>
                                <TableCell>TimeIn</TableCell>
                                <TableCell align="center">TimeOut</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                            ? filteredRows().slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                                )
                            : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                            label={row.employee.userName}
                                            variant="outlined"
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.jobTitle && row.jobTitle?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.division && row.division.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.location && row.location}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.date}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.week}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.timeIn}
                                                variant="outlined"
                                                className={clsx(
                                                    (row.timeIn >= "9:00:00 AM") ? classes.statusRed : classes.statusDarkGreen,
                                                    classes.chip
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {row.timeOut ? row.timeOut : "-- • --"}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={10}
                                        align="center"
                                        style={{
                                            color: "#616161",
                                            textAlign: "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={openBackdrop || loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: state.user.users,
});

const mapDispatchToProps = { getUsersForPPM };

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceReport);
