import React from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { connect, useDispatch } from "react-redux";

import { getAnnualBudgetById } from "../../../../actions/annualAction";
import { getAllBudgetHeads } from "../../../../actions/budgetHeadsActions";
import { getBudgetItemBySubheadAndDivision } from "../../../../actions/budgetItemActions";
import { getBudgetSubheadByHead } from "../../../../actions/budgetSubheadAction";

import Feedback from "../../../atoms/Feedback";
import { getCostCodesByType } from "../../../../actions/costCodeAction";
import {
  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getMonthlyBudgetById,
  getAllMonthlyBudgetItems,
} from "../../../../actions/monthlyBudgetAction";
import { currencyConvert } from "../../../../utils/currencyconvert";
import { getCurrencies } from "../../../../actions/currencyActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  container: {
    // marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));

const INIT_STATE = {
  budgetHead: "",
  budgetSubhead: "",
  year: "",
  month: "",
  budgetItem: "",
  description: "",
  costCategory: "",
  costCenter: "",
  amount: 0,

  rate: 1,
};

const CreateMonthlyBudgetLineModal = ({
  openCreate,
  handleCreateClose,
  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getAllBudgetHeads,
  getAllMonthlyBudgetItems,
  getBudgetSubheadByHead,
  getBudgetItemBySubheadAndDivision,
  error,
  heads,
  subheads,
  items,

  id,
  profile,

  getMonthlyBudgetById,
  getCostCodesByType,
  costcodes,
  monthlyBudgetLines,
  monthlyBudget,
  getCurrencies,
  currencies,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [state, setState] = React.useState(INIT_STATE);

  const [loading, setLoading] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  React.useEffect(() => {
    setLoad((prev) => !prev);
    getCurrencies().then(() => {
      setLoad(false);
    });
  }, [getCurrencies]);

  React.useEffect(() => {
    getAllBudgetHeads();
  }, [getAllBudgetHeads]);

  const getMonthlyBudgetByIdCallback = React.useCallback(() => {
    getMonthlyBudgetById(id);
  }, [id, getMonthlyBudgetById]);

  React.useEffect(() => {
    getMonthlyBudgetByIdCallback();
  }, [getMonthlyBudgetByIdCallback]);

  const getBudgetSubheadCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    if (state.budgetHead !== "") {
      getBudgetSubheadByHead(state.budgetHead).then(() => {
        setOpenBackdrop(false);
      });
    }
  }, [state.budgetHead, getBudgetSubheadByHead]);

  React.useEffect(() => {
    getBudgetSubheadCallback();
  }, [getBudgetSubheadCallback]);

  const itemCallback = React.useCallback(() => {
    const data = {
      subhead: state.budgetSubhead && state.budgetSubhead,
      division: profile && profile.division && profile.division._id,
    };
    if (state.budgetSubhead !== "") {
      getBudgetItemBySubheadAndDivision(data);
    }
  }, [state.budgetSubhead, profile, getBudgetItemBySubheadAndDivision]);

  React.useEffect(() => {
    const abortController = new AbortController();
    itemCallback();
    return () => {
      abortController.abort();
    };
  }, [itemCallback]);

  const codeTypeCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    if (state.costCategory !== "") {
      getCostCodesByType(state.costCategory).then(() => {
        setOpenBackdrop(false);
      });
    }
  }, [getCostCodesByType, state]);

  React.useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading((prev) => !prev);
    const {
      description,
      budgetHead,
      budgetSubhead,
      budgetItem,
      costCenter,
      amount,
      rate,
    } = state;

    let convertedAmount = currencyConvert(amount, rate);
    let data = {};

    data.subHead = budgetSubhead;
    data.description = description;
    data.head = budgetHead;
    data.item = budgetItem;
    data.costCenter = costCenter;
    data.amount = +convertedAmount;

    data.budgetId = id;

    createMonthlyBudgetLine(data)
      .then((doc) => {
        if (doc) {
          setState(INIT_STATE);

          setFeed({
            loading: false,
            open: !feed.open,
            message: `BudgetLine Created Successfully`,
            success: true,
          });

          dispatch(getAllMonthlyBudgetLineByBudgetId(id));
          setLoading(false);
        } else {
          // setState(INIT_STATE);

          setFeed({
            loading: false,
            open: !feed.open,

            success: false,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        // setState(INIT_STATE);

        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
        setLoading(false);
      })
      .finally(() => {
        handleCreateClose();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return (
      !state.budgetHead ||
      !state.budgetItem ||
      !state.budgetSubhead ||
      !state.description ||
      !state.amount
    );
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "BudgetLine Created Successfully."}
        />
      )}
      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        // fullScreen
        maxWidth="md"
      >
        <div className={classes.container}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <DialogTitle id="form-dialog-title">
                <Typography variant="overline" className={classes.title}>
                  Create Budget Line
                </Typography>
              </DialogTitle>
            </Grid>
            <Grid item>
              <Tooltip title="close" arrow style={{ marginLeft: "auto" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCreateClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <DialogContent className={classes.dialogContent}>
            <Paper className={classes.paper}>
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Month"
                      id="month"
                      name="month"
                      // onChange={handleChange}
                      value={
                        monthlyBudget === undefined
                          ? "Loading..."
                          : monthlyBudget && monthlyBudget.month
                      }
                      fullWidth
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Year"
                      id="year"
                      name="year"
                      // onChange={handleChange}
                      value={
                        monthlyBudget === undefined
                          ? "Loading..."
                          : monthlyBudget && monthlyBudget.year
                      }
                      fullWidth
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budget-head">Expense Head</InputLabel>
                      <Select
                        labelId="budget-head"
                        id="budgetHead"
                        name="budgetHead"
                        inputProps={{
                          id: "budgetHead",
                          name: "budgetHead",
                        }}
                        value={state.budgetHead || ""}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="">
                          --- Select Expense Head ---
                        </MenuItem>
                        {heads &&
                          heads.map((head) => (
                            <MenuItem key={head._id} value={head._id}>
                              {head.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budget-subhead">
                        Expense SubHead
                      </InputLabel>
                      <Select
                        labelId="budget-subhead"
                        id="budgetSubhead"
                        name="budgetSubHead"
                        inputProps={{
                          id: "budgetSubhead",
                          name: "budgetSubhead",
                        }}
                        value={state.budgetSubhead || ""}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="">
                          --- Select Expense SubHead ---
                        </MenuItem>
                        {subheads &&
                          subheads.map((subhead) => (
                            <MenuItem key={subhead._id} value={subhead._id}>
                              {subhead.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="budgetItem">Budget Category</InputLabel>
                      <Select
                        labelId="budgetItem"
                        id="budgetItem"
                        inputProps={{
                          id: "budgetItem",
                          name: "budgetItem",
                        }}
                        value={state.budgetItem || ""}
                        onChange={handleChange}
                        name="budgetItem"
                      >
                        <MenuItem disabled value="">
                          --- Select Budget Category ---
                        </MenuItem>
                        {items &&
                          items.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Description"
                      id="description"
                      name="description"
                      onChange={handleChange}
                      value={state.description || ""}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="costCategory">Cost Type</InputLabel>
                      <Select
                        labelId="costCategory"
                        id="costCategory"
                        inputProps={{
                          id: "costCategory",
                          name: "costCategory",
                        }}
                        value={state.costCategory || ""}
                        onChange={handleChange}
                        name="costCategory"
                      >
                        <MenuItem disabled value="">
                          --- Select Cost Category ---
                        </MenuItem>
                        <MenuItem value="project">Project</MenuItem>
                        <MenuItem value="division">
                          Division/Unit
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="costCenter">Cost Center</InputLabel>
                      <Select
                        labelId="costCenter"
                        id="costCenter"
                        inputProps={{
                          id: "costCenter",
                          name: "costCenter",
                        }}
                        value={state.costCenter || ""}
                        onChange={handleChange}
                        name="costCode"
                        disabled={loading}
                      >
                        <MenuItem disabled value="">
                          --- Select Cost Center ---
                        </MenuItem>
                        {costcodes &&
                          costcodes.map((cost) => (
                            <MenuItem key={cost._id} value={cost.code}>
                              {cost.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      // className={classes.textField}
                    >
                      <InputLabel id="head-label-id">Currency</InputLabel>
                      <Select
                        labelId="head-label-id"
                        id="rate"
                        label="Rate"
                        inputProps={{
                          id: "rate",
                          name: "rate",
                        }}
                        value={load ? "Loading..." : state.rate || 1}
                        onChange={handleChange}
                        name="rate"
                      >
                        <MenuItem value="" disabled>
                          --- Select Currency ---
                        </MenuItem>
                        {currencies &&
                          currencies.map((currency) => (
                            <MenuItem key={currency._id} value={currency.rate}>
                              {currency.code}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Amount"
                      id="amount"
                      name="amount"
                      onChange={handleChange}
                      value={state.amount || ""}
                      fullWidth
                      variant="outlined"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Converted Amount"
                      id="convertedAmount"
                      name="convertedAmount"
                      // onChange={handleChange}
                      value={currencyConvert(state.amount, state.rate)}
                      fullWidth
                      variant="outlined"
                      required
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      // color="secondary"
                      style={{
                        color: validCheck() ? "" : "#fff",
                        background: validCheck() || loading ? "" : "#4caf50",
                      }}
                      className={classes.btn}
                      size="large"
                      disabled={validCheck() || loading || openBackdrop}
                    >
                      {loading ? "Loading..." : "Save"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  heads: state.budgetHeads.heads.data,
  subheads: state.budgetSubheads.subheads.data,
  items: state.budgetItems.items.data,
  profile: state.user.profile,
  // error: state.annualBudget.annualBudgetLineError,
  error: state.monthlyBudget.budgetLineError,
  // annualBudget: state.annualBudget.annualBudget.data,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  monthlyBudgetLines:
    state.monthlyBudget.monthlyBudgetLines &&
    state.monthlyBudget.monthlyBudgetLines.data,
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes && state.costcode.costcodes.data,
});

const mapDispatchToProps = {
  getAllBudgetHeads,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,

  getAnnualBudgetById,

  getCostCodesByType,

  getAllMonthlyBudgetLineByBudgetId,
  createMonthlyBudgetLine,
  getMonthlyBudgetById,
  getAllMonthlyBudgetItems,
  getCurrencies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMonthlyBudgetLineModal);
