import {
  GET_ANNUAL_BUDGETS,
  GET_ALL_ANNUAL_BUDGETS,
  CREATE_ANNUAL_BUDGET,
  UPDATE_ANNUAL_BUDGET,
  CREATE_ANNUAL_BUDGET_LINE,
  GET_ALL_ANNUAL_BUDGET_LINES,
  GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID,
  ANNUAL_BUDGET_LINE_ERROR,
  DELETE_ANNUAL_BUDGET_LINE,
  GET_ANNUAL_BUDGET_BY_ID,
  GET_ANNUAL_BUDGET_LINE_BY_ID,
  REVIEW_ALL_ANNUAL_BUDGET,
  APPROVE_ALL_ANNUAL_BUDGET,
  SUBMIT_ANNUAL_BUDGET,
  CONFIRM_ANNUAL_BUDGET,
  DECLINE_ANNUAL_BUDGET,
  APPROVE_ANNUAL_BUDGET,
  REJECT_ANNUAL_BUDGET,
  UPDATE_ANNUAL_BUDGET_LINE,
  ANNUAL_BUDGETS_ERROR,
  ANNUAL_BUDGET_ERROR,
  GET_ALL_ANNUAL_BUGDETS_REVIEWS,
  GET_ALL_ANNUAL_BUGDETS_APPROVALS,
  APPROVE_SINGLE_ANNUAL_BUDGET,
  REQUEST_ANNUAL_BUDGET_MODIFICATION,
  REQUEST_ANNUAL_BUDGET_CLARIFICATION,

  GET_BUDGET_UTILIZATIONS,
  BUDGET_UTILIZATIONS_ERROR,
} from "../../types";

const initState = {
  annualBudgets: [],
  annualBudget: {},
  annualBudgetLines: [],
  annualBudgetLine: {},
  annualBudgetLineError: null,
  error: null,
  message: "",
  budgetUtilizations: [],
  success: false,
  loading: true,
};

const annualReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ANNUAL_BUDGETS:
      return {
        ...state,
        annualBudgets: action.payload,
      };
    case GET_ALL_ANNUAL_BUDGETS:
      return {
        ...state,
        annualBudgets: action.payload,
      };
    case GET_BUDGET_UTILIZATIONS:
      return {
        ...state,
        loading: false,
        success: true,
        budgetUtilizations: action.payload,
      };
    case BUDGET_UTILIZATIONS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };
    case GET_ALL_ANNUAL_BUGDETS_REVIEWS:
      return {
        ...state,
        annualBudgets: action.payload,
      };
    case GET_ALL_ANNUAL_BUGDETS_APPROVALS:
      return {
        ...state,
        annualBudgets: action.payload,
      };

    case CREATE_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case UPDATE_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case CREATE_ANNUAL_BUDGET_LINE:
      return {
        ...state,
        annualBudgetLines: [action.payload, ...state.annualBudgetLines],
      };
    case SUBMIT_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case GET_ANNUAL_BUDGET_BY_ID:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case GET_ANNUAL_BUDGET_LINE_BY_ID:
      return {
        ...state,
        annualBudgetLine: action.payload,
      };
    case GET_ALL_ANNUAL_BUDGET_LINES:
      return {
        ...state,
        annualBudgetLines: action.payload.data,
      };
    case GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID:
      return {
        ...state,
        annualBudgetLines: action.payload.data,
      };
    case ANNUAL_BUDGET_LINE_ERROR:
      return {
        ...state,
        annualBudgetLineError: action.error,
      };
    case REVIEW_ALL_ANNUAL_BUDGET:
    case APPROVE_ALL_ANNUAL_BUDGET:
      return {
        ...state,
        message: action.payload,
      };
    case ANNUAL_BUDGETS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case ANNUAL_BUDGET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case CONFIRM_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case APPROVE_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case UPDATE_ANNUAL_BUDGET_LINE:
      return {
        ...state,
        annualBudgetLine: action.payload,
      };

    case APPROVE_SINGLE_ANNUAL_BUDGET: {
      return {
        ...state,
        annualBudget: action.payload,
      };
    }
    case REQUEST_ANNUAL_BUDGET_CLARIFICATION:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case REQUEST_ANNUAL_BUDGET_MODIFICATION:
      return {
        ...state,
        annualBudget: action.payload,
      };

    case DECLINE_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case REJECT_ANNUAL_BUDGET:
      return {
        ...state,
        annualBudget: action.payload,
      };
    case DELETE_ANNUAL_BUDGET_LINE:
      return {
        ...state,
        annualBudgetLines: state.annualBudgetLines.filter(
          (annual) => annual._id !== action.payload
        ),
      };

    default:
      return state;
  }
};

export default annualReducer;
