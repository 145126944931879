import React, { useState, useEffect, useCallback } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Icon,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    updatePurchaseRequisition,
    submitPurchaseRequisition,
    getPurchaseRequisition,
    getPurchaseRequisitions,
} from "../../../../actions/purchasingActions";
// import { getDivisionMonthlyBudgetItems, getOneMonthlyBudgetItem } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines, getOneAnnualBudgetLineById } from "../../../../actions/annualAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.15) : darken("#fff", 0.80),
    },
    paper0: {
        padding: theme.spacing(2),
        marginBottom: 20,
        paddingBottom: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.05) : darken("#fff", 0.80),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    forWhom:"",
    purpose: "",
    dateNeeded: "",
    month: "",
    year:"",
    items: [{
        description: "",
        quantityNeeded: 1,
        unit: "",
        budgetItem: "",
    }],
    totalQuantityNeeded: '',
    requisitionGroup: "",
};

const INIT_ITEM = {
    description: "",
    quantityNeeded: 1,
    unit: "",
    budgetItem: "",
    budgetAmount: null,
}

const EditPurchaseRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);
    // const { divItems, oneItem } = useSelector((state) => state.monthlyBudget);
    const { annualBudgetLines, annualBudget } = useSelector((state) => state.annualBudget);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, purchase_requisition } = useSelector((state) => state.purchaseRequisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getPurchaseRequisitions());
        dispatch(getPurchaseRequisition(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityNeeded; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantityNeeded: total1,
            }));
        }
    }, [state.items]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (theItem) => {
        if (theItem.quantityNeeded > "0" && theItem.description !== ""  && theItem.budgetAmount !== "" && theItem.unit !== "" 
            && theItem.budgetItem !== "") {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([theItem]),
            }));
            setItem(INIT_ITEM);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    // console.log(purchase_requisition, annualBudgetLines)

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (purchase_requisition && purchase_requisition.purpose) || "",
            employee: (purchase_requisition && purchase_requisition.employee) || "",
            division: (purchase_requisition && purchase_requisition.division) || "",
            forWhom: (purchase_requisition && purchase_requisition.forWhom) || "",
            month: (purchase_requisition && purchase_requisition.month) || "",
            year: (purchase_requisition && purchase_requisition.year) || "",
            dateNeeded: (purchase_requisition && moment(purchase_requisition.dateNeeded).format("l")) || "",
            totalQuantityNeeded: (purchase_requisition && purchase_requisition.totalQuantityNeeded) || "",
            requisitionGroup: (purchase_requisition && purchase_requisition.requisitionGroup) || "",

            items: (purchase_requisition && purchase_requisition.items) || [],
            status: (purchase_requisition && purchase_requisition.status) || "",
            documentNo: (purchase_requisition && purchase_requisition.documentNo) || "",

            createdBy: (purchase_requisition && purchase_requisition.createdBy) || "",
            createdAt: (purchase_requisition && purchase_requisition.createdAt) || "",

            submittedBy: (purchase_requisition && purchase_requisition.submittedBy) || "",
            submittedAt: (purchase_requisition && purchase_requisition.submittedAt) || "",

            approvedBy: (purchase_requisition && purchase_requisition.approvedBy) || "",
            approvedAt: (purchase_requisition && purchase_requisition.approvedAt) || "",
            approveComment: (purchase_requisition && purchase_requisition.approveComment) || "",
            
            rejectedBy: (purchase_requisition && purchase_requisition.rejectedBy) || "",
            rejectedAt: (purchase_requisition && purchase_requisition.rejectedAt) || "",
            rejectComment: (purchase_requisition && purchase_requisition.rejectComment) || "",

            acceptedBy: (purchase_requisition && purchase_requisition.acceptedBy) || "",
            acceptedAt: (purchase_requisition && purchase_requisition.acceptedAt) || "",
            acceptComment: (purchase_requisition && purchase_requisition.acceptComment) || "",

            declinedBy: (purchase_requisition && purchase_requisition.declinedBy) || "",
            declinedAt: (purchase_requisition && purchase_requisition.declinedAt) || "",
            declineComment: (purchase_requisition && purchase_requisition.declineComment) || "",

        }));
    }, [purchase_requisition]);

    const handleChangeDate = (date) => {
        setState((prev) => ({
            ...prev,
            dateNeeded: date,
        }));
    };

    // const getDivisionMonthlyBudgetItemsCallback = useCallback(() => {
    //     let data = {
    //         year: state.year && state.year,
    //         month: state.month && state.month,
    //         serviceDivision: state && state.division._id,
    //     };
    //     dispatch(getDivisionMonthlyBudgetItems(data));
    // }, [dispatch, state]);

    // useEffect(() => {
    //     getDivisionMonthlyBudgetItemsCallback();
    // }, [getDivisionMonthlyBudgetItemsCallback]);

    // useEffect(() => {
    //     if (item.budgetItem !== "") {
    //         dispatch(getOneMonthlyBudgetItem({budgetItem: item.budgetItem}));
    //     }
    // }, [dispatch, item, id]);

    // useEffect(() => {
    //     if (oneItem) {
    //         setItem((prev) => ({
    //             ...prev,
    //             budgetAmount: oneItem && oneItem.amount,
    //         }));
    //     }
    // }, [oneItem]);

    // use annual budget
    const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
        if(state.division !==""){
            let data = {
                year: state.year && state.year,
                division: state && state.division._id,
            };
            dispatch(getDivisionYearAnnualBudgetLines(data));
        }
    }, [state, dispatch]);

    useEffect(() => {
        getDivisionYearAnnualBudgetLinesCallback();
    }, [getDivisionYearAnnualBudgetLinesCallback]);

    useEffect(() => {
        if (item.budgetItem !== "") {
            dispatch(getOneAnnualBudgetLineById(item.budgetItem));
        }
    }, [dispatch, item, id]);

    useEffect(() => {
        if (annualBudget.success) {
            setItem((prev) => ({
                ...prev,
                budgetAmount: annualBudget && annualBudget.data && annualBudget.data?.subTotal,
            }));
        }
    }, [annualBudget]);

    // console.log(annualBudget)

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            purpose: state.purpose,
            dateNeeded: state.dateNeeded,
            items: state.items,
            totalQuantityNeeded: state.totalQuantityNeeded,
            id,
            path: `purchasing/requisition/view/${id}`,
        };
        await dispatch(submitPurchaseRequisition(data));
        await dispatch(getPurchaseRequisitions);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Purchase Requisition Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/purchasing/requisition");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            purpose: state.purpose,
            dateNeeded: state.dateNeeded,
            totalQuantityNeeded: state.totalQuantityNeeded,
            items: state.items,
            id
        }
        await dispatch(updatePurchaseRequisition(data));
        setTimeout(async () => {
            await dispatch(getPurchaseRequisition(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Requisition Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Purchase Requisition
                </Typography>

                <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/purchasing/requisition")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Paper variant="outlined" className={classes.status}>
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Document No.
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    {loading ? "Loading..." : state.documentNo}
                                    
                                </Paper>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <Paper
                                    variant="outlined"
                                    className={clsx(
                                        state.status === "draft"
                                            ? classes.statusYellow
                                            : state.status === "submitted"
                                                ? classes.statusOrange
                                                : (state.status === "accepted" || state.status === "approved" || state.status === "confirmed" || state.status === "consented") ? classes.statusGreen
                                                    : (state.status === "issued" || state.status === "collected" || state.status === "transferred" || state.status === "returned" || state.status === "received" || state.status === "completed")
                                                        ? classes.statusDarkGreen
                                                        : classes.statusRed,
                                        classes.status
                                    )}
                                >
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Status
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        {loading ? "Loading..." : state.status}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>

                <Paper variant="outlined" square className={classes.paper}>            
                <form onSubmit={handleSubmitHandler}>
                    <div>
                    <Paper variant="outlined" square className={classes.paper0} >
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                label="Employee Name"
                                name="employeeName"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                value={(state.createdBy && state.createdBy.userName) || ""}
                                disabled
                                required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Division/Unit"
                                    name="division"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={(state.division && state.division.name) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Document No"
                                    name="documentNo"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={state.documentNo || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Month"
                                    name="month"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={(state.month && state.month) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Year"
                                    name="year"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleChange}
                                    value={(state.year && state.year) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    className={classes.marginBottom}
                                    autoOk
                                    format="DD/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    label="Date Needed"
                                    fullWidth
                                    required
                                    inputVariant="outlined"
                                    value={(state.dateNeeded && state.dateNeeded) || ""}
                                    minDate={state.dateNeeded}
                                    disablePast
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Requisition Category</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "requisitionGroup",
                                            name: "requisitionGroup",
                                        }}
                                        value={state.requisitionGroup || ""}
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        <MenuItem value="Regular">Regular Requisition</MenuItem>
                                        <MenuItem value="Asset Requisition" disabled>Asset Acquisition</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Purpose"
                                    multiline
                                    disabled={!isCanEdit}
                                    rows={3}
                                    name="purpose"
                                    value={(state.purpose && state.purpose) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper variant="outlined" square className={classes.paper0} style={{paddingBottom: 20}}>
                        <Typography variant="overline" className={classes.title2}>
                            Please select from the approved annual budget items of your subdivision for the year <u>{state.year}</u>.<br />
                            Click the green button to add.<br /> 
                            There is no limit to the number of items you can add.
                        </Typography>
                    </Paper>
                        
                    <Paper variant="outlined" square className={classes.paper0}>
                        {item ? (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                            >
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Budget Item</InputLabel>
                                        <Select
                                                inputProps={{
                                                    id: "budgetItem",
                                                    name: "budgetItem",
                                                }}
                                                value={item.budgetItem || ""}
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {annualBudgetLines &&
                                                    annualBudgetLines.map((stuff) => (
                                                        <MenuItem key={stuff._id} value={stuff._id}>
                                                            {stuff?.item?.name} {" - "} {stuff.subTotal && stuff.subTotal}
                                                        </MenuItem>
                                                    ))
                                                }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Budget Amount"
                                        name="budgetAmount"
                                        value={(item.budgetAmount && item.budgetAmount) || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Description of Item"
                                        name="description"
                                        value={item.description || ""}
                                        onChange={handleItemChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Quantity Needed"
                                        type="number"
                                        name="quantityNeeded"
                                        value={item.quantityNeeded || ""}
                                        onChange={handleItemChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} >
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Unit of Measurement</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "unit",
                                                name: "unit",
                                            }}
                                            value={item.unit}
                                            onChange={handleItemChange}
                                        >
                                            <MenuItem disabled>Select Unit</MenuItem>
                                            <MenuItem value="items">Items</MenuItem>
                                            <MenuItem value="packets">Packets</MenuItem>
                                            <MenuItem value="reams">Reams</MenuItem>
                                            <MenuItem value="kg">Kg</MenuItem>
                                            <MenuItem value="cartons">Cartons</MenuItem>
                                            <MenuItem value="crates">Crates</MenuItem>
                                            <MenuItem value="meters">Meters</MenuItem>
                                            <MenuItem value="kilometers">Kilometers</MenuItem>
                                            <MenuItem value="pieces">Pieces</MenuItem>
                                            <MenuItem value="dozens">Dozens</MenuItem>
                                            <MenuItem value="litres">Litres</MenuItem>
                                            <MenuItem value="gallons">Gallons</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Fab
                                        color="primary"
                                        onClick={() => handleAddItemsArray(item)}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Paper>

                        {/* To be filled by the Requestor */}
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Purchase Requisition Items
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper0}>
                                {state.items.map((sitem, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Budget Item</InputLabel>
                                                <Select
                                                        inputProps={{
                                                            id: "budgetItem",
                                                            name: "budgetItem",
                                                        }}
                                                        value={sitem.budgetItem}
                                                        disabled
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {annualBudgetLines &&
                                                            annualBudgetLines.map((item) => (
                                                                <MenuItem key={item._id} value={item._id}>
                                                                    {item?.item?.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Budget Amount"
                                                name="budgetAmount"
                                                value={sitem.budgetAmount || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Description of Item"
                                                name="description"
                                                value={sitem.description || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Needed"
                                                type="number"
                                                name="quantityNeeded"
                                                value={sitem.quantityNeeded || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1} >
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Unit of Measurement</InputLabel>
                                                <Select
                                                    inputProps={{
                                                        id: "unit",
                                                        name: "unit",
                                                    }}
                                                    value={sitem.unit}
                                                    disabled
                                                >
                                                    <MenuItem disabled>Select Unit</MenuItem>
                                                    <MenuItem value="items">Items</MenuItem>
                                                    <MenuItem value="packets">Packets</MenuItem>
                                                    <MenuItem value="reams">Reams</MenuItem>
                                                    <MenuItem value="kg">Kg</MenuItem>
                                                    <MenuItem value="cartons">Cartons</MenuItem>
                                                    <MenuItem value="crates">Crates</MenuItem>
                                                    <MenuItem value="meters">Meters</MenuItem>
                                                    <MenuItem value="kilometers">Kilometers</MenuItem>
                                                    <MenuItem value="pieces">Pieces</MenuItem>
                                                    <MenuItem value="dozens">Dozens</MenuItem>
                                                    <MenuItem value="litres">Litres</MenuItem>
                                                    <MenuItem value="gallons">Gallons</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>                                        
                                        <Grid item xs={12} md={1}>
                                            <label>&nbsp;</label>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveItemsArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>
                        </>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={9} />
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Total Quantity Needed"
                                    name="totalQuantityNeeded"
                                    value={state.totalQuantityNeeded || '0'}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {(state.rejectedAt && state.rejectedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.declinedAt && state.declinedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
                </Paper>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditPurchaseRequisition;
