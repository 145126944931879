module.exports = {
    // Store Management
    CREATE_STORE: "CREATE_STORE",
    CREATE_STORE_REQUEST: "CREATE_STORE_REQUEST",
    CREATE_STORE_FAIL: "CREATE_STORE_FAIL",

    GET_ALL_STORES: "GET_ALL_STORES",
    GET_ALL_STORES_REQUEST: "GET_ALL_STORES_REQUEST",
    GET_ALL_STORES_FAIL: "GET_ALL_STORES_FAIL",

    GET_STORE: "GET_STORE",
    GET_STORE_REQUEST: "GET_STORE_REQUEST",
    GET_STORE_FAIL: "GET_STORE_FAIL",

    GET_STORES_BY_LOCATION: "GET_STORES_BY_LOCATION",
    GET_STORES_BY_LOCATION_REQUEST: "GET_STORES_BY_LOCATION_REQUEST",
    GET_STORES_BY_LOCATION_FAIL: "GET_STORES_BY_LOCATION_FAIL",

    UPDATE_STORE: "UPDATE_STORE",
    UPDATE_STORE_REQUEST: "UPDATE_STORE_REQUEST",
    UPDATE_STORE_FAIL: "UPDATE_STORE_FAIL",

    DELETE_STORE: "DELETE_STORE",
    DELETE_STORE_REQUEST: "DELETE_STORE_REQUEST",
    DELETE_STORE_FAIL: "DELETE_STORE_FAIL",

    STORE_ERROR: "STORE_ERROR",
    RESET_STORE: "RESET_STORE",

    // Location Management
    CREATE_LOCATION: "CREATE_LOCATION",
    CREATE_LOCATION_REQUEST: "CREATE_LOCATION_REQUEST",
    CREATE_LOCATION_FAIL: "CREATE_LOCATION_FAIL",

    GET_ALL_LOCATIONS: "GET_ALL_LOCATIONS",
    GET_ALL_LOCATIONS_REQUEST: "GET_ALL_LOCATIONS_REQUEST",
    GET_ALL_LOCATIONS_FAIL: "GET_ALL_LOCATIONS_FAIL",

    GET_LOCATION: "GET_LOCATION",
    GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
    GET_LOCATION_FAIL: "GET_LOCATION_FAIL",

    GET_LOCATION_ITEMS: "GET_LOCATION_ITEMS",
    GET_LOCATION_ITEMS_REQUEST: "GET_LOCATION_ITEMS_REQUEST",
    GET_LOCATION_ITEMS_FAIL: "GET_LOCATION_ITEMS_FAIL",

    UPDATE_LOCATION: "UPDATE_LOCATION",
    UPDATE_LOCATION_REQUEST: "UPDATE_LOCATION_REQUEST",
    UPDATE_LOCATION_FAIL: "UPDATE_LOCATION_FAIL",

    DELETE_LOCATION: "DELETE_LOCATION",
    DELETE_LOCATION_REQUEST: "DELETE_LOCATION_REQUEST",
    DELETE_LOCATION_FAIL: "DELETE_LOCATION_FAIL",

    RESET_LOCATION: "RESET_LOCATION",

    // Inventory Categories
    CREATE_INVENTORY_CATEGORY: "CREATE_INVENTORY_CATEGORY",
    CREATE_INVENTORY_CATEGORY_REQUEST: "CREATE_INVENTORY_CATEGORY_REQUEST",
    CREATE_INVENTORY_CATEGORY_FAIL: "CREATE_INVENTORY_CATEGORY_FAIL",

    GET_ALL_INVENTORY_CATEGORIES: "GET_ALL_INVENTORY_CATEGORIES",
    GET_ALL_INVENTORY_CATEGORIES_REQUEST: "GET_ALL_INVENTORY_CATEGORIES_REQUEST",
    GET_ALL_INVENTORY_CATEGORIES_FAIL: "GET_ALL_INVENTORY_CATEGORIES_FAIL",

    GET_INVENTORY_CATEGORY_ITEMS: "GET_INVENTORY_CATEGORY_ITEMS",
    GET_INVENTORY_CATEGORY_ITEMS_REQUEST: "GET_INVENTORY_CATEGORY_ITEMS_REQUEST",
    GET_INVENTORY_CATEGORY_ITEMS_FAIL: "GET_INVENTORY_CATEGORY_ITEMS_FAIL",

    GET_INVENTORY_CATEGORY: "GET_INVENTORY_CATEGORY",
    GET_INVENTORY_CATEGORY_REQUEST: "GET_INVENTORY_CATEGORY_REQUEST",
    GET_INVENTORY_CATEGORY_FAIL: "GET_INVENTORY_CATEGORY_FAIL",

    UPDATE_INVENTORY_CATEGORY: "UPDATE_INVENTORY_CATEGORY",
    UPDATE_INVENTORY_CATEGORY_REQUEST: "UPDATE_INVENTORY_CATEGORY_REQUEST",
    UPDATE_INVENTORY_CATEGORY_FAIL: "UPDATE_INVENTORY_CATEGORY_FAIL",

    DELETE_INVENTORY_CATEGORY: "DELETE_INVENTORY_CATEGORY",
    DELETE_INVENTORY_CATEGORY_REQUEST: "DELETE_INVENTORY_CATEGORY_REQUEST",
    DELETE_INVENTORY_CATEGORY_FAIL: "DELETE_INVENTORY_CATEGORY_FAIL",

    // Inventory Items
    CREATE_INVENTORY_ITEM: "CREATE_INVENTORY_ITEM",
    CREATE_INVENTORY_ITEM_REQUEST: "CREATE_INVENTORY_ITEM_REQUEST",
    CREATE_INVENTORY_ITEM_FAIL: "CREATE_INVENTORY_ITEM_FAIL",

    GET_ALL_INVENTORY_ITEMS: "GET_ALL_INVENTORY_ITEMS",
    GET_ALL_INVENTORY_ITEMS_REQUEST: "GET_ALL_INVENTORY_ITEMS_REQUEST",
    GET_ALL_INVENTORY_ITEMS_FAIL: "GET_ALL_INVENTORY_ITEMS_FAIL",

    GET_INVENTORY_ITEM: "GET_INVENTORY_ITEM",
    GET_INVENTORY_ITEM_REQUEST: "GET_INVENTORY_ITEM_REQUEST",
    GET_INVENTORY_ITEM_FAIL: "GET_INVENTORY_ITEM_FAIL",

    UPDATE_INVENTORY_ITEM: "UPDATE_INVENTORY_ITEM",
    UPDATE_INVENTORY_ITEM_REQUEST: "UPDATE_INVENTORY_ITEM_REQUEST",
    UPDATE_INVENTORY_ITEM_FAIL: "UPDATE_INVENTORY_ITEM_FAIL",

    DELETE_INVENTORY_ITEM: "DELETE_INVENTORY_ITEM",
    DELETE_INVENTORY_ITEM_REQUEST: "DELETE_INVENTORY_ITEM_REQUEST",
    DELETE_INVENTORY_ITEM_FAIL: "DELETE_INVENTORY_ITEM_FAIL",

    RESET_ITEM: "RESET_ITEM",

    // Receiving Inspection
    CREATE_INVENTORY_RECEIPT: "CREATE_INVENTORY_RECEIPT",
    CREATE_INVENTORY_RECEIPT_REQUEST: "CREATE_INVENTORY_RECEIPT_REQUEST",
    CREATE_INVENTORY_RECEIPT_FAIL: "CREATE_INVENTORY_RECEIPT_FAIL",

    UPDATE_INVENTORY_RECEIPT: "UPDATE_INVENTORY_RECEIPT",
    UPDATE_INVENTORY_RECEIPT_REQUEST: "UPDATE_INVENTORY_RECEIPT_REQUEST",
    UPDATE_INVENTORY_RECEIPT_FAIL: "UPDATE_INVENTORY_RECEIPT_FAIL",

    DELETE_INVENTORY_RECEIPT: "DELETE_INVENTORY_RECEIPT",
    DELETE_INVENTORY_RECEIPT_REQUEST: "DELETE_INVENTORY_RECEIPT_REQUEST",
    DELETE_INVENTORY_RECEIPT_FAIL: "DELETE_INVENTORY_RECEIPT_FAIL",

    SUBMIT_INVENTORY_RECEIPT: "SUBMIT_INVENTORY_RECEIPT",
    SUBMIT_INVENTORY_RECEIPT_REQUEST: "SUBMIT_INVENTORY_RECEIPT_REQUEST",
    SUBMIT_INVENTORY_RECEIPT_FAIL: "SUBMIT_INVENTORY_RECEIPT_FAIL",

    ACCEPT_INVENTORY_RECEIPT: "ACCEPT_INVENTORY_RECEIPT",
    ACCEPT_INVENTORY_RECEIPT_REQUEST: "ACCEPT_INVENTORY_RECEIPT_REQUEST",
    ACCEPT_INVENTORY_RECEIPT_FAIL: "ACCEPT_INVENTORY_RECEIPT_FAIL",

    REJECT_INVENTORY_RECEIPT: "REJECT_INVENTORY_RECEIPT",
    REJECT_INVENTORY_RECEIPT_REQUEST: "REJECT_INVENTORY_RECEIPT_REQUEST",
    REJECT_INVENTORY_RECEIPT_FAIL: "REJECT_INVENTORY_RECEIPT_FAIL",

    RECEIVE_INVENTORY_RECEIPT: "RECEIVE_INVENTORY_RECEIPT",
    RECEIVE_INVENTORY_RECEIPT_REQUEST: "RECEIVE_INVENTORY_RECEIPT_REQUEST",
    RECEIVE_INVENTORY_RECEIPT_FAIL: "RECEIVE_INVENTORY_RECEIPT_FAIL",

    APPROVE_INVENTORY_RECEIPT: "APPROVE_INVENTORY_RECEIPT",
    APPROVE_INVENTORY_RECEIPT_REQUEST: "APPROVE_INVENTORY_RECEIPT_REQUEST",
    APPROVE_INVENTORY_RECEIPT_FAIL: "APPROVE_INVENTORY_RECEIPT_FAIL",

    GET_ALL_INVENTORY_RECEIPTS: "GET_ALL_INVENTORY_RECEIPTS",
    GET_ALL_INVENTORY_RECEIPTS_REQUEST: "GET_ALL_INVENTORY_RECEIPTS_REQUEST",
    GET_ALL_INVENTORY_RECEIPTS_FAIL: "GET_ALL_INVENTORY_RECEIPTS_FAIL",

    GET_INVENTORY_RECEIPT: "GET_INVENTORY_RECEIPT",
    GET_INVENTORY_RECEIPT_REQUEST: "GET_INVENTORY_RECEIPT_REQUEST",
    GET_INVENTORY_RECEIPT_FAIL: "GET_INVENTORY_RECEIPT_FAIL",

    RESET_RECEIPT: "RESET_RECEIPT",

    // Inventory Requisition
    CREATE_REQUISITION: "CREATE_REQUISITION",
    CREATE_REQUISITION_REQUEST: "CREATE_REQUISITION_REQUEST",
    CREATE_REQUISITION_FAIL: "CREATE_REQUISITION_FAIL",

    GET_ALL_REQUISITIONS: "GET_ALL_REQUISITIONS",
    GET_ALL_REQUISITIONS_REQUEST: "GET_ALL_REQUISITIONS_REQUEST",
    GET_ALL_REQUISITIONS_FAIL: "GET_ALL_REQUISITIONS_FAIL",

    GET_REQUISITION: "GET_REQUISITION",
    GET_REQUISITION_REQUEST: "GET_REQUISITION_REQUEST",
    GET_REQUISITION_FAIL: "GET_REQUISITION_FAIL",

    GET_CHECK_REQUISITIONS: "GET_CHECK_REQUISITIONS",
    GET_CHECK_REQUISITIONS_REQUEST: "GET_CHECK_REQUISITIONS_REQUEST",
    GET_CHECK_REQUISITIONS_FAIL: "GET_CHECK_REQUISITIONS_FAIL",

    GET_QUERY_REQUISITIONS: "GET_QUERY_REQUISITIONS",
    GET_QUERY_REQUISITIONS_REQUEST: "GET_QUERY_REQUISITIONS_REQUEST",
    GET_QUERY_REQUISITIONS_FAIL: "GET_QUERY_REQUISITIONS_FAIL",

    GET_REQUISITIONS_BY_LOCATION: "GET_REQUISITIONS_BY_LOCATION",
    GET_REQUISITIONS_BY_LOCATION_REQUEST: "GET_REQUISITIONS_BY_LOCATION_REQUEST",
    GET_REQUISITIONS_BY_LOCATION_FAIL: "GET_REQUISITIONS_BY_LOCATION_FAIL",

    UPDATE_REQUISITION: "UPDATE_REQUISITION",
    UPDATE_REQUISITION_REQUEST: "UPDATE_REQUISITION_REQUEST",
    UPDATE_REQUISITION_FAIL: "UPDATE_REQUISITION_FAIL",

    DELETE_REQUISITION: "DELETE_REQUISITION",
    DELETE_REQUISITION_REQUEST: "DELETE_REQUISITION_REQUEST",
    DELETE_REQUISITION_FAIL: "DELETE_REQUISITION_FAIL",

    SUBMIT_REQUISITION: "SUBMIT_REQUISITION",
    SUBMIT_REQUISITION_REQUEST: "SUBMIT_REQUISITION_REQUEST",
    SUBMIT_REQUISITION_FAIL: "SUBMIT_REQUISITION_FAIL",

    ACCEPT_REQUISITION: "ACCEPT_REQUISITION",
    ACCEPT_REQUISITION_REQUEST: "ACCEPT_REQUISITION_REQUEST",
    ACCEPT_REQUISITION_FAIL: "ACCEPT_REQUISITION_FAIL",

    DECLINE_REQUISITION: "DECLINE_REQUISITION",
    DECLINE_REQUISITION_REQUEST: "DECLINE_REQUISITION_REQUEST",
    DECLINE_REQUISITION_FAIL: "DECLINE_REQUISITION_FAIL",

    CONSENT_REQUISITION: "CONSENT_REQUISITION",
    CONSENT_REQUISITION_REQUEST: "CONSENT_REQUISITION_REQUEST",
    CONSENT_REQUISITION_FAIL: "CONSENT_REQUISITION_FAIL",

    REFUSE_REQUISITION: "REFUSE_REQUISITION",
    REFUSE_REQUISITION_REQUEST: "REFUSE_REQUISITION_REQUEST",
    REFUSE_REQUISITION_FAIL: "REFUSE_REQUISITION_FAIL",

    CONFIRM_REQUISITION: "CONFIRM_REQUISITION",
    CONFIRM_REQUISITION_REQUEST: "CONFIRM_REQUISITION_REQUEST",
    CONFIRM_REQUISITION_FAIL: "CONFIRM_REQUISITION_FAIL",

    REJECT_REQUISITION: "REJECT_REQUISITION",
    REJECT_REQUISITION_REQUEST: "REJECT_REQUISITION_REQUEST",
    REJECT_REQUISITION_FAIL: "REJECT_REQUISITION_FAIL",

    ISSUE_REQUISITION: "ISSUE_REQUISITION",
    ISSUE_REQUISITION_REQUEST: "ISSUE_REQUISITION_REQUEST",
    ISSUE_REQUISITION_FAIL: "ISSUE_REQUISITION_FAIL",

    CANCEL_REQUISITION: "CANCEL_REQUISITION",
    CANCEL_REQUISITION_REQUEST: "CANCEL_REQUISITION_REQUEST",
    CANCEL_REQUISITION_FAIL: "CANCEL_REQUISITION_FAIL",

    COLLECT_REQUISITION: "COLLECT_REQUISITION",
    COLLECT_REQUISITION_REQUEST: "COLLECT_REQUISITION_REQUEST",
    COLLECT_REQUISITION_FAIL: "COLLECT_REQUISITION_FAIL",

    LEAVE_REQUISITION: "LEAVE_REQUISITION",
    LEAVE_REQUISITION_REQUEST: "LEAVE_REQUISITION_REQUEST",
    LEAVE_REQUISITION_FAIL: "LEAVE_REQUISITION_FAIL",

    TRANSFER_REQUISITION: "TRANSFER_REQUISITION",
    TRANSFER_REQUISITION_REQUEST: "TRANSFER_REQUISITION_REQUEST",
    TRANSFER_REQUISITION_FAIL: "TRANSFER_REQUISITION_FAIL",

    RECEIVE_REQUISITION: "RECEIVE_REQUISITION",
    RECEIVE_REQUISITION_REQUEST: "RECEIVE_REQUISITION_REQUEST",
    RECEIVE_REQUISITION_FAIL: "RECEIVE_REQUISITION_FAIL",

    RETURN_REQUISITION: "RETURN_REQUISITION",
    RETURN_REQUISITION_REQUEST: "RETURN_REQUISITION_REQUEST",
    RETURN_REQUISITION_FAIL: "RETURN_REQUISITION_FAIL",

    COMPLETE_REQUISITION: "COMPLETE_REQUISITION",
    COMPLETE_REQUISITION_REQUEST: "COMPLETE_REQUISITION_REQUEST",
    COMPLETE_REQUISITION_FAIL: "COMPLETE_REQUISITION_FAIL",

    RESET_REQUISITION: "RESET_REQUISITION",

    // Inventory Count
    CREATE_INVENTORYCOUNT: "CREATE_INVENTORYCOUNT",
    CREATE_INVENTORYCOUNT_REQUEST: "CREATE_INVENTORYCOUNT_REQUEST",
    CREATE_INVENTORYCOUNT_FAIL: "CREATE_INVENTORYCOUNT_FAIL",

    GET_ALL_INVENTORYCOUNTS: "GET_ALL_INVENTORYCOUNTS",
    GET_ALL_INVENTORYCOUNTS_REQUEST: "GET_ALL_INVENTORYCOUNTS_REQUEST",
    GET_ALL_INVENTORYCOUNTS_FAIL: "GET_ALL_INVENTORYCOUNTS_FAIL",

    GET_INVENTORYCOUNT: "GET_INVENTORYCOUNT",
    GET_INVENTORYCOUNT_REQUEST: "GET_INVENTORYCOUNT_REQUEST",
    GET_INVENTORYCOUNT_FAIL: "GET_INVENTORYCOUNT_FAIL",

    GET_INVENTORYCOUNTS_BY_LOCATION: "GET_INVENTORYCOUNTS_BY_LOCATION",
    GET_INVENTORYCOUNTS_BY_LOCATION_REQUEST: "GET_INVENTORYCOUNTS_BY_LOCATION_REQUEST",
    GET_INVENTORYCOUNTS_BY_LOCATION_FAIL: "GET_INVENTORYCOUNTS_BY_LOCATION_FAIL",

    UPDATE_INVENTORYCOUNT: "UPDATE_INVENTORYCOUNT",
    UPDATE_INVENTORYCOUNT_REQUEST: "UPDATE_INVENTORYCOUNT_REQUEST",
    UPDATE_INVENTORYCOUNT_FAIL: "UPDATE_INVENTORYCOUNT_FAIL",

    DELETE_INVENTORYCOUNT: "DELETE_INVENTORYCOUNT",
    DELETE_INVENTORYCOUNT_REQUEST: "DELETE_INVENTORYCOUNT_REQUEST",
    DELETE_INVENTORYCOUNT_FAIL: "DELETE_INVENTORYCOUNT_FAIL",

    SUBMIT_INVENTORYCOUNT: "SUBMIT_INVENTORYCOUNT",
    SUBMIT_INVENTORYCOUNT_REQUEST: "SUBMIT_INVENTORYCOUNT_REQUEST",
    SUBMIT_INVENTORYCOUNT_FAIL: "SUBMIT_INVENTORYCOUNT_FAIL",

    ACCEPT_INVENTORYCOUNT: "ACCEPT_INVENTORYCOUNT",
    ACCEPT_INVENTORYCOUNT_REQUEST: "ACCEPT_INVENTORYCOUNT_REQUEST",
    ACCEPT_INVENTORYCOUNT_FAIL: "ACCEPT_INVENTORYCOUNT_FAIL",

    DECLINE_INVENTORYCOUNT: "DECLINE_INVENTORYCOUNT",
    DECLINE_INVENTORYCOUNT_REQUEST: "DECLINE_INVENTORYCOUNT_REQUEST",
    DECLINE_INVENTORYCOUNT_FAIL: "DECLINE_INVENTORYCOUNT_FAIL",

    APPROVE_INVENTORYCOUNT: "APPROVE_INVENTORYCOUNT",
    APPROVE_INVENTORYCOUNT_REQUEST: "APPROVE_INVENTORYCOUNT_REQUEST",
    APPROVE_INVENTORYCOUNT_FAIL: "APPROVE_INVENTORYCOUNT_FAIL",

    REJECT_INVENTORYCOUNT: "REJECT_INVENTORYCOUNT",
    REJECT_INVENTORYCOUNT_REQUEST: "REJECT_INVENTORYCOUNT_REQUEST",
    REJECT_INVENTORYCOUNT_FAIL: "REJECT_INVENTORYCOUNT_FAIL",

    ISSUE_INVENTORYCOUNT: "ISSUE_INVENTORYCOUNT",
    ISSUE_INVENTORYCOUNT_REQUEST: "ISSUE_INVENTORYCOUNT_REQUEST",
    ISSUE_INVENTORYCOUNT_FAIL: "ISSUE_INVENTORYCOUNT_FAIL",

    RECEIVE_INVENTORYCOUNT: "RECEIVE_INVENTORYCOUNT",
    RECEIVE_INVENTORYCOUNT_REQUEST: "RECEIVE_INVENTORYCOUNT_REQUEST",
    RECEIVE_INVENTORYCOUNT_FAIL: "RECEIVE_INVENTORYCOUNT_FAIL",

    RETURN_INVENTORYCOUNT: "RETURN_INVENTORYCOUNT",
    RETURN_INVENTORYCOUNT_REQUEST: "RETURN_INVENTORYCOUNT_REQUEST",
    RETURN_INVENTORYCOUNT_FAIL: "RETURN_INVENTORYCOUNT_FAIL",

    RESET_INVENTORYCOUNT: "RESET_INVENTORYCOUNT",

    // Gate Pass
    CREATE_GATEPASS: "CREATE_GATEPASS",
    CREATE_GATEPASS_REQUEST: "CREATE_GATEPASS_REQUEST",
    CREATE_GATEPASS_FAIL: "CREATE_GATEPASS_FAIL",

    GET_ALL_GATEPASSES: "GET_ALL_GATEPASSES",
    GET_ALL_GATEPASSES_REQUEST: "GET_ALL_GATEPASSES_REQUEST",
    GET_ALL_GATEPASSES_FAIL: "GET_ALL_GATEPASSES_FAIL",

    GET_GATEPASS: "GET_GATEPASS",
    GET_GATEPASS_REQUEST: "GET_GATEPASS_REQUEST",
    GET_GATEPASS_FAIL: "GET_GATEPASS_FAIL",

    UPDATE_GATEPASS: "UPDATE_GATEPASS",
    UPDATE_GATEPASS_REQUEST: "UPDATE_GATEPASS_REQUEST",
    UPDATE_GATEPASS_FAIL: "UPDATE_GATEPASS_FAIL",

    DELETE_GATEPASS: "DELETE_GATEPASS",
    DELETE_GATEPASS_REQUEST: "DELETE_GATEPASS_REQUEST",
    DELETE_GATEPASS_FAIL: "DELETE_GATEPASS_FAIL",

    SUBMIT_GATEPASS: "SUBMIT_GATEPASS",
    SUBMIT_GATEPASS_REQUEST: "SUBMIT_GATEPASS_REQUEST",
    SUBMIT_GATEPASS_FAIL: "SUBMIT_GATEPASS_FAIL",

    REVIEW_GATEPASS: "REVIEW_GATEPASS",
    REVIEW_GATEPASS_REQUEST: "REVIEW_GATEPASS_REQUEST",
    REVIEW_GATEPASS_FAIL: "REVIEW_GATEPASS_FAIL",

    ACCEPT_GATEPASS: "ACCEPT_GATEPASS",
    ACCEPT_GATEPASS_REQUEST: "ACCEPT_GATEPASS_REQUEST",
    ACCEPT_GATEPASS_FAIL: "ACCEPT_GATEPASS_FAIL",

    APPROVE_GATEPASS: "APPROVE_GATEPASS",
    APPROVE_GATEPASS_REQUEST: "APPROVE_GATEPASS_REQUEST",
    APPROVE_GATEPASS_FAIL: "APPROVE_GATEPASS_FAIL",

    GRANT_GATEPASS: "GRANT_GATEPASS",
    GRANT_GATEPASS_REQUEST: "GRANT_GATEPASS_REQUEST",
    GRANT_GATEPASS_FAIL: "GRANT_GATEPASS_FAIL",

    REJECT_GATEPASS: "REJECT_GATEPASS",
    REJECT_GATEPASS_REQUEST: "REJECT_GATEPASS_REQUEST",
    REJECT_GATEPASS_FAIL: "REJECT_GATEPASS_FAIL",

    COMPLETE_GATEPASS: "COMPLETE_GATEPASS",
    COMPLETE_GATEPASS_REQUEST: "COMPLETE_GATEPASS_REQUEST",
    COMPLETE_GATEPASS_FAIL: "COMPLETE_GATEPASS_FAIL",

    RESET_GATEPASS: "RESET_GATEPASS",

    // GRN
    CREATE_GRN: "CREATE_GRN",
    CREATE_GRN_REQUEST: "CREATE_GRN_REQUEST",
    CREATE_GRN_FAIL: "CREATE_GRN_FAIL",

    SUBMIT_GRN_REQUEST: "SUBMIT_GRN_REQUEST",
    SUBMIT_GRN: "SUBMIT_GRN",
    SUBMIT_GRN_FAIL: "SUBMIT_GRN_FAIL",

    GET_ALL_GRNS: "GET_ALL_GRNS",
    GET_ALL_GRNS_REQUEST: "GET_ALL_GRNS_REQUEST",
    GET_ALL_GRNS_FAIL: "GET_ALL_GRNS_FAIL",

    GET_GRN: "GET_GRN",
    GET_GRN_REQUEST: "GET_GRN_REQUEST",
    GET_GRN_FAIL: "GET_GRN_FAIL",
    
    UPDATE_GRN: "UPDATE_GRN",
    UPDATE_GRN_REQUEST: "UPDATE_GRN_REQUEST",
    UPDATE_GRN_FAIL: "UPDATE_GRN_FAIL",

    DELETE_GRN: "DELETE_GRN",
    DELETE_GRN_REQUEST: "DELETE_GRN_REQUEST",
    DELETE_GRN_FAIL: "DELETE_GRN_FAIL",

    APPROVE_GRN: "APPROVE_GRN",
    APPROVE_GRN_REQUEST: "APPROVE_GRN_REQUEST",
    APPROVE_GRN_FAIL: "APPROVE_GRN_FAIL",

    ACCEPT_GRN: "ACCEPT_GRN",
    ACCEPT_GRN_REQUEST: "ACCEPT_GRN_REQUEST",
    ACCEPT_GRN_FAIL: "ACCEPT_GRN_FAIL",

    REJECT_GRN: "REJECT_GRN",
    REJECT_GRN_REQUEST: "REJECT_GRN_REQUEST",
    REJECT_GRN_FAIL: "REJECT_GRN_FAIL",

    DECLINE_GRN: "DECLINE_GRN",
    DECLINE_GRN_REQUEST: "DECLINE_GRN_REQUEST",
    DECLINE_GRN_FAIL: "DECLINE_GRN_FAIL",

    GRN_ERROR: "GRN_ERROR",
    RESET_GRN: "RESET_GRN",

};
