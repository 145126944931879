import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    Fab,
    ButtonGroup,
    Hidden,
    Radio,
    RadioGroup,
    FormControlLabel, 
    FormControl,
    FormLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../atoms/Feedback";
import {
    updateAssetRequisition,
    submitAssetRequisition,
    getAssetRequisition,
    getAssetRequisitions,
} from "../../../actions/assetRequisitionActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
        marginBottom: 20
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    capitalBudget: "",
    description: "",
    justification: "",
    expectedLife: "",
    paymentMode: "",
    requestDate: "",
    quotations: [],
};

const INIT_QUOTATION = {
    description: "",
    terms: "",
    amount: "",
    newUsed: "",
    vendor: "",
}

const EditAssetRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [quotation, setQuotation] = useState(INIT_QUOTATION);
    const { error, loading, asset_requisition } = useSelector((state) => state.assetRequisition);
    
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getAssetRequisitions());
        dispatch(getAssetRequisition(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            capitalBudget: (asset_requisition && asset_requisition.capitalBudget) || "",
            description: (asset_requisition && asset_requisition.description) || "",
            justification: (asset_requisition && asset_requisition.justification) || "",
            expectedLife: (asset_requisition && asset_requisition.expectedLife) || "",
            paymentMode: (asset_requisition && asset_requisition.paymentMode) || "",
            quotations: (asset_requisition && asset_requisition.quotations) || [],

            employee: (asset_requisition && asset_requisition.employee) || "",
            division: (asset_requisition && asset_requisition.division) || "",
            month: (asset_requisition && asset_requisition.month) || "",
            year: (asset_requisition && asset_requisition.year) || "",
            requestDate: (asset_requisition && moment(asset_requisition.requestDate).format("l")) || "",
            
            status: (asset_requisition && asset_requisition.status) || "",
            documentNo: (asset_requisition && asset_requisition.documentNo) || "",

            createdBy: (asset_requisition && asset_requisition.createdBy) || "",
            createdAt: (asset_requisition && asset_requisition.createdAt) || "",

            submittedBy: (asset_requisition && asset_requisition.submittedBy) || "",
            submittedAt: (asset_requisition && asset_requisition.submittedAt) || "",

            approvedBy: (asset_requisition && asset_requisition.approvedBy) || "",
            approvedAt: (asset_requisition && asset_requisition.approvedAt) || "",
            approveComment: (asset_requisition && asset_requisition.approveComment) || "",
            
            rejectedBy: (asset_requisition && asset_requisition.rejectedBy) || "",
            rejectedAt: (asset_requisition && asset_requisition.rejectedAt) || "",
            rejectComment: (asset_requisition && asset_requisition.rejectComment) || "",

            acceptedBy: (asset_requisition && asset_requisition.acceptedBy) || "",
            acceptedAt: (asset_requisition && asset_requisition.acceptedAt) || "",
            acceptComment: (asset_requisition && asset_requisition.acceptComment) || "",

            declinedBy: (asset_requisition && asset_requisition.declinedBy) || "",
            declinedAt: (asset_requisition && asset_requisition.declinedAt) || "",
            declineComment: (asset_requisition && asset_requisition.declineComment) || "",

        }));
    }, [asset_requisition]);

    const handleItemChange = (e) => {
        e.persist();
        setQuotation((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (quotation) => {
        if (quotation.amount > "0" && quotation.description !== "" && quotation.terms !== "" && quotation.vendor !== "" && quotation.newUsed !== "") {
            setState((prev) => ({
                ...prev,
                quotations: prev.quotations.concat([quotation]),
            }));
            setQuotation(INIT_QUOTATION);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            quotations: prev.quotations.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            capitalBudget: state.capitalBudget,
            justification: state.justification,
            description: state.description,
            expectedLife: state.expectedLife,
            paymentMode: state.paymentMode,
            quotations: state.quotations,
            id,
            path: `asset/requisition/view/${id}`,
        };
        await dispatch(submitAssetRequisition(data));
        await dispatch(getAssetRequisitions);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Capital Asset Requisition Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/asset/requisition");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            capitalBudget: state.capitalBudget,
            justification: state.justification,
            description: state.description,
            expectedLife: state.expectedLife,
            paymentMode: state.paymentMode,
            quotations: state.quotations,
            id
        }
        await dispatch(updateAssetRequisition(data));
        setTimeout(async () => {
            await dispatch(getAssetRequisition(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Capital Asset Requisition Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" || state.status === "disapproved" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.month || 
        !state.year || 
        !state.requestDate ||
        !state.capitalBudget ||
        !state.description || 
        !state.justification || 
        !state.expectedLife || 
        !state.paymentMode || 
        (state.quotations.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Capital Asset Requisition Request
                </Typography>

                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/asset/requisition")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : state.status === "reviewed"
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "sent")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                label="Employee Name"
                                name="employeeName"
                                variant="outlined"
                                fullWidth
                                value={(state.createdBy && state.createdBy.userName) || ""}
                                disabled
                                required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Division/Unit"
                                    name="division"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.division && state.division.name) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Document No"
                                    name="documentNo"
                                    variant="outlined"
                                    fullWidth
                                    value={state.documentNo || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Month"
                                    name="month"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.month && state.month) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Year"
                                    name="year"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.year && state.year) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DatePicker
                                    className={classes.marginBottom}
                                    autoOk
                                    format="DD/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    label="Request Date"
                                    fullWidth
                                    required
                                    inputVariant="outlined"
                                    value={(state.requestDate && state.requestDate) || ""}
                                    disabled
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Capital Budget"
                                    disabled={!isCanEdit}
                                    name="capitalBudget"
                                    value={(state.capitalBudget && state.capitalBudget) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Expected Life"
                                    disabled={!isCanEdit}
                                    name="expectedLife"
                                    value={(state.expectedLife && state.expectedLife) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Complete item description including accessories"
                                    disabled={!isCanEdit}
                                    name="description"
                                    value={(state.description && state.description) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Reason or justification for request, including use and capability"
                                    disabled={!isCanEdit}
                                    name="justification"
                                    value={(state.justification && state.justification) || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{color: "currentcolor", }}> 
                                        Payment Mode: 
                                    </FormLabel>
                                    <RadioGroup row onChange={handleChange}>
                                        <FormControlLabel
                                            value="Credit Card"
                                            control={<Radio checked={(state.paymentMode === "Credit Card") ? true : false} />}
                                            label="Credit Card"
                                            name="paymentMode"
                                        />
                                        <FormControlLabel
                                            value="Cheque"
                                            control={<Radio checked={(state.paymentMode === "Cheque") ? true : false} />}
                                            label="Cheque"
                                            name="paymentMode"
                                        />
                                        <FormControlLabel
                                            value="Bank Transfer"
                                            control={<Radio checked={(state.paymentMode === "Bank Transfer") ? true : false} />}
                                            label="Bank Transfer"
                                            name="paymentMode"
                                        />
                                        <FormControlLabel
                                            value="Cash"
                                            control={<Radio checked={(state.paymentMode === "Cash") ? true : false} />}
                                            label="Cash"
                                            name="paymentMode"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Typography variant="overline" className={classes.title2}>
                            Add Price or Lease Quotations
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {quotation ? (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                >
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Description"
                                            name="description"
                                            value={quotation.description || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="New or Used"
                                            name="newUsed"
                                            value={(quotation.newUsed && quotation.newUsed) || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Vendor"
                                            name="vendor"
                                            value={(quotation.vendor && quotation.vendor) || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Terms"
                                            name="terms"
                                            value={(quotation.terms && quotation.terms) || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Amount"
                                            name="amount"
                                            type="number"
                                            value={(quotation.amount && quotation.amount) || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="primary"
                                            style={{ backgroundColor: "#093" }}
                                            onClick={() => handleAddItemsArray(quotation)}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                             ) : null}
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Price or Lease Quotations
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.quotations.map((sitem, i) => (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                    key={i}
                                >
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Description"
                                            name="description"
                                            value={sitem.description || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="New or Used"
                                            name="newUsed"
                                            value={(sitem.newUsed && sitem.newUsed) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Vendor"
                                            name="vendor"
                                            value={(sitem.vendor && sitem.vendor) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Terms"
                                            name="terms"
                                            value={(sitem.terms && sitem.terms) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Amount"
                                            name="amount"
                                            type="number"
                                            value={(sitem.amount && sitem.amount) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="secondary"
                                            onClick={() => handleRemoveItemsArray(i)}
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                             ))}
                        </Paper>

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {(state.rejectedAt && state.rejectedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {(state.declinedAt && state.declinedBy) && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading || validCheck()}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditAssetRequisition;
