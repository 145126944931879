import React, { useEffect } from "react";
import { Grid, Tabs, Button, Divider, ButtonGroup,
    Tab, Typography, Box, Paper, makeStyles, TextField, darken, Backdrop,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel, FormControl
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../actions/userActions";
import { useParams, useHistory } from "react-router-dom";
import { getVisitorAppointment, submitVisitorAppointment } from "../../../actions/visitorsActions";
import Feedback from "../../atoms/Feedback";
import CheckinVisitModal from "./modals/CheckinVisitModal";
import CheckoutVisitModal from "./modals/CheckoutVisitModal";
import clsx from "clsx";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box paddingTop={3} paddingLeft={1} paddingRight={1}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 18,
        padddingBottom: 5,
        fontFamily: "Rubik",
        textTransform: "none"
    },
    title4: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        borderBottomColor: '#ccc',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 15,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper2: {
        width: "100%",
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.15),
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.15) : darken("#fff", 0.80),
    },
    paper0: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.05) : darken("#fff", 0.80),
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: theme.palette.type === "light" ? darken("#fff", 0.008) : darken("#fff", 0.80),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2a: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        background: "#8b0000",
        color: "#fff",
        "&:hover": {
            background: darken("#8b0000", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn3: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: "120px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
}));

const INIT_STATE = {
    visitorName: "",
    visitorEmail: "",
    visitorPhone: "",
    visitPurpose: "",
    visitDetails: "",
    visitDuration: "",
    visitTime: "",
    visitDate: "",
    visitCode: "",
    visitQRCode: "",
};

const ViewVisitor = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const [state, setState] = React.useState(INIT_STATE);
    const [openCheckin, setOpenCheckin] = React.useState(false);
    const [openCheckout, setOpenCheckout] = React.useState(false);
    const { loading, error, visitor } = useSelector((state) => state.visitors);
    const { profile } = useSelector((state) => state.user);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getVisitorAppointment(id));
    }, [dispatch, id]);

    const handleOpenCheckin = (id) => {
        setOpenCheckin(true);
    };
    const handleCloseCheckin = () => {
        setOpenCheckin(false);
    };
    const handleOpenCheckout = (id) => {
        setOpenCheckout(true);
    };
    const handleCloseCheckout = () => {
        setOpenCheckout(false);
    };

    React.useEffect(() => {
        setState((prev) => ({
            ...prev,
            visitorName: (visitor && visitor.visitorName) || "",
            visitorEmail: (visitor && visitor.visitorEmail) || "",
            visitorPhone: (visitor && visitor.visitorPhone) || "",
            visitPurpose: (visitor && visitor.visitPurpose) || "",
            visitDetails: (visitor && visitor.visitDetails) || "",
            visitDuration: (visitor && visitor.visitDuration) || "",
            visitDate: (visitor && visitor.visitDate) || "",
            visitTime: (visitor && visitor.visitTime) || "",
            visitCode: (visitor && visitor.visitCode) || "",
            visitQRCode: (visitor && visitor.visitQRCode) || "",
            employeeId: (visitor && visitor.employeeId) || "",
            employee: (visitor && visitor.employee) || "",
            status: (visitor && visitor.status) || "",
            documentNo: (visitor && visitor.documentNo) || "",
            checkedInAt: (visitor && visitor.checkedInAt) || "",
            checkedInOn: (visitor && visitor.checkedInOn) || "",
            checkedOutAt: (visitor && visitor.checkedOutAt) || "",
            feedback: (visitor && visitor.feedback) || "",
            securityContact: (visitor && visitor.securityContact) || [],
            welcomeCentre: (visitor && visitor.welcomeCentre) || [],
            frontDeskSupport: (visitor && visitor.frontDeskSupport) || [],
            officeEnvironment: (visitor && visitor.officeEnvironment) || [],
            feedbackDate: (visitor && visitor.feedbackDate) || "",
        }));
    }, [visitor]);

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        await dispatch(submitVisitorAppointment(data));
        await dispatch(getVisitorAppointment(id));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Visitor Appointment Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/visitor/appointments");
        }, 1500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isMine = profile ? profile.employeeId && (profile.employeeId === state.employeeId) : false;
    const isCUE = profile ? profile.division && (profile.division.code === "CUE") : false;

    return (
        <div>
            <>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <CheckinVisitModal {...{ openCheckin, handleCloseCheckin, id }} />
                <CheckoutVisitModal {...{ openCheckout, handleCloseCheckout, id }} />

                <Typography variant="overline" style={{ marginBottom: 0 }} className={classes.title}>
                    Visitor Appointment
                </Typography>

                <Grid container justify="space-between" alignItems="center" spacing={3}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => history.goBack()}
                            startIcon={<ArrowBackIosIcon />}
                            className={classes.backBtn}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : (state.status === "checked_in" || state.status === "checked_out" || state.status === "completed")
                                            ? classes.statusGreen
                                            : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Paper variant="outlined" square spacing={3} className={classes.paper} style={{ marginTop: 20 }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                        variant="fullWidth"
                        style={{ marginTop: 0 }}
                    >
                        <Tab
                            label={
                            <Typography variant="overline" className={classes.title4}>
                                APPOINTMENT DETAILS
                            </Typography>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={
                            <Typography variant="overline" className={classes.title4}>
                                QR CODE
                            </Typography>
                            }
                            {...a11yProps(1)}
                        />
                        {state.status === "completed"? 
                        <Tab
                            label={
                            <Typography variant="overline" className={classes.title4}>
                                VISITOR FEEDBACK
                            </Typography>
                            }
                            {...a11yProps(2)}
                        />
                        : null}
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2} className={classes.grid}>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Document No</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        value={(state.documentNo && state.documentNo) || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Invited By</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        value={(state.employee && state.employee) || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visitor Name</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        value={(state.visitorName && state.visitorName) || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visitor Email</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={state.visitorEmail || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visitor Phone</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        value={state.visitorPhone || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Purpose</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        value={(state.visitPurpose && state.visitPurpose) || ""}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Details</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.visitDetails && state.visitDetails) || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Date</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.visitDate && state.visitDate) || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Time</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.visitTime && state.visitTime) || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Duration</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.visitDuration && state.visitDuration) || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Visit Code</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.visitCode && state.visitCode) || ""}
                                        disabled
                                        required
                                    />
                                </Grid>
                                {state.checkedInAt && state.checkedInAt ? 
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Check In Time</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(`${state.checkedInAt && state.checkedInAt} ${state.checkedInOn && state.checkedInOn}`) || ""}
                                        disabled
                                        required
                                    />
                                </Grid> : null}
                                {state.checkedOutAt && state.checkedOutAt ? 
                                <Grid item xs={12} md={4}>
                                    <label style={{ fontWeight: "bold" }}>Check Out Time</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={(state.checkedOutAt && state.checkedOutAt) || ""}
                                        disabled
                                        required
                                    />
                                </Grid> : null}
                            </Grid>
                        </Paper>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Paper className={classes.paper}>
                            <Grid
                                container
                                justify="center"
                                alignItems="flex-start"
                                spacing={3}
                                style={{ paddingLeft: 15 }}
                            >
                                <Grid item xs={12} md={12}>
                                    <img src={state.visitQRCode} style={{ width: 260, height: 260 }} alt={state.visitorName} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </TabPanel>

                    {(state.feedback && state.feedback) && (state.status === "completed") ? 
                    
                    <TabPanel value={value} index={2}>
                        <Paper className={classes.paper0}>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                        How often do you visit RusselSmith? 
                                    </FormLabel>
                                    <RadioGroup row >
                                        <FormControlLabel
                                            value="First visit"
                                            control={<Radio checked={(state.feedback && state.feedback.howOften === "First visit") ? true : false} />}
                                            label="First visit"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="Rarely"
                                            control={<Radio checked={(state.feedback && state.feedback.howOften === "Rarely") ? true : false} />}
                                            label="Rarely"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="Occasionally"
                                            control={<Radio checked={(state.feedback && state.feedback.howOften === "Occasionally") ? true : false} />}
                                            label="Occasionally"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="Frequently"
                                            control={<Radio checked={(state.feedback && state.feedback.howOften === "Frequently") ? true : false} />}
                                            label="Frequently"
                                            disabled
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                        Level of satisfaction
                                    </FormLabel>
                                    <span>Please specify your level of satisfaction on a scale of 1-5. 5 being the highest and 1 being the lowest.</span>

                                    <Grid container spacing={2} className={classes.grid}>
                                        
                                        <Grid item xs={12} md={4}>
                                            <Paper variant="outlined" square className={classes.paper2} >
                                                <Typography variant="overline" className={classes.title3}>
                                                    Security Contact
                                                </Typography>

                                                {state.securityContact && state.securityContact.map((secItem, i) => (
                                                    <div key={i}>
                                                        <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                            {secItem.type}
                                                        </label>
                                                        <RadioGroup row style={{marginBottom: 10}} >
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                                label="1"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                                label="2"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                                label="3"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                                label="4"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                                label="5"
                                                                disabled
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                ))}
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Paper variant="outlined" square className={classes.paper3} >
                                                <Typography variant="overline" className={classes.title3}>
                                                    Welcome Center
                                                </Typography>

                                                {state.welcomeCentre && state.welcomeCentre.map((secItem, i) => (
                                                    <div key={i}>
                                                        <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                            {secItem.type}
                                                        </label>
                                                        <RadioGroup row style={{marginBottom: 10}} >
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                                label="1"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                                label="2"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                                label="3"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                                label="4"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                                label="5"
                                                                disabled
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                ))}
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Paper variant="outlined" square className={classes.paper2} >
                                                <Typography variant="overline" className={classes.title3}>
                                                    Front Desk Support
                                                </Typography>

                                                {state.frontDeskSupport && state.frontDeskSupport.map((secItem, i) => (
                                                    <div key={i}>
                                                        <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                            {secItem.type}
                                                        </label>
                                                        <RadioGroup row style={{marginBottom: 10}}>
                                                            <FormControlLabel
                                                                value="1"
                                                                control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                                label="1"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="2"
                                                                control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                                label="2"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="3"
                                                                control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                                label="3"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="4"
                                                                control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                                label="4"
                                                                disabled
                                                            />
                                                            <FormControlLabel
                                                                value="5"
                                                                control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                                label="5"
                                                                disabled
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                ))}
                                            </Paper>
                                        </Grid>

                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                        Level of satisfaction with the Office Environment
                                    </FormLabel>
                                    <span>Please specify your level of satisfaction on a scale of 1-5. 5 being the highest and 1 being the lowest.</span>

                                    <Grid container spacing={2} className={classes.grid}>

                                        <Grid item xs={12} md={12}>
                                            <Paper variant="outlined" square className={classes.paper3} >
                                                <Grid item xs={12} md={12}>
                                                    {state.officeEnvironment && state.officeEnvironment.map((secItem, i) => (
                                                        <div key={i}>
                                                            <label style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                                {secItem.type}
                                                            </label>
                                                            <RadioGroup row style={{marginBottom: 10}} >
                                                                <FormControlLabel
                                                                    value="1"
                                                                    control={<Radio checked={(secItem.rating === "1") ? true : false} />}
                                                                    label="1"
                                                                    disabled
                                                                />
                                                                <FormControlLabel
                                                                    value="2"
                                                                    control={<Radio checked={(secItem.rating === "2") ? true : false} />}
                                                                    label="2"
                                                                    disabled
                                                                />
                                                                <FormControlLabel
                                                                    value="3"
                                                                    control={<Radio checked={(secItem.rating === "3") ? true : false} />}
                                                                    label="3"
                                                                    disabled
                                                                />
                                                                <FormControlLabel
                                                                    value="4"
                                                                    control={<Radio checked={(secItem.rating === "4") ? true : false} />}
                                                                    label="4"
                                                                    disabled
                                                                />
                                                                <FormControlLabel
                                                                    value="5"
                                                                    control={<Radio checked={(secItem.rating === "5") ? true : false} />}
                                                                    label="5"
                                                                    disabled
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    ))}
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel style={{fontWeight: 'bold', color: "currentcolor", marginTop: 30}}> 
                                        Considering your experience with our company, how likely would you be to recommend our company to others?
                                    </FormLabel>
                                    <span>On a scale of 1-10. 10 being the highest (extremely likely) and 1 being the lowest (very unlikely).</span>

                                    <RadioGroup row>
                                        <FormControlLabel
                                            value="1"
                                            control={<Radio 
                                                checked={(state.feedback && state.feedback.recommend === "1") ? true : false} />}
                                            label="1"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="Rarely"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "2") ? true : false} />}
                                            label="2"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="3"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "3") ? true : false} />}
                                            label="4"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="4"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "4") ? true : false} />}
                                            label="4"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="5"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "5") ? true : false} />}
                                            label="5"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="6"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "6") ? true : false} />}
                                            label="6"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="7"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "7") ? true : false} />}
                                            label="7"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="8"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "8") ? true : false} />}
                                            label="8"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="9"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "9") ? true : false} />}
                                            label="9"
                                            disabled
                                        />
                                        <FormControlLabel
                                            value="10"
                                            control={<Radio checked={(state.feedback && state.feedback.recommend === "10") ? true : false} />}
                                            label="10"
                                            disabled
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} md={12} style={{marginTop: 20}}>
                                <label style={{ fontWeight: "bold" }}>Any complaint, area of improvement or commendation for the company</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name="comment"
                                    value={(state.feedback && state.feedback.comment) || ""}
                                    required
                                    disabled
                                />
                            </Grid>
                        </Paper>
                    </TabPanel>
                    : null }

                    <Paper style={{ marginTop: 15 }}>
                        <Grid
                            container
                            justify="center"
                            alignItems="flex-start"
                            spacing={3}
                        >
                            {isMine && state.status === "draft" ?
                                <Grid container justify="center">
                                    <Grid item>
                                        <ButtonGroup>
                                            <Button
                                                type="submit"
                                                className={classes.saveBtn2}
                                                variant="contained"
                                                onClick={handleSubmitHandler}
                                                disabled={loading}
                                            >
                                                Submit
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            : null}

                            {isCUE && (state.status === "submitted" || state.status === "checked_in") ?
                                <ButtonGroup>
                                    <Button
                                        color="primary"
                                        onClick={() => handleOpenCheckin(id)}
                                        variant="contained"
                                        className={classes.saveBtn2}
                                        disabled={state.status !== "submitted"}
                                    >
                                        Check In
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={() => handleOpenCheckout(id)}
                                        variant="contained"
                                        className={classes.saveBtn}
                                        disabled={state.status !== "checked_in"}
                                    >
                                        Check Out
                                    </Button>
                                </ButtonGroup>
                                : null}
                        </Grid>
                    </Paper>
                </Paper>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        </div>
    );
};

export default ViewVisitor;
