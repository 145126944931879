import React, { useState } from "react";
import {
  TextField,
  Paper,
  makeStyles,
  Typography,
  Grid,
  Divider,
  Button,
  Backdrop,
  InputAdornment,
  ButtonGroup,
  Icon,
  useTheme,
  darken,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect } from "react-redux";
import SearchComponent from "../../SearchComponent";
import { useHistory, useParams } from "react-router-dom";
import { getAnnualBudgetById } from "../../../actions/annualAction";
import { formatAmount, formatCurrency } from "../../../utils";
import clsx from "clsx";

import { getMonthlyBudgetById } from "../../../actions/monthlyBudgetAction";
import MonthlyConfirmModal from "./Approvals/MonthlyConfirmModal";
import MonthlyApproveBudgetModal from "./Approvals/MonthlyApproveBudgetModal";
import RejectMonthlyBudgetModal from "./Rejections/RejectMonthlyBudgetModal";
import DeclineMonthlyBudget from "./Rejections/DeclineMonthlyBudget";
import FinalMonthlyApproveModal from "./Approvals/FinalMonthlyApproveModal";
import RequestMonthlyClarification from "./Approvals/RequestMonthlyClarification";
import RequestMonthlyModification from "./Approvals/RequestMonthlyModification";
import MonthlyBudgetTable from "./MonthlyBudgetTable";
import MonthlyBudgetReviewTable from "./MonthlyBudgetReviewTable";
import SubmitMonthlyClarifyModal from "./modals/SubmitMonthlyClarifyModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  btn: {
    // marginBottom: 15,
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  table: {
    minWidth: 500,
  },
  divider: {
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  grid: {
    marginTop: 15,
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  textFieldSmall: {
    width: 100,
  },
  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  actions: {
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  btn2: {
    marginLeft: 10,

    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  btnGreen: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  embed: {
    width: "100%",
    height: 100,
    objectFit: "contain",
    objectPosition: "center",
    marginRight: 5,
    cursor: "pointer",
    transition: "all 300ms ease-in-out",
    zIndex: 1,
    "&:hover": {
      transform: "scale(2.5)",
      zIndex: 1000000000000,
    },
  },
  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    fontWeight: "bold",
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  centered: {
    textAlign: "center",
  },
  paperBlack: {
    color: "#f5f5f5",
    // background: "#3c3737",
    background: theme.palette.grey.A400,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
    textAlign: "center",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
  },
  info2: {
    marginBottom: 20,
    fontWeight: 500,
  },
}));

const INIT_STATE = {
  employeeName: "",
  division: "",
  jobTitle: "",
  year: "",
  month: "",
  status: "",
  documentNo: "",
  type: "",
  clarification: "",
};

const ViewMonthlyBudget = ({
  getAnnualBudgetById,
  annualBudget,
  profile,
  getMonthlyBudgetById,
  monthlyBudget,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [approveAnchorEl, setApproveAnchorEl] = React.useState(null);
  const [openClarify, setOpenClarify] = useState(false);

  const [openReject, setOpenReject] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openFinalApprove, setOpenFinalApprove] = useState(false);
  const [openRequestModification, setOpenRequestModification] = useState(false);
  const [openRequestClarification, setOpenRequestClarification] = useState(
    false
  );

  const [openDecline, setOpenDecline] = useState(false);
  // const [openReview, setOpenReview] = useState(false);

  const [state, setState] = useState(INIT_STATE);
  const [total, setTotal] = React.useState(0);

  const [budgetLines, setBudgetLines] = useState([]);

  const history = useHistory();
  const { id } = useParams();

  const monthlyBudgetCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getMonthlyBudgetById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getMonthlyBudgetById]);

  React.useEffect(() => {
    monthlyBudgetCallback();
  }, [monthlyBudgetCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: monthlyBudget && monthlyBudget.employee,
      division:
        monthlyBudget && monthlyBudget.division && monthlyBudget.division.name,
      jobTitle: monthlyBudget && monthlyBudget.jobTitle.name,
      year: monthlyBudget && monthlyBudget.year,
      month: monthlyBudget && monthlyBudget.month,
      status: monthlyBudget && monthlyBudget.status,
      documentNo: monthlyBudget && monthlyBudget.documentNo,
      type: monthlyBudget && monthlyBudget.type,
      clarification: monthlyBudget && monthlyBudget.clarification,

      submittedBy:
        (monthlyBudget && monthlyBudget.createdBy && monthlyBudget.createdBy.userName) || "",
      submittedAt: (monthlyBudget && monthlyBudget.createdAt) || "",

      approvedBy: (monthlyBudget && monthlyBudget.approvedBy && monthlyBudget.approvedBy.userName) || "",
      approvedAt: (monthlyBudget && monthlyBudget.approvedAt) || "",
      approverComment: (monthlyBudget && monthlyBudget.approverComment) || "",

      acceptedBy: (monthlyBudget && monthlyBudget.acceptedBy && monthlyBudget.acceptedBy.userName) || "",
      acceptedAt: (monthlyBudget && monthlyBudget.acceptedAt) || "",
      acceptComment: (monthlyBudget && monthlyBudget.financeComment) || "",

      rejectedBy: (monthlyBudget && monthlyBudget.rejectedBy && monthlyBudget.rejectedBy.userName) || "",
      rejectedAt: (monthlyBudget && monthlyBudget.rejectedAt) || "",
      rejectComment: (monthlyBudget && monthlyBudget.rejectComment) || "",

      declinedBy: (monthlyBudget && monthlyBudget.declinedBy && monthlyBudget.declinedBy.userName) || "",
      declinedAt: (monthlyBudget && monthlyBudget.declinedAt) || "",
      declineComment: (monthlyBudget && monthlyBudget.declineComment) || "",

      completedBy: (monthlyBudget && monthlyBudget.completedBy && monthlyBudget.completedBy.userName) || "",
      completedAt: (monthlyBudget && monthlyBudget.completedAt) || "",
      CEOComment: (monthlyBudget && monthlyBudget.CEOComment) || "",

      reviewedBy: (monthlyBudget && monthlyBudget.reviewedBy && monthlyBudget.reviewedBy.userName) || "",
      reviewedAt: (monthlyBudget && monthlyBudget.reviewedAt) || "",
      reviewerComment: (monthlyBudget && monthlyBudget.reviewerComment) || "",

    }));
  }, [monthlyBudget]);

  React.useEffect(() => {
    let total = (monthlyBudget && monthlyBudget.total) || 0;
    total = formatAmount(total);
    setTotal(total);
  }, [monthlyBudget]);

  React.useEffect(() => {
    setBudgetLines(monthlyBudget && monthlyBudget.budgetLines);
  }, [monthlyBudget]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const filteredRows = () =>
    budgetLines === undefined
      ? []
      : budgetLines.filter((row) => {
        if (search !== "") {
          return (
            row.amount
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.head.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            row.item.toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
        } else {
          return row;
        }
      });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Array(10).length - page * rowsPerPage);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenDecline = () => {
    setOpenDecline(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };
  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenApproveMenu = (event) => {
    setApproveAnchorEl(event.currentTarget);
  };

  const handleCloseApproveMenu = () => {
    setApproveAnchorEl(null);
  };

  const handleOpenFinalApprove = () => {
    setOpenFinalApprove(true);
    handleCloseApproveMenu();
  };

  const handleCloseFinalApprove = () => {
    setOpenFinalApprove(false);
  };

  const handleOpenRequestModification = () => {
    setOpenRequestModification(true);
    handleCloseApproveMenu();
  };

  const handleCloseRequestModification = () => {
    setOpenRequestModification(false);
  };

  const handleOpenRequestClarification = () => {
    setOpenRequestClarification(true);
    handleCloseApproveMenu();
  };

  const handleCloseRequestClarification = () => {
    setOpenRequestClarification(false);
  };

  // const handleOpenReview = () => {
  //   setOpenReview(true);
  // };
  // const handleCloseReview = () => {
  //   setOpenReview(false);
  // };

  const handleOpenClarify = () => {
    setOpenClarify(true);
  };

  const handleCloseClarify = () => {
    setOpenClarify(false);
  };

  let isFinance = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "reviewer" && grp.module === "budget"
    )
    : false;

  let isHOD = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "head" && grp.module === "budget"
    )
    : false;

  let isCEO = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "ceo" && grp.module === "budget"
    )
    : false;

  return (
    <div>
      <SubmitMonthlyClarifyModal
        {...{
          openClarify,
          handleCloseClarify,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <MonthlyConfirmModal
        {...{
          openConfirm,
          handleCloseConfirm,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <MonthlyApproveBudgetModal
        {...{
          openApprove,
          handleCloseApprove,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <RejectMonthlyBudgetModal
        {...{
          openReject,
          handleCloseReject,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <DeclineMonthlyBudget
        {...{
          openDecline,
          handleCloseDecline,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <FinalMonthlyApproveModal
        {...{
          openFinalApprove,
          handleCloseFinalApprove,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <RequestMonthlyClarification
        {...{
          openRequestClarification,
          handleCloseRequestClarification,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />
      <RequestMonthlyModification
        {...{
          openRequestModification,
          handleCloseRequestModification,
          id: monthlyBudget && monthlyBudget._id,
        }}
      />

      <Grid container alignItems="center" spacing={6}>
        <Grid item>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          {/* {profile &&
          profile.jobTitle !== "Chief Executive Officer" &&
          state.status !== "reviewed"
            ? openBackdrop
              ? null
              : isAdminAndFinance &&
                state.status === "approved" && (
                  <Button
                    variant="contained"
                    className={classes.actions}
                    onClick={handleOpenReview}
                  >
                    Review
                  </Button>
                )
            : null} */}

          {isCEO &&
            (state.status === "accepted" ||
              state.status === "modified" ||
              state.status === "clarified") ? (
            <>
              <Button
                variant="contained"
                className={classes.btnGreen}
                // size="large"
                onClick={handleOpenApproveMenu}
                endIcon={
                  approveAnchorEl ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                Actions
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={approveAnchorEl}
                keepMounted
                open={Boolean(approveAnchorEl)}
                onClose={handleCloseApproveMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={handleOpenFinalApprove}>Approve</MenuItem>
                <MenuItem onClick={handleOpenRequestModification}>
                  Request Modification
                </MenuItem>
                <MenuItem onClick={handleOpenRequestClarification}>
                  Request Clarification
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            View{" "}
            {state.type === undefined
              ? `Loading...`
              : state.type === "monthly"
                ? "Monthly"
                : "Supplementary"}{" "}
            Budget
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            label="Document Number"
            value={state.documentNo || ""}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted" ||
                  state.status === "tomodify" ||
                  state.status === "modified" ||
                  state.status === "clarified" ||
                  state.status === "toclarify"
                  ? classes.statusOrange
                  : state.status === "approved" || state.status === "reviewed"
                    ? classes.statusGreen
                    : state.status === "completed" || state.status === "accepted"
                      ? classes.statusDarkGreen
                      : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {openBackdrop
              ? "Loading..."
              : state.status && state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid item xs={12} md={6}>
          <TextField
            label="Employee Name"
            fullWidth
            variant="outlined"
            className={classes.textField}
            name="employeeName"
            value={openBackdrop ? "Loading..." : state.employeeName || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Division/Unit"
            fullWidth
            variant="outlined"
            name="division"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.division || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Job Title"
            fullWidth
            variant="outlined"
            name="jobTitle"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.jobTitle || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Month"
            fullWidth
            variant="outlined"
            name="month"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.month || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Year"
            fullWidth
            variant="outlined"
            name="year"
            className={classes.textField}
            value={openBackdrop ? "Loading..." : state.year || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {state.clarification && (
            <TextField
              label="Clarification"
              fullWidth
              variant="outlined"
              name="clarification"
              multiline
              rows={2}
              className={classes.textField}
              value={state.clarification || ""}
              disabled
            />
          )}
        </Grid>
      </Grid>

      <Divider className={classes.divider} light />

      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      {isFinance ? (
        <MonthlyBudgetReviewTable
          {...{
            filteredRows,
            classes,
            page,
            rowsPerPage,
            state,
            emptyRows,
            handleChangePage,
            handleChangeRowsPerPage,
            theme,
            profile,
            formatCurrency,
            monthlyBudget: monthlyBudget,

            id,
          }}
        />
      ) : (
        <MonthlyBudgetTable
          {...{
            filteredRows,
            classes,
            page,
            rowsPerPage,

            emptyRows,
            handleChangePage,
            handleChangeRowsPerPage,
            theme,
            formatCurrency,
          }}
        />
      )}

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Total"
            variant="outlined"
            disabled
            className={classes.textField2}
            value={total}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        style={{ marginBottom: 5 }}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title2}>
            Audit Trail
          </Typography>
        </Grid>
        <Grid item>
          <Icon className="fas fa-clipboard-list" color="disabled" />
        </Grid>
      </Grid>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          {(state.submittedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Created By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.submittedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.submittedAt}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.rejectComment) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Rejected By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.approvedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Approved By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approverComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.reviewedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Reviewed By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewerComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.declinedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Declined By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declineComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {state.acceptedBy && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Accepted By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.acceptComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {(state.completedBy) && (
            <>
              <Grid item xs={12} md={12}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Completed By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.completedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.completedAt}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.CEOComment}</span>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      {doCheck(
        monthlyBudget &&
        monthlyBudget.division &&
        monthlyBudget.division.manager,
        profile && profile._id
      ) &&
        state.status === "submitted" && (
          <>
            <Divider light className={classes.divider} />

            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              className={classes.grid}
            >
              <ButtonGroup>
                <Button
                  color="primary"
                  variant="contained"
                  // style={{ marginRight: 3 }}
                  onClick={handleOpenConfirm}
                  className={classes.saveBtn}
                >
                  Review
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  // style={{ marginLeft: 3 }}
                  onClick={handleOpenDecline}
                  className={classes.saveBtn}
                >
                  Decline
                </Button>
              </ButtonGroup>
            </Grid>
          </>
        )}

      {doCheck(
        monthlyBudget &&
        monthlyBudget.division &&
        monthlyBudget.division.head &&
        monthlyBudget.division.head._id,
        profile && profile._id
      ) &&
        state.status === "reviewed" && (
          <>
            <Divider light className={classes.divider} />

            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={3}
              className={classes.grid}
            >
              <ButtonGroup>
                <Button
                  color="primary"
                  // style={{ marginRight: 3 }}
                  onClick={handleOpenApprove}
                  className={classes.saveBtn}
                  variant="contained"
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  // style={{ marginLeft: 3 }}
                  onClick={handleOpenReject}
                  className={classes.saveBtn}
                >
                  Reject
                </Button>
              </ButtonGroup>
            </Grid>
          </>
        )}

      {isHOD && state.status === "toclarify" ? (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Button
              onClick={handleOpenClarify}
              variant="contained"
              color="primary"
              className={classes.saveBtn}
            >
              Clarify
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

const doCheck = (check, validate) => {
  if (check !== undefined || validate !== undefined) {
    return check === validate;
  }
};

const mapStateToProps = (state) => ({
  annualBudget: state.annualBudget.annualBudget.data,
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getAnnualBudgetById,
  getMonthlyBudgetById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewMonthlyBudget);
