import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { createAssetAcquisition, getAssetAcquisitions } from "../../../../actions/assetAcquisitionActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    assetName:"",
    contact:"",
    description: "",
    requestDate: "",
    costs: [
        {
            cost: "Acquisition",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
        {
            cost: "Operation/Maintenance",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
        {
            cost: "Other Costs",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
    ],
    earnings: [
        {
            earning: "Sales",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
        {
            earning: "Savings",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
        {
            earning: "Other Income",
            average: "",
            usefullife: "",
            years: "",
            total: "",
        },
    ],
    totalCost: "",
    totalCostAverage: "",
    totalEarning: "",
    totalEarningAverage: "",
};

const CreateAssetAcquisitionModal = ({ openCreate, handleCloseCreate, createAssetAcquisition }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [requestDate, setRequestDate] = useState(new Date());
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    
    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.assetAcquisition);
    
    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            requestDate: moment(requestDate).toDate(),
        }));
    }, [profile, requestDate, year, month]);

    const handleChangeDate = (date) => {
        setRequestDate(date);
    };

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // cost
    const handleCostChange = (i) => (e) => {
        e.persist();
        const newCosts = state.costs.map((item, index) => {
            if (i !== index) {
                return item;
            }
            let total = null;
            if(e.target.name === "years"){
                total = e.target.value * item.average;
            }else if(e.target.name === "average" ){
                total = e.target.value * item.years;
            }else {
                total = item.total;
            }
            return {
                ...item,
                total,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            costs: newCosts,
        }));
    };

    // earning
    const handleEarningChange = (i) => (e) => {
        e.persist();
        const newEarnings = state.earnings.map((item, index) => {
            if (i !== index) {
                return item;
            }
            let average = null;
            if(e.target.name === "total"){
                average = e.target.value / item.years;
            }else if(e.target.name === "years" ){
                average = item.total / e.target.value;
            }else {
                average = item.average;
            }
            return {
                ...item,
                average,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            earnings: newEarnings,
        }));
    };

    useEffect(() => {
        if (state.costs && state.costs.length > 0) {
            let total1 = state.costs.reduce((acc, cur) => { return acc + +cur.total; }, 0);
            let total2 = state.costs.reduce((acc, cur) => { return acc + +cur.average; }, 0);
            setState((prev) => ({
                ...prev,
                totalCost: total1,
                totalCostAverage: total2,
            }));
        }
    }, [state.costs]);

    useEffect(() => {
        if (state.earnings && state.earnings.length > 0) {
            let total1 = state.earnings.reduce((acc, cur) => { return acc + +cur.total; }, 0);
            let total2 = state.earnings.reduce((acc, cur) => { return acc + +cur.average; }, 0);
            setState((prev) => ({
                ...prev,
                totalEarning: total1,
                totalEarningAverage: total2,
            }));
        }
    }, [state.earnings]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createAssetAcquisition(data));
        });
        
        if (res) {
            dispatch(getAssetAcquisitions());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Asset Acquisition Request Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/asset/acquisition`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.month || 
        !state.year || 
        !state.requestDate ||
        !state.assetName ||
        !state.contact || 
        !state.description || 
        !state.otherBenefits || 
        !state.totalCost ||
        !state.totalCostAverage || 
        !state.totalEarning || 
        !state.totalEarningAverage ||  
        (state.costs.length === 0) || 
        (state.earnings.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Capital Asset Acquisition Request
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Request Date"
                                            fullWidth
                                            required
                                            inputVariant="outlined"
                                            value={requestDate}
                                            onChange={handleChangeDate}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Asset Name"
                                            name="assetName"
                                            value={state.assetName || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Contact"
                                            name="contact"
                                            value={state.contact || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Description"
                                            name="description"
                                            value={state.description || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            required
                                            label="Other Material Benefits"
                                            name="otherBenefits"
                                            value={state.otherBenefits || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                {(state.costs && state.costs.length > 0) ? <>

                                <Typography variant="overline" className={classes.title2}>
                                    Costs
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {state.costs ? state.costs.map((sCost, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            style={{paddingBottom:0}}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Cost"
                                                    name="cost"
                                                    value={sCost.cost || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Useful Life"
                                                    name="usefullife"
                                                    value={sCost.usefullife || ""}
                                                    onChange={handleCostChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Years"
                                                    name="years"
                                                    value={sCost.years || ""}
                                                    type="number"
                                                    onChange={handleCostChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Average Naira/Year"
                                                    name="average"
                                                    type="number"
                                                    value={sCost.average || ""}
                                                    onChange={handleCostChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Total"
                                                    name="total"
                                                    value={sCost.total || ""}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    )) : null }
                                </Paper> 
                                </> : null}

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={8} />
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Total Average"
                                            name="totalCostAverage"
                                            value={state.totalCostAverage || '0'}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Total Cost"
                                            name="totalCost"
                                            value={state.totalCost || '0'}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                {(state.earnings && state.earnings.length > 0) ? <>

                                <Typography variant="overline" className={classes.title2}>
                                    Projected Earnings/Income
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {state.earnings ? state.earnings.map((sEarning, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            style={{paddingBottom:0}}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Cost"
                                                    name="cost"
                                                    value={sEarning.earning || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Years"
                                                    name="years"
                                                    value={sEarning.years || ""}
                                                    type="number"
                                                    onChange={handleEarningChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Useful Life"
                                                    name="usefullife"
                                                    value={sEarning.usefullife || ""}
                                                    onChange={handleEarningChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Average Naira/Year"
                                                    name="average"
                                                    value={sEarning.average || ""}
                                                    disabled
                                                    />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Total"
                                                    name="total"
                                                    value={sEarning.total || ""}
                                                    type="number"
                                                    onChange={handleEarningChange(i)}
                                                />
                                            </Grid>
                                        </Grid>
                                    )) : null }
                                </Paper> 
                                </> : null}

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={8} />
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Total Earning Average"
                                            name="totalEarningAverage"
                                            value={state.totalEarningAverage || '0'}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            label="Total Earning"
                                            name="totalEarning"
                                            value={state.totalEarning || '0'}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createAssetAcquisition, })(CreateAssetAcquisitionModal);
