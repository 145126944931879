import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getCustomers } from "../../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../../actions/contactPersonActions";
import { getAllPersonnelPriceList } from "../../../../actions/masterListActions/personnelActions";
import { getAllEquipmentPriceList } from "../../../../actions/masterListActions/equipmentAction";
import { getAllMaterialPriceList } from "../../../../actions/masterListActions/materialActions";
import { getAllMobilizationPriceList } from "../../../../actions/masterListActions/mobilizationActions";
import { getQuotation, getQuotations, updateQuotation, submitQuotation } from "../../../../actions/quotationActions";
import { getCurrencies } from "../../../../actions/currencyActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: 500,
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
}));

const INIT_STATE = {
    title: "",
    employee: "",
    division: "",
    customer: null,
    quotationDate: "",
    contactPerson: "",
    contactPersonName: "",
    contactPersonEmail: "",
    contactPersonJob: "",
    contactPersonPhone: "",
    personnel: [],
    equipment: [],
    material: [],
    consumable: [],
    mob_demob: [],
    others: [],
    currency: "",
    comment: "",
    noteToPricing: "",
    sendComment: "",
};

const EditQuotation = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { customers } = useSelector((state) => state.customer);
    const { currencies } = useSelector((state) => state.currency);
    const { contactPersons } = useSelector((state) => state.contactPerson);
    const { profile } = useSelector((state) => state.user);
    const { personnels } = useSelector((state) => state.personnel);
    const { equipments } = useSelector((state) => state.equipment);
    const { materials } = useSelector((state) => state.material);
    const { error, loading, quotation } = useSelector((state) => state.quotation);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getCurrencies());
        dispatch(getAllEquipmentPriceList());
        dispatch(getAllPersonnelPriceList());
        dispatch(getAllMaterialPriceList());
        dispatch(getAllMobilizationPriceList());
        dispatch(getCustomers());
        dispatch(getQuotation(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (state.customer !== null) {
            dispatch(getContactPersonByCustomer(state.customer || ""));
        }
    }, [dispatch, state.customer]);

    useEffect(() => {
        if (state.contactPerson) {
            contactPersons &&
                contactPersons.map(
                    (item) =>
                        item.name === state.contactPerson.name &&
                        setState((prev) => ({
                            ...prev,
                            contactPersonName: item && item.name,
                            contactPersonEmail: item && item.email,
                            contactPersonJob: item && item.designation,
                            contactPersonPhone: item && item.phone,
                        }))
                );
        }
    }, [state.contactPerson, contactPersons]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            title: (quotation && quotation.title) || "",
            employee: (quotation && quotation.employee) || "",
            division: (quotation && quotation.division) || "",
            customer: (quotation && quotation.customer && quotation.customer._id) || "",
            contactPerson: (quotation && quotation.contactPerson) || "",
            contactPersonEmail: (quotation && quotation.contactPersonEmail) || "",
            contactPersonJob: (quotation && quotation.contactPersonJob) || "",
            contactPersonPhone: (quotation && quotation.contactPersonPhone) || "",

            currency: (quotation && quotation.currency),
            comment: (quotation && quotation.comment) || "",
            noteToPricing: (quotation && quotation.noteToPricing) || "",

            personnel: (quotation && quotation.personnel) || [],
            equipment: (quotation && quotation.equipment) || [],
            material: (quotation && quotation.material) || [],
            consumable: (quotation && quotation.consumable) || [],
            mob_demob: (quotation && quotation.mob_demob) || [],
            others: (quotation && quotation.others) || [],

            status: (quotation && quotation.status) || "",
            documentNo: (quotation && quotation.documentNo) || "",
            sendComment: (quotation && quotation.sendComment) || "",
            quotationDate: (quotation && moment(quotation.quotationDate).format("l")) || "",
        }));
    }, [quotation]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangePersonnelCharges = (i) => (e) => {
        e.persist();
        const newPersonnelCharges = state.personnel.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            personnel: newPersonnelCharges,
        }));
    };

    const handleChangeEquipmentCharges = (i) => (e) => {
        e.persist();
        const newEquipmentCharges = state.equipment.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            equipment: newEquipmentCharges,
        }));
    };

    const handleChangeMaterialCharges = (i) => (e) => {
        e.persist();
        const newMaterialCharges = state.material.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            material: newMaterialCharges,
        }));
    };

    const handleChangeConsumableCharges = (i) => (e) => {
        e.persist();
        const newConsumableCharges = state.consumable.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            consumable: newConsumableCharges,
        }));
    };

    const handleChangeMobDemobCharges = (i) => (e) => {
        e.persist();
        const newMobDemobCharges = state.mob_demob.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            mob_demob: newMobDemobCharges,
        }));
    };

    const handleChangeOtherCharges = (i) => (e) => {
        e.persist();
        const newOtherCharges = state.others.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            others: newOtherCharges,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            division: profile && profile.division,
            quotationDate: moment(state.quotationDate).toDate(),
            currency: state.currency,
            customer: state.customer,
            personnel: state.personnel,
            equipment: state.equipment,
            material: state.material,
            consumable: state.consumable,
            mob_demob: state.mob_demob,
            others: state.others,
            comment: state.comment,
            sendComment: state.sendComment,
            noteToPricing: state.noteToPricing,
            path: `sales/quotation/view`,
            id,
        };

        await dispatch(submitQuotation(data));
        await dispatch(getQuotations);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Quotation Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/sales/quotation");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            division: profile && profile.division,
            id
        }
        await dispatch(updateQuotation(data));
        await dispatch(getQuotation(id));
        setTimeout(async () => {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Quotation Updated Successfully.",
                success: true,
            }));
        }, 1500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCommercials = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => ((grp.name === "user" && grp.module === "sales"))
        )
        : false;

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected") &&
            (state.status !== "closed")
        )
        : false;

    const handleAddPersonnelCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.concat([data]),
        }));
    };

    const handleRemovePersonnelCharges = (i) => {
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddEquipmentCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.concat([data]),
        }));
    };

    const handleRemoveEquipmentCharges = (i) => {
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddMaterialCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            duration: "",
            unitRate: "",
            totalRate: ""
        };
        setState((prev) => ({
            ...prev,
            material: prev.material.concat([data]),
        }));
    };

    const handleRemoveMaterialCharges = (i) => {
        setState((prev) => ({
            ...prev,
            material: prev.material.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddConsumableCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            unitRate: null,
            totalRate: null
        };
        setState((prev) => ({
            ...prev,
            consumable: prev.consumable.concat([data]),
        }));
    };

    const handleRemoveConsumableCharges = (i) => {
        setState((prev) => ({
            ...prev,
            consumable: prev.consumable.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddMobDemobCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            kind: "",
            totalAmount: null
        };
        setState((prev) => ({
            ...prev,
            mob_demob: prev.mob_demob.concat([data]),
        }));
    };

    const handleRemoveMobDemobCharges = (i) => {
        setState((prev) => ({
            ...prev,
            mob_demob: prev.mob_demob.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddOtherCharges = () => {
        const data = {
            description: "",
            quantity: 1,
            currency: "",
            kind: "",
            totalAmount: null
        };
        setState((prev) => ({
            ...prev,
            others: prev.others.concat([data]),
        }));
    };

    const handleRemoveOtherCharges = (i) => {
        setState((prev) => ({
            ...prev,
            others: prev.others.filter(
                (_, index) => index !== i
            ),
        }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <Typography variant="overline" className={classes.title}>
                Edit Quotation
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/sales/quotation")}
                    >
                        Back
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        className={classes.backBtn}
                        onClick={() => history.push(`/sales/quotation/${id}`)}
                    >
                        Preview
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : state.status === "reviewed"
                                        ? classes.statusGreen
                                        : (state.status === "approved" || state.status === "sent")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmitHandler}>
                <Typography variant="overline" className={classes.title2}>
                    Basic Information
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={2}
                        className={classes.grid}
                    >
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Prepared By"
                                name="employee"
                                value={state.employee || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    inputProps={{
                                        id: "currency",
                                        name: "currency",
                                    }}
                                    value={state.currency || ""}
                                    onChange={handleChange}
                                >
                                    <MenuItem disabled>Select Currency</MenuItem>
                                    {currencies.map((currency) => (
                                        <MenuItem key={currency._id} value={currency.code}>
                                            {currency.code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Division/Unit"
                                name="division"
                                value={state.division.name || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Date Created"
                                name="quotationDate"
                                value={state.quotationDate || ""}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Typography variant="overline" className={classes.title2}>
                    Quotation &amp; Customer Information
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        spacing={1}
                        className={classes.grid}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Quotation Title"
                                name="title"
                                value={state.title || ""}
                                onChange={handleChange}
                                disabled={state.status !== "draft"}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth required variant="outlined" >
                                <InputLabel id="customer">
                                    Customer Name
                                </InputLabel>
                                <Select
                                    labelId="Customer Name"
                                    name="customer"
                                    value={state.customer || ""}
                                    onChange={handleChange}

                                >
                                    <MenuItem disabled value="">
                                        Select
                                    </MenuItem>
                                    {customers &&
                                        customers.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth required variant="outlined" >
                                <InputLabel id="contactPerson">
                                    Contact Person
                                </InputLabel>
                                <Select
                                    labelId="contactPerson"
                                    name="contactPerson"
                                    value={state.contactPerson.id || ""}
                                    onChange={handleChange}

                                >
                                    <MenuItem disabled value="">
                                        Select
                                    </MenuItem>
                                    {contactPersons &&
                                        contactPersons.map((item) => (
                                            <MenuItem key={item._id} value={item._id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                style={{ marginTop: 10 }}
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                label="Contact Person Email"
                                name="contactPersonEmail"
                                value={state.contactPersonEmail || ""}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                style={{ marginTop: 10 }}
                                variant="outlined"
                                fullWidth
                                required
                                label="Job Title"
                                name="contactPersonJob"
                                value={state.contactPersonJob || ""}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                style={{ marginTop: 10 }}
                                variant="outlined"
                                fullWidth
                                required
                                label="Phone Number"
                                type="tel"
                                name="contactPersonPhone"
                                value={state.contactPersonPhone || ""}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Paper>

                {isCommercials ? (
                    <>
                        <Typography variant="overline" className={classes.title2}>
                            Personnel Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.personnel &&
                                state.personnel.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="description">
                                                    Personnel Description
                                                </InputLabel>
                                                <Select
                                                    labelId="description"
                                                    name="description"
                                                    value={item.description || ""}
                                                    onChange={handleChangePersonnelCharges(i)}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {personnels &&
                                                        personnels.map((item) => (
                                                            <MenuItem key={item._id} value={item.description}>
                                                                {item.description}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ""}
                                                onChange={handleChangePersonnelCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={item.currency || ""}
                                                    onChange={handleChangePersonnelCharges(i)}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Duration"
                                                name="duration"
                                                value={item.duration || ""}
                                                onChange={handleChangePersonnelCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Daily Rate"
                                                name="unitRate"
                                                value={item.unitRate || ""}
                                                onChange={handleChangePersonnelCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Total Rate"
                                                name="totalRate"
                                                value={item.totalRate || ""}
                                                onChange={handleChangePersonnelCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemovePersonnelCharges(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddPersonnelCharges(state.personnel.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Equipment Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.equipment &&
                                state.equipment.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="description">
                                                    Equipment Description
                                                </InputLabel>
                                                <Select
                                                    labelId="description"
                                                    name="description"
                                                    value={item.description || ""}
                                                    onChange={handleChangeEquipmentCharges(i)}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {equipments &&
                                                        equipments.map((item) => (
                                                            <MenuItem key={item._id} value={item.description}>
                                                                {item.description}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ""}
                                                onChange={handleChangeEquipmentCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={item.currency || ""}
                                                    onChange={handleChangeEquipmentCharges(i)}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Duration"
                                                name="duration"
                                                value={item.duration || ""}
                                                onChange={handleChangeEquipmentCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Daily Rate"
                                                name="unitRate"
                                                value={item.unitRate || ""}
                                                onChange={handleChangeEquipmentCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Total Rate"
                                                name="totalRate"
                                                value={item.totalRate || ""}
                                                onChange={handleChangeEquipmentCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveEquipmentCharges(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddEquipmentCharges(state.equipment.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Material Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.material &&
                                state.material.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="description">
                                                    Material Description
                                                </InputLabel>
                                                <Select
                                                    labelId="description"
                                                    name="description"
                                                    value={item.description || ""}
                                                    onChange={handleChangeMaterialCharges(i)}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {materials &&
                                                        materials.map((item) => (
                                                            <MenuItem key={item._id} value={item.description}>
                                                                {item.description}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ""}
                                                onChange={handleChangeMaterialCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={item.currency || ""}
                                                    onChange={handleChangeMaterialCharges(i)}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Duration"
                                                name="duration"
                                                value={item.duration || ""}
                                                onChange={handleChangeMaterialCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Daily Rate"
                                                name="unitRate"
                                                value={item.unitRate || ""}
                                                onChange={handleChangeMaterialCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Total Rate"
                                                name="totalRate"
                                                value={item.totalRate || ""}
                                                onChange={handleChangeMaterialCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveMaterialCharges(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddMaterialCharges(state.material.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Consumable Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.consumable &&
                                state.consumable.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Description"
                                                type="text"
                                                name="description"
                                                value={item.description || ""}
                                                onChange={handleChangeConsumableCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ""}
                                                onChange={handleChangeConsumableCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Currency</InputLabel>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={item.currency || ""}
                                                    onChange={handleChangeConsumableCharges(i)}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Unit/Daily Rate"
                                                name="unitRate"
                                                value={item.unitRate || ""}
                                                onChange={handleChangeConsumableCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Total Rate"
                                                name="totalRate"
                                                value={item.totalRate || ""}
                                                onChange={handleChangeConsumableCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveConsumableCharges(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddConsumableCharges(state.consumable.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Mob / Demob Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.mob_demob && state.mob_demob.map((item, i) => (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                    key={i}
                                >
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Description"
                                            type="text"
                                            name="description"
                                            value={item.description || ""}
                                            onChange={handleChangeMobDemobCharges(i)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Quantity"
                                            type="number"
                                            name="quantity"
                                            value={item.quantity || ""}
                                            onChange={handleChangeMobDemobCharges(i)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Currency</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "currency",
                                                    name: "currency",
                                                }}
                                                value={item.currency || ""}
                                                onChange={handleChangeMobDemobCharges(i)}
                                            >
                                                <MenuItem disabled>Select Currency</MenuItem>
                                                {currencies.map((currency) => (
                                                    <MenuItem key={currency._id} value={currency.code}>
                                                        {currency.code}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Kind"
                                            name="kind"
                                            value={item.kind || ""}
                                            onChange={handleChangeMobDemobCharges(i)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            type="number"
                                            label="Total Amount"
                                            name="totalAmount"
                                            value={item.totalAmount || ""}
                                            onChange={handleChangeMobDemobCharges(i)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="secondary"
                                            onClick={() => handleRemoveMobDemobCharges(i)}
                                        >
                                            <CloseIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddMobDemobCharges(state.mob_demob.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>

                        <Typography variant="overline" className={classes.title2}>
                            Other Charges
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.others &&
                                state.others.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Description"
                                                type="text"
                                                name="description"
                                                value={item.description || ""}
                                                onChange={handleChangeOtherCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity || ""}
                                                onChange={handleChangeOtherCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <InputLabel>Currency</InputLabel>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={item.currency || ""}
                                                    onChange={handleChangeOtherCharges(i)}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Kind"
                                                name="kind"
                                                value={item.kind || ""}
                                                onChange={handleChangeOtherCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                type="number"
                                                label="Total Amount"
                                                name="totalAmount"
                                                value={item.totalAmount || ""}
                                                onChange={handleChangeOtherCharges(i)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveOtherCharges(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}

                            <Button
                                variant="outlined"
                                color="default"
                                onClick={() =>
                                    handleAddOtherCharges(state.others.length + 1)
                                }
                                endIcon={<AddIcon fontSize="large" />}
                            >
                                Insert Item
                            </Button>
                        </Paper>
                    </>
                ) : null}

                <Typography variant="overline" className={classes.title2}>
                    Note To Pricing
                </Typography>
                <Paper variant="outlined" square className={classes.paper}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            multiline
                            rows={3}
                            label="Note to Pricing"
                            type="text"
                            name="noteToPricing"
                            value={state.noteToPricing || ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Paper>

                <Typography variant="overline" className={classes.title2}>
                    Cover Letter to Customer
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Cover Letter to Customer"
                            multiline
                            required
                            rows={3}
                            type="text"
                            name="sendComment"
                            value={state.sendComment || ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Paper>

                <Typography variant="overline" className={classes.title2}>
                    Comment
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid item xs={12} md={12}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Comment"
                            multiline
                            rows={3}
                            type="text"
                            name="comment"
                            value={state.comment || ""}
                            onChange={handleChange}
                        />
                    </Grid>
                </Paper>

                {isCommercials && isCanEdit ? (
                    <Grid container justify="center">
                        <Grid item>
                            <ButtonGroup>
                                <Button
                                    className={classes.saveBtn}
                                    color="primary"
                                    variant="contained"
                                    disabled={loading}
                                    onClick={handleSave}
                                >
                                    {loading ? "Loading..." : "Save Changes"}
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.saveBtn2}
                                    variant="contained"
                                    onClick={handleSubmitHandler}
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}
            </form>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditQuotation;
