import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    Radio,
    RadioGroup,
    FormControlLabel, 
    FormControl,
    FormLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../atoms/Feedback";
import {
    getAssetRequisitions,
    getAssetRequisition,
} from "../../../actions/assetRequisitionActions";
import { getProfile } from "../../../actions/userActions";

// Head of Division
import ApproveAssetRequisitionModal from "./modals/ApproveAssetRequisitionModal";
import DisapproveAssetRequisitionModal from "./modals/DisapproveAssetRequisitionModal";

// Inventory Officer
import ReviewAssetRequisitionModal from "./modals/ReviewAssetRequisitionModal";
import RejectAssetRequisitionModal from "./modals/RejectAssetRequisitionModal";

// CEO
import AuthorizeAssetRequisitionModal from "./modals/AuthorizeAssetRequisitionModal";
import DeauthorizeAssetRequisitionModal from "./modals/DeauthorizeAssetRequisitionModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    capitalBudget: "",
    description: "",
    justification: "",
    expectedLife: "",
    paymentMode: "",
    requestDate: "",
    quotations: [],
};

const ViewAssetRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openApprove, setOpenApprove] = useState(false);
    const [openDisapprove, setOpenDisapprove] = useState(false);

    const [openReview, setOpenReview] = useState(false);
    const [openReject, setOpenReject] = useState(false);

    const [openAuthorize, setOpenAuthorize] = useState(false);
    const [openDeauthorize, setOpenDeauthorize] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, asset_requisition } = useSelector((state) => state.assetRequisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getAssetRequisitions());
        dispatch(getAssetRequisition(id));
        dispatch(getProfile);
    }, [dispatch, id]);

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;
        
    const isDivManager = profile
        ? profile && (profile?.id === state.division?.manager)
        : false;

    const isInventory = profile
        ? profile &&
        (profile?.id === "5fabb94da52ffb0017dbae89")
        : false;

    const isCEO = profile
        ? profile &&
        (profile?.id === "5f63554578b9af0017a3dec2")
        : false;
        
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            capitalBudget: (asset_requisition && asset_requisition.capitalBudget) || "",
            description: (asset_requisition && asset_requisition.description) || "",
            justification: (asset_requisition && asset_requisition.justification) || "",
            expectedLife: (asset_requisition && asset_requisition.expectedLife) || "",
            paymentMode: (asset_requisition && asset_requisition.paymentMode) || "",
            quotations: (asset_requisition && asset_requisition.quotations) || [],

            employee: (asset_requisition && asset_requisition.employee) || "",
            division: (asset_requisition && asset_requisition.division) || "",
            month: (asset_requisition && asset_requisition.month) || "",
            year: (asset_requisition && asset_requisition.year) || "",
            requestDate: (asset_requisition && moment(asset_requisition.requestDate).format("l")) || "",
            
            status: (asset_requisition && asset_requisition.status) || "",
            documentNo: (asset_requisition && asset_requisition.documentNo) || "",

            createdBy: (asset_requisition && asset_requisition.createdBy) || "",
            createdAt: (asset_requisition && asset_requisition.createdAt) || "",

            submittedBy: (asset_requisition && asset_requisition.submittedBy) || "",
            submittedAt: (asset_requisition && asset_requisition.submittedAt) || "",

            approvedBy: (asset_requisition && asset_requisition.approvedBy) || "",
            approvedAt: (asset_requisition && asset_requisition.approvedAt) || "",
            approveComment: (asset_requisition && asset_requisition.approveComment) || "",

            disapprovedBy: (asset_requisition && asset_requisition.disapprovedBy) || "",
            disapprovedAt: (asset_requisition && asset_requisition.disapprovedAt) || "",
            disapproveComment: (asset_requisition && asset_requisition.disapproveComment) || "",

            reviewedBy: (asset_requisition && asset_requisition.reviewedBy) || "",
            reviewedAt: (asset_requisition && asset_requisition.reviewedAt) || "",
            reviewComment: (asset_requisition && asset_requisition.reviewComment) || "",
            
            rejectedBy: (asset_requisition && asset_requisition.rejectedBy) || "",
            rejectedAt: (asset_requisition && asset_requisition.rejectedAt) || "",
            rejectComment: (asset_requisition && asset_requisition.rejectComment) || "",

            acceptedBy: (asset_requisition && asset_requisition.acceptedBy) || "",
            acceptedAt: (asset_requisition && asset_requisition.acceptedAt) || "",
            acceptComment: (asset_requisition && asset_requisition.acceptComment) || "",

            declinedBy: (asset_requisition && asset_requisition.declinedBy) || "",
            declinedAt: (asset_requisition && asset_requisition.declinedAt) || "",
            declineComment: (asset_requisition && asset_requisition.declineComment) || "",

            authorizedBy: (asset_requisition && asset_requisition.authorizedBy) || "",
            authorizedAt: (asset_requisition && asset_requisition.authorizedAt) || "",
            authorizeComment: (asset_requisition && asset_requisition.authorizeComment) || "",
        }));

    }, [asset_requisition]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // head of subdivision
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenDisapprove = () => {
        setOpenDisapprove(true);
    };
    const handleCloseDisapprove = () => {
        setOpenDisapprove(false);
    };

    // inventory officer
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // CEO
    const handleOpenAuthorize = () => {
        setOpenAuthorize(true);
    };
    const handleCloseAuthorize = () => {
        setOpenAuthorize(false);
    };
    const handleOpenDeauthorize = () => {
        setOpenDeauthorize(true);
    };
    const handleCloseDeauthorize = () => {
        setOpenDeauthorize(false);
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            {/* Head of subdivision */}
            <ApproveAssetRequisitionModal {...{ openApprove, handleCloseApprove, id }} />
            <DisapproveAssetRequisitionModal {...{ openDisapprove, handleCloseDisapprove, id }} />

            {/* Inventory Officer */}
            <ReviewAssetRequisitionModal {...{ openReview, handleCloseReview, id }} />
            <RejectAssetRequisitionModal {...{ openReject, handleCloseReject, id }} />

            {/* CEO */}
            <AuthorizeAssetRequisitionModal {...{ openAuthorize, handleCloseAuthorize, id }} />
            <DeauthorizeAssetRequisitionModal {...{ openDeauthorize, handleCloseDeauthorize, id }} />            

            <Typography variant="overline" className={classes.title}>
                View Capital Asset Requisition
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/asset/requisition")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "approved" || state.status === "reviewed") ? classes.statusGreen
                                        : (state.status === "authorized" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Employee</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.createdBy && state.createdBy.userName) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Division/Unit</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.division && state.division.name) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Document No</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={state.documentNo || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Request Date</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={state.requestDate || ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Capital Budget</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="capitalBudget"
                        value={(state.capitalBudget && state.capitalBudget) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Expected Life</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="expectedLife"
                        value={(state.expectedLife && state.expectedLife) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Complete item description including accessories</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="description"
                        value={(state.description && state.description) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold", marginBottom: 10 }}>Reason or justification for request, including use and capability</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="justification"
                        value={(state.justification && state.justification) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        component="fieldset"
                    >
                        <FormLabel style={{color: "currentcolor", fontWeight: "bold" }}> 
                            Payment Mode: 
                        </FormLabel>
                        <RadioGroup row disabled>
                            <FormControlLabel
                                value="Credit Card"
                                control={<Radio checked={(state.paymentMode === "Credit Card") ? true : false} />}
                                label="Credit Card"
                                name="paymentMode"
                                disabled
                            />
                            <FormControlLabel
                                value="Cheque"
                                control={<Radio checked={(state.paymentMode === "Cheque") ? true : false} />}
                                label="Cheque"
                                name="paymentMode"
                                disabled
                            />
                            <FormControlLabel
                                value="Bank Transfer"
                                control={<Radio checked={(state.paymentMode === "Bank Transfer") ? true : false} />}
                                label="Bank Transfer"
                                name="paymentMode"
                                disabled
                            />
                            <FormControlLabel
                                value="Cash"
                                control={<Radio checked={(state.paymentMode === "Cash") ? true : false} />}
                                label="Cash"
                                name="paymentMode"
                                disabled
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>


            <Typography variant="overline" className={classes.title2}>
                Price or Lease Quotations
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.quotations.map((sitem, i) => (
                <Grid
                    container
                    spacing={2}
                    className={classes.grid}
                    key={i}
                    >
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Description"
                            name="description"
                            value={(sitem.description && sitem.description) || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="New or Used"
                            name="newUsed"
                            value={(sitem.newUsed && sitem.newUsed) || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Vendor"
                            name="vendor"
                            value={(sitem.vendor && sitem.vendor) || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Terms"
                            name="terms"
                            value={(sitem.terms && sitem.terms) || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Amount"
                            name="amount"
                            type="number"
                            value={(sitem.amount && sitem.amount) || ""}
                            disabled
                        />
                    </Grid>
                </Grid>
                ))}
            </Paper>
            
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Created By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdAt}</span>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>

                    {(state.approvedAt && state.approvedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Approved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.disapprovedAt && state.disapprovedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Disapproved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedBy && state.disapprovedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapproveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {(state.reviewedAt && state.reviewedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.rejectedAt && state.rejectedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Rejected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.acceptedAt && state.acceptedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.declinedAt && state.declinedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Declined By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.declinedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.declineComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {(state.authorizedAt && state.authorizedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Authorized By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizedBy && state.authorizedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizeComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>

            {/* ACTION BUTTONS */}
            {(isDivManager || isAdmin) && state.status === "submitted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset acquisition approval or disapproval is to be done by the Head of the 
                                    Requesting Division/Unit.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={handleOpenDisapprove}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Disapprove
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(isInventory || isAdmin) && state.status === "approved" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset requisition review or rejection is to be done by the Inventory Control Officer.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                className={classes.saveBtn}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                                onClick={handleOpenReview}
                            >
                                Review
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(isCEO || isAdmin) && state.status === "reviewed" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset requisition authorization or deauthorization is to be done by the CEO.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                color="primary"
                                onClick={handleOpenAuthorize}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={loading}
                            >
                                Authorize
                            </Button>
                            <Button
                                onClick={handleOpenDeauthorize}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                            >
                                Deauthorize
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewAssetRequisition;
