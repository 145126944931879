import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    MenuItem,
    Select,
    InputLabel, 
    FormControl,
    Divider,
    InputAdornment,
    Paper,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { createOrderDatabase, getOrderDatabases } from "../../../../../actions/salesOrderActions";
import { getCustomers } from "../../../../../actions/customerActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import { getServiceTypesByDivision, getServiceTypes } from "../../../../../actions/serviceTypeActions";
import { getSequence } from "../../../../../actions/sequenceAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    nobottom: {
        marginBottom: 0,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    division: "",
    employee: "",
    employeeId:"",
    customer: "",
    crewName: "",
    projectTitle: "",
    projectDescription: "",
    projectTeamName: "",
    assetFacility: "",
    orderDate: "",
    serviceDivision: "",
    serviceType: "",
    serviceDescription: "",
    jobNumber: "",
    customerCode: "",
    pmdnNumber: "",
};

const CreateOrderDatabaseModal = ({ openCreate, handleCloseCreate, createOrderDatabase }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [requestDate] = useState(new Date());
    const [seqId] = useState("654b830ba067a300147192e5");
    const [orderDate, setOrderDate] = useState(new Date());
    const [state, setState] = useState(INIT_STATE);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.salesorder);
    const { divisions } = useSelector((state) => state.division);
    const { customers } = useSelector((state) => state.customer);
    const { services } = useSelector((state) => state.service);
    const { sequence } = useSelector((state) => state.sequence);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getCustomers())
        dispatch(getDivisionsByService());
        dispatch(getSequence(seqId));
    }, [dispatch, seqId]);

    useEffect(() => {
        if (state.serviceDivision !== "") {
          dispatch(getServiceTypesByDivision(state.serviceDivision));
        } else {dispatch(getServiceTypes());}
      }, [dispatch, state.serviceDivision]);

      useEffect(() => {
        if (state.customerCode !== "" && sequence !== "") {
            let pmdnNumber = "RS/PMDN/" + state.customerCode + "/" + sequence.data.nextNumber;
            let jobNumber = sequence.data.nextNumber;
            setState((prev) => ({
                ...prev,
                pmdnNumber: pmdnNumber,
                jobNumber: jobNumber
            }));
        } 
      }, [state.customerCode, sequence]);
    
    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            requestDate: moment(requestDate).toDate(),
        }));
    }, [profile, requestDate]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeOrderDate = (date) => {
        setOrderDate(date);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            orderDate: orderDate,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createOrderDatabase(data));
        });
        
        if (res) {
            dispatch(getOrderDatabases());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "New Order Record Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/sales/orderdatabase`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.requestDate ||
        !state.customer || 
        !state.crewName || 
        !state.projectTitle || 
        !state.projectTeamName || 
        !state.projectDescription || 
        !state.assetFacility || 
        !orderDate || 
        !state.serviceDescription || 
        !state.serviceType || 
        !state.serviceDivision || 
        !state.customerCode || 
        !state.pmdnNumber || 
        !state.jobNumber;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Order Database
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Order Date"
                                            fullWidth
                                            inputVariant="outlined"
                                            value={orderDate}
                                            onChange={handleChangeOrderDate}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        <Divider
                                                            className={classes.divider2}
                                                            orientation="vertical"
                                                        />
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                disabled={orderDate === null}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOrderDate(null);
                                                                }}
                                                            >
                                                                {orderDate ? (
                                                                    <CloseIcon />
                                                                ) : (
                                                                    <FilterListIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    </>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl variant="outlined" fullWidth required>
                                        <InputLabel id="customer">Customer</InputLabel>
                                            <Select
                                                name="customer"
                                                value={state.customer}
                                                onChange={handleChange}
                                                labelId="customer"
                                            >
                                            <MenuItem disabled>Select</MenuItem>
                                                {customers && customers.map((item) => (
                                                    <MenuItem key={item._id} value={item._id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Crew Name"
                                            name="crewName"
                                            value={state.crewName || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Project Title"
                                            name="projectTitle"
                                            value={state.projectTitle || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Project Team Name"
                                            name="projectTeamName"
                                            value={state.projectTeamName || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Project Description"
                                            name="projectDescription"
                                            value={state.projectDescription || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Asset/Facility Location"
                                            name="assetFacility"
                                            value={state.assetFacility || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="overline" className={classes.title2}>
                                    Add Services/Jobs
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth required variant="outlined">
                                                <InputLabel id="serviceDivision">Service Division</InputLabel>
                                                <Select
                                                    labelId="serviceDivision"
                                                    name="serviceDivision"
                                                    value={state.serviceDivision || ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                        {divisions && divisions.map((item) => (
                                                        <MenuItem key={item._id} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="outlined" fullWidth required>
                                            <InputLabel id="serviceType">Service Type</InputLabel>
                                                <Select
                                                    name="serviceType"
                                                    value={state.serviceType}
                                                    onChange={handleChange}
                                                    labelId="serviceType"
                                                >
                                                <MenuItem disabled>Select</MenuItem>
                                                    {(state.serviceDivision && state.serviceDivision) && services && services.map((item) => (
                                                        <MenuItem key={item._id} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Service Description"
                                                name="serviceDescription"
                                                value={state.serviceDescription || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Customer Code"
                                                name="customerCode"
                                                value={state.customerCode || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Job Number"
                                                name="jobNumber"
                                                value={state.jobNumber || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="PMDN Number"
                                                name="pmdnNumber"
                                                value={state.pmdnNumber || ""}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createOrderDatabase, })(CreateOrderDatabaseModal);
