import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Feedback from "../../../../atoms/Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { createCRF, getCRFs } from "../../../../../actions/ppmActions/changeMgtActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    requestDescription: "",
    justification: "",
    priority: "",
    resourceRequirements: "",
    budgetProjection: "",
    timelines: [],
    outcomes: [],
    riskFactors: "",
    opportunityFactors: "",
    trainingRequired: "",
    reason: "",
    recommendation: "",
};

const INIT_OUTCOME = {
    description: "",
}

const INIT_TIMELINE = {
    action: "",
    dueDate: "",
}

const CreateChangeRequestModal = ({ openCreate, handleCloseCreate, createCRF }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [requestDate] = useState(moment(new Date()));
    const [dueDate, setDueDate] = useState("");
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const [timeline, setTimeline] = useState(INIT_TIMELINE);
    const [outcome, setOutcome] = useState(INIT_OUTCOME);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.changeMgt);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            month: month,
            year: year,
            requestDate: moment(requestDate).toDate(),
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
        }));
    }, [profile, year, month, requestDate]);

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const handleChangeDueDate = (date) => {
        setDueDate(date);
    };

    useEffect(() => {
        if(dueDate !==""){
            let d = moment(dueDate).toDate();
            setTimeline((prev) => ({ ...prev, dueDate: moment(d).format('DD/MM/YYYY') }));
        }
    }, [dueDate]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleTimelineChange = (e) => {
        e.persist();
        setTimeline((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    
    const handleOutcomeChange = (e) => {
        e.persist();
        setOutcome((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddTimelineArray = (item) => {
        if (item.action !== "" && item.dueDate !== "") {
            setState((prev) => ({
                ...prev,
                timelines: prev.timelines.concat([item]),
            }));
            setTimeline(INIT_TIMELINE); setDueDate("");
        }
    };

    const handleRemoveTimelineArray = (i) => {
        setState((prev) => ({
            ...prev,
            timelines: prev.timelines.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddOutcomeArray = (item) => {
        if (item.description !== "") {
            setState((prev) => ({
                ...prev,
                outcomes: prev.outcomes.concat([item]),
            }));
            setOutcome(INIT_OUTCOME);
        }
    };

    const handleRemoveOutcomeArray = (i) => {
        setState((prev) => ({
            ...prev,
            outcomes: prev.outcomes.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            timelines: state.timelines,
            outcomes: state.outcomes,
        };
        
        const res = await new Promise((resolve, reject) => {
            resolve(createCRF(data));
        });
        
        if (res) {
            dispatch(getCRFs());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Change Request Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.requestDescription ||
        !state.justification || 
        !state.priority || 
        !state.budgetProjection ||
        !state.resourceRequirements ||
        // !state.riskFactors ||
        // !state.opportunityFactors ||
        (state.timelines.length === 0) ||
        (state.outcomes.length === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Change Request
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                        label="Change Initiator"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={(state.employeeName && state.employeeName) || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="EID"
                                            name="employeeId"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.employeeId || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Request Date"
                                            fullWidth
                                            required
                                            name="requestDate"
                                            inputVariant="outlined"
                                            value={state.requestDate}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={2}
                                            label="Change Request Description"
                                            name="requestDescription"
                                            value={state.requestDescription || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={2}
                                            label="Business or Technical Justification"
                                            name="justification"
                                            value={state.justification || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Resource Requirements"
                                            name="resourceRequirements"
                                            value={state.resourceRequirements || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Budget Projection"
                                            name="budgetProjection"
                                            value={state.budgetProjection || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <FormControl
                                            fullWidth
                                            required
                                            variant="outlined"
                                            component="fieldset"
                                        >
                                            <FormLabel style={{fontWeight: 'bold', color: "currentcolor"}}> 
                                                Priority 
                                            </FormLabel>
                                            <RadioGroup row onChange={handleChange}>
                                                <FormControlLabel
                                                    value="Critical"
                                                    control={<Radio checked={(state.priority === "Critical") ? true : false} />}
                                                    label="Critical"
                                                    name="priority"
                                                />
                                                <FormControlLabel
                                                    value="High"
                                                    control={<Radio checked={(state.priority === "High") ? true : false} />}
                                                    label="High"
                                                    name="priority"
                                                />
                                                <FormControlLabel
                                                    value="Medium"
                                                    control={<Radio checked={(state.priority === "Medium") ? true : false} />}
                                                    label="Medium"
                                                    name="priority"
                                                />
                                                <FormControlLabel
                                                    value="Low"
                                                    control={<Radio checked={(state.priority === "Low") ? true : false} />}
                                                    label="Low"
                                                    name="priority"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Typography variant="overline" className={classes.title2}>
                                    Add Project Timelines
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {timeline ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={7}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Action"
                                                    name="action"
                                                    value={(timeline.action && timeline.action) || ""}
                                                    onChange={handleTimelineChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <DatePicker
                                                    className={classes.marginBottom}
                                                    autoOk
                                                    format="DD/MM/yyyy"
                                                    views={["year", "month", "date"]}
                                                    fullWidth
                                                    required
                                                    inputVariant="outlined"
                                                    label="Due Date"
                                                    name="dueDate"
                                                    value={(dueDate ? moment(dueDate).toDate() : state.date) || ""}
                                                    onChange={handleChangeDueDate}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#1877F2" }}
                                                    onClick={() => handleAddTimelineArray(timeline)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Add Expected Outcome
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    {outcome ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={11}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description"
                                                    name="description"
                                                    value={(outcome.description && outcome.description) || ""}
                                                    onChange={handleOutcomeChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    style={{ backgroundColor: "#1877F2" }}
                                                    onClick={() => handleAddOutcomeArray(outcome)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* Timelines Added */}
                                {(state.timelines && state.timelines.length > 0) ? 
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Project Timelines
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {(state.timelines && state.timelines.length > 0) ? state.timelines.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={7}>
                                                    <label style={{ fontWeight: "bold" }}>Action</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="action"
                                                        value={sitem.action || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <label style={{ fontWeight: "bold" }}>Due Date</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="dueDate"
                                                        value={sitem.dueDate || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveTimelineArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Project Timeline added yet
                                            </Typography>
                                        </>}
                                    </Paper>
                                </> : null }

                                {/* Outcomes Added */}
                                {(state.outcomes && state.outcomes.length > 0) ? 
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Expected Outcomes
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {(state.outcomes && state.outcomes.length > 0) ? state.outcomes.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={11}>
                                                    <label style={{ fontWeight: "bold" }}>Description</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        name="description"
                                                        value={sitem.description || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <label>&nbsp;</label>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveOutcomeArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Expected Outcome added yet
                                            </Typography>
                                        </>}
                                    </Paper>
                                </> : null }

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createCRF, getCRFs })(CreateChangeRequestModal);
