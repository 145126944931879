import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Radio,
    FormControlLabel,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { getFIELD, getFIELDs } from "../../../../actions/ppmActions/changeMgtActions";
import { getProfile } from "../../../../actions/userActions";

// Board
import ApproveChangeRequest from "./_modals/ApproveFieldForce";
import RejectChangeRequest from "./_modals/RejectFieldForce";

// Coordinator
import ReviewChangeRequest from "./_modals/ReviewFieldForce";
import DeclineChangeRequest from "./_modals/DeclineFieldForce";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        marginBottom: 30,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        padddingBottom: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.05) : darken("#fff", 0.80),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.15) : darken("#fff", 0.80),
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 10,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    month: "",
    year: "",
    drivingForces: [],
    restrainingForces: [],
    totalDrivingForces:0,
    totalRestrainingForces:0,
};

const ViewFieldForce = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const { profile } = useSelector((state) => state.user);
    const { error, loading, force } = useSelector((state) => state.fieldForce);

    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openReview, setOpenReview] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile())
        dispatch(getFIELD(id));
        dispatch(getFIELDs());
    }, [dispatch, id]);
    
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            changeRequest: (force && force.changeRequest) || "",
            drivingForces: (force && force.drivingForces) || [],
            restrainingForces: (force && force.restrainingForces) || [],
            totalDrivingForces: (force && force.totalDrivingForces) || 0,
            totalRestrainingForces: (force && force.totalRestrainingForces) || 0,
            requestDate: (force && force.requestDate) || "",
            division: (force && force.division) || "",
            month: (force && force.month) || "",
            year: (force && force.year) || "",

            status: (force && force.status) || "",
            documentNo: (force && force.documentNo) || "",
            
            createdBy: (force && force.createdBy) || "",
            createdAt: (force && force.createdAt) || "",

            submittedBy: (force && force.submittedBy) || "",
            submittedAt: (force && force.submittedAt) || "",

            approvedBy: (force && force.approvedBy) || "",
            approvedAt: (force && force.approvedAt) || "",
            approveComment: (force && force.approveComment) || "",
            
            rejectedBy: (force && force.rejectedBy) || "",
            rejectedAt: (force && force.rejectedAt) || "",
            rejectComment: (force && force.rejectComment) || "",

            reviewedBy: (force && force.reviewedBy) || "",
            reviewedAt: (force && force.reviewedAt) || "",
            reviewComment: (force && force.reviewComment) || "",

            declinedBy: (force && force.declinedBy) || "",
            declinedAt: (force && force.declinedAt) || "",
            declineComment: (force && force.declineComment) || "",

        }));
    }, [force]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isCoordinator = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "coordinator" && grp.module === "change_force"
        )
        : false;

    const isBoard = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "board" && grp.module === "change_force"
        )
        : false;

    // change coordinator
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // change initiator
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                {/* Change Board */}
                <ApproveChangeRequest {...{ openApprove, handleCloseApprove, id }} />
                <RejectChangeRequest {...{ openReject, handleCloseReject, id }} />

                {/* Change Coordinator */}
                <ReviewChangeRequest {...{ openReview, handleCloseReview, id }} />
                <DeclineChangeRequest {...{ openDecline, handleCloseDecline, id }} />


                <Typography variant="overline" className={classes.title}>
                    Field Force Analysis
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/change/fieldforce")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Paper variant="outlined" className={classes.status}>
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Document No.
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    {loading ? "Loading..." : state.documentNo}
                                    
                                </Paper>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <Paper
                                    variant="outlined"
                                    className={clsx(
                                        state.status === "draft"
                                            ? classes.statusYellow
                                            : state.status === "submitted"
                                                ? classes.statusOrange
                                                : state.status === "reviewed"
                                                    ? classes.statusGreen
                                                    : (state.status === "approved" || state.status === "sent")
                                                        ? classes.statusDarkGreen
                                                        : classes.statusRed,
                                        classes.status
                                    )}
                                >
                                
                                <Typography variant="overline" className={classes.statusTitle}>
                                    Status
                                </Typography>
                                <Divider className={classes.divider2} orientation="vertical" />
                                <Typography variant="overline" className={classes.statusTitle}>
                                    {loading ? "Loading..." : state.status}
                                </Typography>

                                </Paper>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>

                <div>
                    <Typography variant="overline" className={classes.title}>
                        Change Request Details
                    </Typography>

                    <Paper variant="outlined" square spacing={3} className={classes.paper}>
                        <Paper variant="outlined" square className={classes.paper1}>
                            <Grid container spacing={2} className={classes.grid} style={{marginBottom:0}} >
                                {state.changeRequest !== null ? <>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Change Request No"
                                            name="changeRequestNo"
                                            value={state.changeRequest?.requestNo || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Change Initiator"
                                            name="changeInitiator"
                                            value={state.changeRequest?.createdBy?.userName || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Change Coordinator"
                                            name="changeCoordinator"
                                            value={state.changeRequest?.reviewedBy?.userName || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Change Request Description"
                                            name="changeRequestDescription"
                                            value={state.changeRequest?.requestDescription || ""}
                                            disabled
                                        />
                                    </Grid>
                                </> : null }
                            </Grid>
                        </Paper>

                        {/* Driving Force Added */}
                        {(state.drivingForces && state.drivingForces.length > 0) ? 
                        <>
                            <Typography variant="overline" className={classes.title}>
                                Driving Forces
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper1}>
                                <TableContainer>
                                    <Table className={classes.table} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>SN</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Very Likely</TableCell>
                                                <TableCell>Likely</TableCell>
                                                <TableCell>Undecided</TableCell>
                                                <TableCell>Unlikely</TableCell>
                                                <TableCell>Very Unlikely</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {state.drivingForces && state.drivingForces.map((sitem, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                <TableCell component="th" scope="row">{sitem.description || ""}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio checked={(sitem.likelihood === 5) ? true : false} />}
                                                        label="Very Likely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio checked={(sitem.likelihood === 4) ? true : false} />}
                                                        label="Likely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio checked={(sitem.likelihood === 3) ? true : false} />}
                                                        label="Undecided"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio checked={(sitem.likelihood === 2) ? true : false} />}
                                                        label="Unlikely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio checked={(sitem.likelihood === 1) ? true : false} />}
                                                        label="Very Likely"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                            <TableRow>
                                                <TableCell component="th" scope="row" colSpan={2}>
                                                    Total:
                                                </TableCell>
                                                <TableCell component="th" scope="row" colSpan={5}>
                                                    {(state?.totalDrivingForces && state?.totalDrivingForces) || 0}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </> : null }

                        {/* Restraining Force Added */}
                        {(state.restrainingForces && state.restrainingForces.length > 0) ? 
                        <>
                            <Typography variant="overline" className={classes.title}>
                                Restraining Forces
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper1}>
                                <TableContainer>
                                    <Table className={classes.table} aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>SN</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell>Very Likely</TableCell>
                                                <TableCell>Likely</TableCell>
                                                <TableCell>Undecided</TableCell>
                                                <TableCell>Unlikely</TableCell>
                                                <TableCell>Very Unlikely</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {state.restrainingForces && state.restrainingForces.map((sitem, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{i + 1}</TableCell>
                                                <TableCell component="th" scope="row">{sitem.description || ""}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="5"
                                                        control={<Radio checked={(sitem.likelihood === 5) ? true : false} />}
                                                        label="Very Likely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="4"
                                                        control={<Radio checked={(sitem.likelihood === 4) ? true : false} />}
                                                        label="Likely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio checked={(sitem.likelihood === 3) ? true : false} />}
                                                        label="Undecided"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio checked={(sitem.likelihood === 2) ? true : false} />}
                                                        label="Unlikely"
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio checked={(sitem.likelihood === 1) ? true : false} />}
                                                        label="Very Likely"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                            <TableRow>
                                                <TableCell component="th" scope="row" colSpan={2}>
                                                    Total:
                                                </TableCell>
                                                <TableCell component="th" scope="row" colSpan={5}>
                                                    {(state?.totalRestrainingForces && 
                                                        state?.totalRestrainingForces) || 0}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </> : null }
                    </Paper>
                    
                    
                        
                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        style={{ marginBottom: 5 }}
                    >
                        <Grid item>
                            <Typography variant="overline" className={classes.title2}>
                                Audit Trail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Icon className="fas fa-clipboard-list" color="disabled" />
                        </Grid>
                    </Grid>


                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            {state.status === "draft" && (
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Created By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdAt}</span>
                                    </Paper>
                                </Grid>
                            )}
                            {state.status !== "draft" && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Submitted By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedAt}</span>
                                        </Paper>
                                    </Grid>
                                    {(state.reviewedAt && state.reviewedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Reviewed By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.declinedAt && state.declinedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Declined By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declineComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.approvedAt && state.approvedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Approved By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approveComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.rejectedAt && state.rejectedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Rejected By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Paper>

                    {(isCoordinator || isAdmin) && state.status === "submitted" ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            This change request form review or decline is to be done by the Change Coordinator.
                                        </Typography>
                                    </Paper>
                                </div>
                                <ButtonGroup>
                                    <Button
                                        onClick={handleOpenReview}
                                        className={classes.saveBtn2}
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        Review
                                    </Button>
                                    <Button
                                        onClick={handleOpenDecline}
                                        className={classes.saveBtn}
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Decline
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    ) : null}

                    {(isBoard || isAdmin) && state.status === "reviewed" ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            This change request decision approval or rejection is to be done by the Chief Executive Officer.
                                        </Typography>
                                    </Paper>
                                </div>
                                <ButtonGroup>
                                    <Button
                                        onClick={handleOpenApprove}
                                        className={classes.saveBtn2}
                                        variant="contained"
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        onClick={handleOpenReject}
                                        className={classes.saveBtn}
                                        color="secondary"
                                        variant="contained"
                                    >
                                        Reject
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    ) : null}
                </div>

            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewFieldForce;
