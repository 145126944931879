import {
  GET_ANNUAL_BUDGETS,
  GET_ALL_ANNUAL_BUDGETS,
  CREATE_ANNUAL_BUDGET,
  UPDATE_ANNUAL_BUDGET,
  CREATE_ANNUAL_BUDGET_LINE,
  GET_ALL_ANNUAL_BUDGET_LINES,
  GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID,
  GET_ANNUAL_BUDGET_LINE_BY_ID,
  ERROR,
  ANNUAL_BUDGET_LINE_ERROR,
  ANNUAL_BUDGETS_ERROR,
  ANNUAL_BUDGET_ERROR,
  DELETE_ANNUAL_BUDGET_LINE,
  GET_ANNUAL_BUDGET_BY_ID,
  SUBMIT_ANNUAL_BUDGET,
  CONFIRM_ANNUAL_BUDGET,
  DECLINE_ANNUAL_BUDGET,
  UPDATE_ANNUAL_BUDGET_LINE,
  APPROVE_ANNUAL_BUDGET,
  REJECT_ANNUAL_BUDGET,
  REVIEW_ALL_ANNUAL_BUDGET,
  APPROVE_ALL_ANNUAL_BUDGET,
  GET_ALL_ANNUAL_BUGDETS_REVIEWS,
  APPROVE_SINGLE_ANNUAL_BUDGET,
  REQUEST_ANNUAL_BUDGET_CLARIFICATION,
  REQUEST_ANNUAL_BUDGET_MODIFICATION,
  CLARIFY_ANNUAL_BUDGET,
  MODIFY_ANNUAL_BUDGET,
  SUBMIT_REJECTED_ANNUAL_BUDGET,
  GET_BUDGET_UTILIZATIONS,
  BUDGET_UTILIZATIONS_ERROR,
} from "../types";

import axios from "axios";

const token = localStorage.getItem("token:key");

const URL = "/api/v1/budget/annual";

export const getAnnualBudgets = () => async (dispatch) => {
  try {
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ANNUAL_BUDGETS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    dispatch({
      type: ANNUAL_BUDGETS_ERROR,
      error:
        error.response && error.response.data
          ? error.response.data
          : error.request
          ? error.request.data
          : error.message,
    });
  }
};


export const getDivisionYearAnnualBudgetLines = (data) => async (dispatch) => {
  try {
    let { year, division } = data;
    if(year && division){
      let URL = `/api/v1/budget/annual/${year}/${division}/item`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID,
        payload: res.data,
      });
      return await res.data;
    }else {
      return null;
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};


export const getOneAnnualBudgetLineById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/item/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ANNUAL_BUDGET_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};


export const getBudgetUtilizations = () => async (dispatch) => {
  try {
    const URL = "/api/v1/budget/utilizations";
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_BUDGET_UTILIZATIONS,
      payload: res.data?.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: BUDGET_UTILIZATIONS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: BUDGET_UTILIZATIONS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: BUDGET_UTILIZATIONS_ERROR,
        error: error.message,
      });
    }
  }
};


export const getAllAnnualBudgets = () => async (dispatch) => {
  try {
    const URL = "/api/v1/budget/annual/all";
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ANNUAL_BUDGETS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// GET ALL ANNUAL BUDGETS REVIEWS
export const getAllAnnualBudgetsReviews = () => async (dispatch) => {
  try {
    const URL = "/api/v1/budget/annual/awaitingAction";
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ANNUAL_BUGDETS_REVIEWS,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// GET ALL ANNUAL BUDGETS APPROVALS
// export const getAllAnnualBudgetsApprovals = () => async (dispatch) => {
//   try {
//     const URL = "/api/v1/budget/annual/awaitingApproval";
//     const res = await axios.get(URL, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     dispatch({
//       type: GET_ALL_ANNUAL_BUGDETS_APPROVALS,
//       payload: res.data,
//     });
//     return await res.data;
//   } catch (error) {
//     if (error.response) {
//       dispatch({
//         type: ANNUAL_BUDGETS_ERROR,
//         error: error.response.data,
//       });
//     } else if (error.request) {
//       dispatch({
//         type: ANNUAL_BUDGETS_ERROR,
//         error: error.request.data,
//       });
//     } else {
//       dispatch({
//         type: ANNUAL_BUDGETS_ERROR,
//         error: error.message,
//       });
//     }
//   }
// };

export const createAnnualBudget = ({ year }) => async (dispatch) => {
  try {
    const res = await axios.post(
      URL,
      { year },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: CREATE_ANNUAL_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const updateAnnualBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const submitAnnualBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/submit/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_ANNUAL_BUDGET,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ANNUAL_BUDGET_ERROR,
      error:
        error.response && error.response.data
          ? error.response.data
          : error.request
          ? error.request.data
          : error.message,
    });
  }
};

export const updateAnnualBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_ANNUAL_BUDGET_LINE,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const reviewAllAnnualBudget = (data) => async (dispatch) => {
  try {
    const URL = "/api/v1/budget/annual/review/all";
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REVIEW_ALL_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// approve single annual supplementary budget line
export const approveSuppAnnualBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/suppapprove/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_ALL_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};


// decline single annual supplementary budget line
export const declineSuppAnnualBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/suppdecline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_ALL_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};



// Approve All Annual Budget

export const approveAllAnnualBudget = (data) => async (dispatch) => {
  try {
    const URL = "/api/v1/budget/annual/finalapprove";
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_ALL_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// APPROVE SINGLE BUDGET BY CEO

export const approveSingleAnnualBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/finalapprove/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_SINGLE_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// REQUEST FOR MODIFICATION BY CEO

export const requestAnnualBudgetModification = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/requestmodify/${data.budgetId}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REQUEST_ANNUAL_BUDGET_MODIFICATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

// REQUEST FOR CLARIFICATION BY CEO

export const requestAnnualBudgetClarification = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/requestclarify/${data.budgetId}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REQUEST_ANNUAL_BUDGET_CLARIFICATION,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGETS_ERROR,
        error: error.message,
      });
    }
  }
};

export const getAnnualBudgetById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ANNUAL_BUDGET_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllAnnualBudgetLines = () => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_ANNUAL_BUDGET_LINES,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const createAnnualBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line/${data.budgetId}`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_ANNUAL_BUDGET_LINE,
      payload: res.data.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};


export const createAnnualSuppBudgetLine = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line/supp/${data.budgetId}`;
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_ANNUAL_BUDGET_LINE,
      payload: res.data.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getAnnualBudgetLineById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ANNUAL_BUDGET_LINE_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ERROR,
        error: error.message,
      });
    }
  }
};

export const getAllAnnualBudgetLineByBudgetId = (budgetId) => async (
  dispatch
) => {
  try {
    const URL = `/api/v1/budget/annual/line/budgetId/${budgetId}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_ALL_ANNUAL_BUDGET_LINE_BY_BUDGET_ID,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_LINE_ERROR,
        error: error.message,
      });
    }
  }
};

export const deleteAnnualBudgetLine = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/line/${id}`;
    await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_ANNUAL_BUDGET_LINE,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    // if (error.response) {
    //   dispatch({
    //     type: ANNUAL_BUDGET_LINE_ERROR,
    //     error: error.response.data,
    //   });
    // } else if (error.request) {
    //   dispatch({
    //     type: ANNUAL_BUDGET_LINE_ERROR,
    //     error: error.request.data,
    //   });
    // } else {
    //   dispatch({
    //     type: ANNUAL_BUDGET_LINE_ERROR,
    //     error: error.message,
    //   });
    // }
  }
};

/**
 * Approval Cycle
 *
 */
// Manager - Confirm
export const confirmBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/confirm/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CONFIRM_ANNUAL_BUDGET,
      payload: res.data,
    });
    return await res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

// Manager Decline
export const declineBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/decline/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DECLINE_ANNUAL_BUDGET,
      payload: res.data,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

export const approveBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/approve/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: APPROVE_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

// Head Decline
export const rejectBudget = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/budget/annual/reject/${data.id}`;
    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: REJECT_ANNUAL_BUDGET,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

export const clarifyAnnualBudget = (data) => async (dispatch) => {
  try {
    const res = await axios.put(
      `${URL}/clarification/submit/${data.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({
      type: CLARIFY_ANNUAL_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

export const modifyAnnualBudget = (data) => async (dispatch) => {
  try {
    const res = await axios.put(`${URL}/modification/submit/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: MODIFY_ANNUAL_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};

export const submitRejectedAnnualBudget = (data) => async (dispatch) => {
  try {
    const res = await axios.put(`${URL}/rejected/submit/${data.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: SUBMIT_REJECTED_ANNUAL_BUDGET,
      payload: res.data,
    });
    return await res.data.data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.response.data,
      });
    } else if (error.request) {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.request.data,
      });
    } else {
      dispatch({
        type: ANNUAL_BUDGET_ERROR,
        error: error.message,
      });
    }
  }
};
