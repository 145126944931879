import React, { useEffect, useCallback } from "react";
import { Grid, Typography, Paper, makeStyles, TextField, darken, Backdrop } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { checkinByCodeVisitorAppointment, resetVisitorErrors } from "../../../actions/visitorsActions";
import Feedback from "../../atoms/Feedback";
import Swal from "sweetalert2";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 18,
        padddingBottom: 5,
        fontFamily: "Rubik",
        textTransform: "none"
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper2: {
        width: "100%",
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2a: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: 200,
        background: "#8b0000",
        color: "#fff",
        "&:hover": {
            background: darken("#8b0000", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn3: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(1.2),
        width: "120px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
}));

const CheckVisitorIn = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [visitorInCode, setVisitorCode] = React.useState('');
    const { loading, error, visitor } = useSelector((state) => state.visitors);

    const clearErrorCallback = useCallback(() => {
        dispatch(resetVisitorErrors());
    }, [dispatch]);

    useEffect(() => {
        if (error && error !== null) {
            setVisitorCode(null);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));

            Swal.fire({
                icon: "error",
                title: "Error",
                text: error,
            });

            setTimeout(() => {
                clearErrorCallback()
            }, 1000);
        }
    }, [error, clearErrorCallback]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
        e.persist();
        setVisitorCode(e.target.value);
    };

    const checkVisitorInCallback = useCallback(() => {
        if (visitorInCode && visitorInCode !== null && visitorInCode.length === 4) {
            const data = {
                code: visitorInCode,
                comment: 'Checked In',
                path: `visitor`,
            };
            dispatch(checkinByCodeVisitorAppointment(data));
            setVisitorCode(null);
        } else {
            dispatch(resetVisitorErrors());
        }
    }, [dispatch, visitorInCode]);

    useEffect(() => {
        checkVisitorInCallback()
    }, [checkVisitorInCallback]);

    useEffect(() => {
        if (visitor && visitor.status === "checked_in" && (visitor.visitCode === visitorInCode)) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: `${visitor.visitorName} has been checked in successfully at ${moment().format("LTS")}. `,
            });
            setVisitorCode(null);
        }
      }, [visitor, visitorInCode]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            <>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" style={{ marginBottom: 0 }} className={classes.title}>
                    Check Visitor In By Code 
                </Typography>

                
                <Paper style={{ marginTop: 45 }}>
                    <Grid
                        container
                        justify="center"
                        alignItems="flex-start"
                        spacing={3}
                        style={{ paddingLeft: 15 }}
                    >
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Check In Visitor Code"
                                name="visitorInCode"
                                onChange={handleChange}
                                autoFocus
                                required
                                helperText={
                                    <Typography variant="overline" className={classes.small}>
                                        Place the cursor in the text field and position the QR CODE in front of the Scanner
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        </div>
    );
};

export default CheckVisitorIn;
