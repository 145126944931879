import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Backdrop,
    // Chip,
    Menu,
    MenuItem,
    // Icon,
    IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import Fade from "@material-ui/core/Fade";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";

import { getBudgetUtilizations } from "../../../actions/annualAction";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../atoms/Feedback";
// import clsx from "clsx";
// import moment from "moment";
import { formatAmount } from "../../../utils";
import { ExportJsonCsv } from "react-export-json-csv";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusDarkDarkGreen: {
        backgroundColor: "#4caf50",
        color:"#fff",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const BudgetUtilizations = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");

    const [search, setSearch] = useState("");
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { budgetUtilizations, loading, error } = useSelector((state) => state.annualBudget);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: loading,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error, loading]);

    useEffect(() => {
        dispatch(getBudgetUtilizations());
    }, [dispatch]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };
    
    const handleOpenFilter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    let filteredRows = () =>
        budgetUtilizations === undefined
            ? []
            : budgetUtilizations &&
            budgetUtilizations.filter((row) => {
                if (search !== "") {
                    return (
                        row.amount
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 || 
                        row.status
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 || 
                        row.employee?.userName
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 || 
                        row.division?.name
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 || 
                        row.createdAt
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1 || 
                        row.budgetType
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1
                    );
                } else if (filter !== "") {
                    return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSelectFilter = (e, val) => {
        setFilter(val.substr(0, 20));
        handleCloseFilter();
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage,
        (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

    const headers = [
        { key: 'sn', name: 'SN',  },
        { key: 'year', name: 'Year', },
        { key: 'month', name: 'Month', },
        { key: 'employee', name: 'Employee', },
        { key: 'division', name: 'Division/Unit', },
        { key: 'dateUtilized', name: 'Utilized on',  },
        { key: 'budgetType', name: 'Budget Type', },
        { key: 'currency', name: 'Currency',  },
        { key: 'amount', name: 'Amount',  },
        { key: 'status', name: 'Status', },
    ];
        
    const exportData = filteredRows() && filteredRows().map((item, i) => (
        {
            sn: i + 1, 
            year: item.year, 
            month: item.month, 
            employee: item.employee?.userName, 
            division: item.division?.name,
            dateUtilized: item.createdAt,
            budgetType: item.budgetType,
            currency: item.currency || "",
            amount : item.amount,
            status: item.status,   
        }),
    );
    
    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            
            <Typography variant="overline" gutterBottom className={classes.title}>
                Budget Utilization
            </Typography>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.createBtn}
                        // onClick={handleOpenCreate}
                    >
                        Create
                    </Button>
                </Grid>
                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>

            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <ExportJsonCsv 
                            headers={headers} 
                            items={exportData} 
                            fileTitle="budget_utilizations"
                            style={{ marginRight: 10, marginTop: 15, border:'none', fontWeight:'normal', background:'transparent', cursor:'pointer' }}
                        >
                            <IconButton>
                                <Typography style={{color:'#999'}}> <CloudDownloadOutlined /> </Typography>
                            </IconButton>
                        </ExportJsonCsv>
                        
                        <IconButton
                            style={{ marginRight: 10, marginTop: -10 }}
                            onClick={handleOpenFilter}
                        >
                            <FilterListIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            TransitionComponent={Fade}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                                All
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                                Draft
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Completed")}>
                                Completed
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>SN</TableCell>
                                <TableCell>Year</TableCell>
                                <TableCell>Month</TableCell>
                                <TableCell>Employee</TableCell>
                                <TableCell>Division/Unit</TableCell>
                                <TableCell>Utilized On</TableCell>
                                <TableCell>Budget Type</TableCell>
                                <TableCell>Amount</TableCell>
                                {/* <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.year && row.year}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.month && row.month}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.employee && row.employee.userName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.division && row.division.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.createdAt && row.createdAt}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.budgetType && row.budgetType} 
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {((row.amount && formatAmount(row.amount)) || 0)}
                                        </TableCell>
                                        {/* <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.status}
                                                variant="outlined"
                                                className={clsx(
                                                    row.status === "draft" || row.status === "rejected"
                                                        ? classes.statusYellow : row.status === "submitted" 
                                                            ? classes.statusOrange : row.status === "accepted"  
                                                                ? classes.statusGreen : row.status === "completed"
                                                                    ? classes.statusDarkGreen : row.status === "paid" 
                                                                        ? classes.statusDarkDarkGreen : classes.statusRed,classes.chip
                                                )}
                                            />
                                        </TableCell> */}
                                        {/* <TableCell component="th" scope="row">
                                            <IconButton
                                                variant="extended"
                                                color="secondary"
                                                // onClick={() => handleDeleteOpen(row._id)}
                                                disabled
                                            >
                                                <Icon className="fas fa-trash" color="secondary" />
                                            </IconButton>
                                        </TableCell> */}
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={10}
                                        align="center"
                                        style={{ color: "#616161" }}
                                        component="th"
                                        scope="row"
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default BudgetUtilizations;