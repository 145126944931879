import {
  GET_DIVISIONS,
  GET_ACTIVE_DIVISIONS,
  CREATE_DIVISION,
  DIVISION_ERROR,
  GET_DIVISION_BY_ID,
  UPDATE_DIVISION,
  GET_DIVISIONS_BY_SERVICE,
  GET_DIVISIONS_BY_SERVICE_REQUEST,
  GET_DIVISIONS_BY_SERVICE_FAIL,
  DELETE_DIVISION,
  DELETE_DIVISION_FAIL,
  DELETE_DIVISION_REQUEST,
} from "../types";
import axios from "axios";

const URL = `/api/v1/admin/division`;
const token = localStorage.getItem("token:key");

export const getDivisions = () => async (dispatch) => {
  try {
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: GET_DIVISIONS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.response.data,
      });
      return error.response.data;
    } else if (error.request) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.request.data,
      });
      return error.request.data;
    } else {
      dispatch({
        type: DIVISION_ERROR,
        error: error.message,
      });
      return error.message;
    }
  }
};


export const getActiveDivisions = () => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/active/division`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ACTIVE_DIVISIONS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.response.data,
      });
      return error.response.data;
    } else if (error.request) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.request.data,
      });
      return error.request.data;
    } else {
      dispatch({
        type: DIVISION_ERROR,
        error: error.message,
      });
      return error.message;
    }
  }
};

export const createDivision = (data) => async (dispatch) => {
  try {
    const res = await axios.post(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: CREATE_DIVISION,
      payload: res.data.data,
    });
  } catch (error) {
    if (error && error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const getDivisionById = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/division/${id}`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_DIVISION_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.response.data,
      });
      return error.response.data;
    } else if (error.request) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.request.data,
      });
      return error.request.data;
    } else {
      dispatch({
        type: DIVISION_ERROR,
        error: error.message,
      });
      return error.message;
    }
  }
};

export const getDivisionsByService = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_DIVISIONS_BY_SERVICE_REQUEST,
    });
    const URL = `/api/v1/admin/service/division`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_DIVISIONS_BY_SERVICE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DIVISIONS_BY_SERVICE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateDivision = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/admin/division/${data.id}`;

    const res = await axios.put(URL, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: UPDATE_DIVISION,
      payload: res.data.data,
    });
  } catch (error) {
    if (error && error.response) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.response.data,
      });
      return error.response.data;
    } else if (error.request) {
      dispatch({
        type: DIVISION_ERROR,
        error: error.request.data,
      });
      return error.request.data;
    } else {
      dispatch({
        type: DIVISION_ERROR,
        error: error.message,
      });
      return error.message;
    }
  }
};

export const deleteDivision = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_DIVISION_REQUEST,
    });
    await axios.delete(`${URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_DIVISION,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_DIVISION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
