import React, { useState, useEffect, useCallback } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import {
    getPurchaseRequisitions,
    getPurchaseRequisition,
} from "../../../../actions/purchasingActions";
import { getProfile } from "../../../../actions/userActions";
// import { getDivisionMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../../actions/annualAction";


// Head of Division
import ApprovePurchaseRequisitionModal from "../modals/ApprovePurchaseRequisitionModal";
import RejectPurchaseRequisitionModal from "../modals/RejectPurchaseRequisitionModal";

// Procurement Division
import AcceptPurchaseRequisitionModal from "../modals/AcceptPurchaseRequisitionModal";
import DeclinePurchaseRequisitionModal from "../modals/DeclinePurchaseRequisitionModal";
import { formatAmount, formatCurrency } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
        marginBottom: 15,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.15) : darken("#fff", 0.80),
    },
    paper0: {
        padding: theme.spacing(2),
        marginBottom: 20,
        paddingBottom: 0,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.05) : darken("#fff", 0.80),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    requisitionType: "",
    purchaseType:"",
    shippingType:"",
    purpose: "",
    dateNeeded: "",
    items: [],
    totalQuantityNeeded: null,
};

const ViewPurchaseRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    // const { divItems } = useSelector((state) => state.monthlyBudget);

    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openAccept, setOpenAccept] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, purchase_requisition } = useSelector((state) => state.purchaseRequisition);
    const { annualBudgetLines } = useSelector((state) => state.annualBudget);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getPurchaseRequisitions());
        dispatch(getPurchaseRequisition(id));
        dispatch(getProfile)
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (purchase_requisition && purchase_requisition.purpose) || "",
            employee: (purchase_requisition && purchase_requisition.employee) || "",
            division: (purchase_requisition && purchase_requisition.division) || "",
            dateNeeded: (purchase_requisition && moment(purchase_requisition.dateNeeded).format("l")) || "",
            
            month: (purchase_requisition && purchase_requisition.month) || "",
            year: (purchase_requisition && purchase_requisition.year) || "",
            requisitionGroup: (purchase_requisition && purchase_requisition.requisitionGroup) || "",

            totalQuantityNeeded: (purchase_requisition && purchase_requisition.totalQuantityNeeded) || "",

            items: (purchase_requisition && purchase_requisition.items) || [],
            status: (purchase_requisition && purchase_requisition.status) || "",
            documentNo: (purchase_requisition && purchase_requisition.documentNo) || "",

            createdBy: (purchase_requisition && purchase_requisition.createdBy) || "",
            createdAt: (purchase_requisition && purchase_requisition.createdAt) || "",

            submittedBy: (purchase_requisition && purchase_requisition.submittedBy) || "",
            submittedAt: (purchase_requisition && purchase_requisition.submittedAt) || "",

            approvedBy: (purchase_requisition && purchase_requisition.approvedBy) || "",
            approvedAt: (purchase_requisition && purchase_requisition.approvedAt) || "",
            approveComment: (purchase_requisition && purchase_requisition.approveComment) || "",
            
            rejectedBy: (purchase_requisition && purchase_requisition.rejectedBy) || "",
            rejectedAt: (purchase_requisition && purchase_requisition.rejectedAt) || "",
            rejectComment: (purchase_requisition && purchase_requisition.rejectComment) || "",

            acceptedBy: (purchase_requisition && purchase_requisition.acceptedBy) || "",
            acceptedAt: (purchase_requisition && purchase_requisition.acceptedAt) || "",
            acceptComment: (purchase_requisition && purchase_requisition.acceptComment) || "",

            declinedBy: (purchase_requisition && purchase_requisition.declinedBy) || "",
            declinedAt: (purchase_requisition && purchase_requisition.declinedAt) || "",
            declineComment: (purchase_requisition && purchase_requisition.declineComment) || "",

        }));
    }, [purchase_requisition]);

    // const getDivisionMonthlyBudgetItemsCallback = useCallback(() => {
    //     let data = {
    //         year: state.year && state.year,
    //         month: state.month && state.month,
    //         serviceDivision: state && state.division._id,
    //     };
    //     dispatch(getDivisionMonthlyBudgetItems(data));
    // }, [dispatch, state]);

    // useEffect(() => {
    //     getDivisionMonthlyBudgetItemsCallback();
    // }, [getDivisionMonthlyBudgetItemsCallback]);

    // use annual budget
    const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
        if(state.division !==""){
            let data = {
                year: state.year && state.year,
                division: state && state.division._id,
            };
            dispatch(getDivisionYearAnnualBudgetLines(data));
        }
    }, [dispatch, state]);

    useEffect(() => {
        getDivisionYearAnnualBudgetLinesCallback();
    }, [getDivisionYearAnnualBudgetLinesCallback]);


    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // head of subdivision
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // procurement
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;
        
    const isDivManager = profile
        ? (
            (profile && (profile?.id === state.division?.manager)) || 
            (profile && (profile.division?.code === "LAC") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "FAS") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "SER") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "QHS") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "PMG") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "PPM") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "SCM") && (profile?.id === profile.division?.manager)) || 
            (profile && (profile.division?.code === "COM") && (profile?.id === profile.division?.manager))
        )
        : false;

    const isProcurement = profile
        ? profile &&
        profile.division &&
        ((profile?.division?.code === "PMG") || (profile?.division?.code === "SCM"))
        : false;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            {/* Head of subdivision */}
            <ApprovePurchaseRequisitionModal {...{ openApprove, handleCloseApprove, id }} />
            <RejectPurchaseRequisitionModal {...{ openReject, handleCloseReject, id }} />

            {/* Procurement */}
            <AcceptPurchaseRequisitionModal {...{ openAccept, handleCloseAccept, id }} />
            <DeclinePurchaseRequisitionModal {...{ openDecline, handleCloseDecline, id }} />
            
            <Typography variant="overline" className={classes.title}>
                View Purchase Requisition
            </Typography>

            <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/purchasing/requisition")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Paper variant="outlined" className={classes.status}>
                                <Typography variant="overline" className={classes.statusTitle}>
                                    Document No.
                                </Typography>
                                <Divider className={classes.divider2} orientation="vertical" />
                                {loading ? "Loading..." : state.documentNo}
                                
                            </Paper>
                        </Grid>
                        &nbsp;&nbsp;&nbsp;
                        <Grid item>
                            <Paper
                                variant="outlined"
                                className={clsx(
                                    state.status === "draft"
                                        ? classes.statusYellow
                                        : state.status === "submitted"
                                            ? classes.statusOrange
                                            : (state.status === "accepted" || state.status === "approved" || state.status === "confirmed" || state.status === "consented") ? classes.statusGreen
                                                : (state.status === "issued" || state.status === "collected" || state.status === "transferred" || state.status === "returned" || state.status === "received" || state.status === "completed")
                                                    ? classes.statusDarkGreen
                                                    : classes.statusRed,
                                    classes.status
                                )}
                            >
                                <Typography variant="overline" className={classes.statusTitle}>
                                    Status
                                </Typography>
                                <Divider className={classes.divider2} orientation="vertical" />
                                <Typography variant="overline" className={classes.statusTitle}>
                                    {loading ? "Loading..." : state.status}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>                        
                </Grid>
            </Grid>

            <Paper variant="outlined" square className={classes.paper}>
                <Paper variant="outlined" square className={classes.paper0}>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Employee</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={(state.createdBy && state.createdBy.userName) || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Division/Unit</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={(state.division && state.division.name) || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Document No</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={state.documentNo || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Date Needed</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                disabled
                                value={state.dateNeeded || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Month</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.month && state.month) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <label style={{ fontWeight: "bold" }}>Year</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={(state.year && state.year) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <label style={{ fontWeight: "bold" }}>Requisition Category</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    inputProps={{
                                        id: "requisitionGroup",
                                        name: "requisitionGroup",
                                    }}
                                    value={state.requisitionGroup || ""}
                                    disabled
                                >
                                    <MenuItem disabled>Select</MenuItem>
                                    <MenuItem value="Regular">Regular Requisition</MenuItem>
                                    <MenuItem value="Asset Requisition" disabled>Asset Acquisition</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <label style={{ fontWeight: "bold" }}>Purpose</label>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled
                                multiline
                                rows={4}
                                name="purpose"
                                value={state.purpose || ""}
                            />
                        </Grid>
                    </Grid>
                </Paper>

                <Typography variant="overline" className={classes.title2}>
                    Requisition Items
                </Typography>

                <Paper variant="outlined" square className={classes.paper0}>
                    {state.items.map((sitem, i) => (
                        <Grid
                            container
                            spacing={2}
                            className={classes.grid}
                            key={i}
                        >
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <label style={{ fontWeight: "bold" }}>Budget Item</label>
                                    <Select
                                            inputProps={{
                                                id: "budgetItem",
                                                name: "budgetItem",
                                            }}
                                            value={sitem.budgetItem}
                                            disabled
                                        >
                                            <MenuItem disabled>Select</MenuItem>
                                            {annualBudgetLines &&
                                                annualBudgetLines.map((item) => (
                                                    <MenuItem key={item._id} value={item._id}>
                                                        {item?.item?.name}
                                                    </MenuItem>
                                                ))
                                            }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Budget Amount (NGN)</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="budgetAmount"
                                    value={`${formatCurrency(sitem.budgetAmount, "en-NG", "NGN") || 0}` || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <label style={{ fontWeight: "bold" }}>Description</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="description"
                                    value={sitem.description || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Quantity Needed</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    type="text"
                                    name="quantityNeeded"
                                    value={`${sitem.quantityNeeded}` || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Unit of Measurement</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    type="text"
                                    name="unit"
                                    value={sitem.unit || ""}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
                
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={9} />
                    <Grid item xs={12} md={3}>
                        <TextField
                            label="Total Quantity Needed"
                            name="totalQuantityNeeded"
                            value={`${formatAmount(state.totalQuantityNeeded, "en-NG") || 0}` || ""}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                    style={{ marginBottom: 5 }}
                >
                    <Grid item>
                        <Typography variant="overline" className={classes.title2}>
                            Audit Trail
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Icon className="fas fa-clipboard-list" color="disabled" />
                    </Grid>
                </Grid>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Created By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdAt}</span>
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Submitted By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.submittedAt}</span>
                            </Paper>
                        </Grid>

                        {(state.approvedAt && state.approvedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Approved By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approveComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.rejectedAt && state.rejectedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Rejected By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.acceptedAt && state.acceptedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Accepted By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.declinedAt && state.declinedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Declined By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declineComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                {/* ACTION BUTTONS */}
                {(isDivManager || isAdmin) && state.status === "submitted" ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        This purchase requisition approval or rejection is to be done by the Head of the 
                                        Requesting Divison/Unit.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenApprove}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Approve
                                </Button>
                                <Button
                                    onClick={handleOpenReject}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Reject
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}

                {(isProcurement || isAdmin) && state.status === "approved" ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        This purchase requisition acceptance or decline is to be done by anyone in the Supply Chain Division (PMG or SCM).
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenAccept}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Accept
                                </Button>
                                <Button
                                    onClick={handleOpenDecline}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Decline
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}
            </Paper>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewPurchaseRequisition;
