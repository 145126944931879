import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Icon,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    IconButton,
    Fab,
    Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InvoiceViewModal from "../modals/InvoiceModal";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../atoms/Feedback";
import { uploadDocument } from "../../../../actions/vendorsActions";
import {
    updateReceivingInspection,
    submitReceivingInspection,
    getReceivingInspection,
    getReceivingInspections,
} from "../../../../actions/qualityActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 14,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper1: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.15) : darken("#fff", 0.80),
    },
    paper0: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        background: theme.palette.type === "light" ? darken("#fff", 0.05) : darken("#fff", 0.80),
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: theme.palette.type === "light" ? darken("#fff", 0.008) : darken("#fff", 0.80),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    date: "",
    items: [{
        description: "",
        quantityOrdered: "",
        quantityDelivered: "",
        quantityReceived: "",
        quotedAmount: "",
        unit: ""
    }],
};

const INIT_INVOICE = {
    description: "",
    invoice: "",
}

const EditReceiving = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [theUrl, setTheUrl] = useState("");
    const [openDoc, setOpenDoc] = useState(false);
    const [state, setState] = useState(INIT_STATE);
    const [invoice, setInvoice] = useState(INIT_INVOICE);
    const [invoiceFile, setInvoiceFile] = useState(undefined);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, receiving } = useSelector((state) => state.receiving);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getReceivingInspections());
        dispatch(getReceivingInspection(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (receiving && receiving.employee) || "",
            division: (receiving && receiving.division) || "",
            costType: (receiving && receiving.costType) || "",
            costCenter: (receiving && receiving.costCenter) || "",
            vendor: (receiving && receiving.vendor) || "",
            vendorName: (receiving && receiving.vendorName) || "",
            vendorEmail: (receiving && receiving.vendorEmail) || "",
            vendorPhone: (receiving && receiving.vendorPhone) || "",
            vendorContactName: (receiving && receiving.vendorContactName) || "",
            supplierId: (receiving && receiving.supplierId) || "",
            deliveryTime: (receiving && receiving.deliveryTime) || "",
            deliveryLocation: (receiving && receiving.deliveryLocation) || "",
            date: (receiving && moment(receiving.date).format("l")) || "",
            
            items: (receiving && receiving.items) || [],
            invoices: (receiving && receiving.invoices) || [],
            status: (receiving && receiving.status) || "",
            documentNo: (receiving && receiving.documentNo) || "",
            purchaseOrderNo: (receiving && receiving.purchaseOrderNo) || "",

            createdBy: (receiving && receiving.createdBy) || "",
            createdAt: (receiving && receiving.createdAt) || "",

            submittedBy: (receiving && receiving.submittedBy) || "",
            submittedAt: (receiving && receiving.submittedAt) || "",

            approvedBy: (receiving && receiving.approvedBy) || "",
            approvedAt: (receiving && receiving.approvedAt) || "",
            approveComment: (receiving && receiving.approveComment) || "",
            
            rejectedBy: (receiving && receiving.rejectedBy) || "",
            rejectedAt: (receiving && receiving.rejectedAt) || "",
            rejectComment: (receiving && receiving.rejectComment) || "",

            acceptedBy: (receiving && receiving.acceptedBy) || "",
            acceptedAt: (receiving && receiving.acceptedAt) || "",
            acceptComment: (receiving && receiving.acceptComment) || "",

            declinedBy: (receiving && receiving.declinedBy) || "",
            declinedAt: (receiving && receiving.declinedAt) || "",
            declineComment: (receiving && receiving.declineComment) || "",

        }));
    }, [receiving]);

    // console.log(receiving)

    // capture item changes
    const handleVendorItemChange = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            if((e.target.name === "quotedAmount") || (e.target.name === "quantityReceived") || (e.target.name === "quantityDelivered") || (e.target.name === "quantityNeeded") || (e.target.name === "quantityOrdered")){
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            id,
            path: `quality/receiving/view/${id}`,
        };
        await dispatch(submitReceivingInspection(data));
        await dispatch(getReceivingInspections);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Receiving and Inspection Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/quality/receivinginspection");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            deliveryLocation: state.deliveryLocation,
            deliveryTime: state.deliveryTime,
            items: state.items,
            id
        }
        await dispatch(updateReceivingInspection(data));
        setTimeout(async () => {
            await dispatch(getReceivingInspection(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Receiving and Inspection Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleInvoiceChange = (e) => {
        e.persist();
        setInvoice((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUploadInvoice = (e) => {
        const { files } = e.target;
        setInvoiceFile(files[0]);
    };

    useEffect(() => {
        const upload = async () => {
            if (invoiceFile) {
                const formData = new FormData();
                formData.append("doc", invoiceFile);
                let url = await new Promise((resolve, reject) => {
                        resolve(dispatch(uploadDocument(formData)));
                });
        
                if (url) {
                    setInvoice((prev) => ({ ...prev, invoice: url }));
                    setInvoiceFile(null);
                }
            }
        };
        upload();
    }, [invoiceFile, dispatch]);

    const handleAddInvoiceArray = (invoice) => {
        if (invoice.description !== "" && invoice.invoice !== "") {
            setState((prev) => ({
                ...prev,
                invoices: prev.invoices.concat([invoice]),
            }));
            setInvoice(INIT_INVOICE);
        }
    };

    const handleRemoveInvoiceArray = (i) => {
        setState((prev) => ({
            ...prev,
            invoices: prev.invoices.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleOpenDoc = (url) => {
        setTheUrl(null);
        if(url){
            setTheUrl(url);
            setOpenDoc(true);
        }
    };
    
    const handleCloseDoc = () => {
        setOpenDoc(false);
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected" ||
            state.status === "disapproved")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                
                <InvoiceViewModal {...{ openDoc, handleCloseDoc, docs: theUrl }} />

                <Typography variant="overline" className={classes.title}>
                    Edit Receiving and Inspection
                </Typography>
                
                <Grid container justify="space-between" alignItems="center" spacing={2} style={{ marginBottom: 20 }}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/quality/receivinginspection")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Paper variant="outlined" className={classes.status}>
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Document No.
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    {loading ? "Loading..." : state.documentNo}
                                    
                                </Paper>
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                            <Grid item>
                                <Paper
                                    variant="outlined"
                                    className={clsx(
                                        state.status === "draft"
                                            ? classes.statusYellow
                                            : state.status === "submitted"
                                                ? classes.statusOrange
                                                : state.status === "reviewed"
                                                    ? classes.statusGreen
                                                    : (state.status === "approved" || state.status === "sent")
                                                        ? classes.statusDarkGreen
                                                        : classes.statusRed,
                                        classes.status
                                    )}
                                >
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        Status
                                    </Typography>
                                    <Divider className={classes.divider2} orientation="vertical" />
                                    <Typography variant="overline" className={classes.statusTitle}>
                                        {loading ? "Loading..." : state.status}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <Paper variant="outlined" square spacing={3} className={classes.paper}>
                        <div>
                            <Paper variant="outlined" square className={classes.paper0}>
                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Employee Name"
                                            name="employeeName"
                                            variant="outlined"
                                            fullWidth
                                            value={(state.createdBy && state.createdBy.userName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Division/Unit"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            value={(state.division && state.division?.name) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Document No"
                                            name="documentNo"
                                            variant="outlined"
                                            fullWidth
                                            value={state.documentNo || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Purchase Order Number"
                                            name="purchaseOrderNo"
                                            variant="outlined"
                                            fullWidth
                                            value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Vendor"
                                            name="vendor"
                                            variant="outlined"
                                            fullWidth
                                            value={((state.vendor && state.vendor?.companyName) || (state.vendorName && state.vendorName)) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Supplier ID"
                                            name="supplierId"
                                            value={(state.supplierId && state.supplierId) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <DatePicker
                                            className={classes.marginBottom}
                                            autoOk
                                            format="DD/MM/yyyy"
                                            views={["year", "month", "date"]}
                                            label="Date"
                                            fullWidth
                                            disabled
                                            required
                                            inputVariant="outlined"
                                            value={(state.date && state.date) || ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Delivery Location"
                                            name="deliveryLocation"
                                            value={(state.deliveryLocation && state.deliveryLocation) || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Cost Type"
                                            name="costType"
                                            value={(state.costType && state.costType) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Cost Center"
                                            name="costCenter"
                                            value={(state.costCenter && state.costCenter?.name) || ""}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3} >
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel id="deliveryTime">Was The Product/Service Delivered On Time?</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "deliveryTime",
                                                    name: "deliveryTime",
                                                }}
                                                value={state.deliveryTime || ""}
                                                onChange={handleChange}
                                                disabled
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Purchase Order No"
                                            name="purchaseOrderNo"
                                            value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Typography variant="overline" className={classes.title2}>
                                Invoices
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper0} style={{ paddingBottom:0 }}>
                                {invoice ? (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Description of Invoice"
                                                name="description"
                                                value={invoice.description || ""}
                                                onChange={handleInvoiceChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth variant="outlined" required>
                                                <input
                                                    type="file"
                                                    label="Invoice File"
                                                    variant="outlined"
                                                    accept="image/*,.pdf"
                                                    id="file"
                                                    defaultValue={invoice.invoice || ""}
                                                    className={classes.input}
                                                    onChange={handleUploadInvoice}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <Fab
                                                color="primary"
                                                disabled={(invoice.description === "" || invoice.invoice === "")}
                                                onClick={() => handleAddInvoiceArray(invoice)}
                                            >
                                                <AddIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </Paper>

                            {(state.invoices && state.invoices.length > 0) && <>
                            
                            <Typography variant="overline" className={classes.title2}>
                                Uploaded Invoices
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper} style={{ paddingBottom:0 }}>
                                {state.invoices ? state.invoices.map((sinvoice, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={10}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Description of Invoice"
                                                name="description"
                                                value={sinvoice.description || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <IconButton
                                                disabled={sinvoice.invoice === null}
                                                onClick={() => handleOpenDoc(sinvoice.invoice)}
                                            >
                                                <Badge
                                                    color="secondary"
                                                    badgeContent={sinvoice.invoice ? 1 : null}
                                                >
                                                    <AttachFileIcon />
                                                </Badge>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <label>&nbsp;</label>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveInvoiceArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                )) : <>
                                <Typography variant="overline" className={classes.title2}>
                                    No Invoice Uploaded
                                </Typography>
                                </>}
                            </Paper> </> }

                            <Paper variant="outlined" square className={classes.paper}>
                                <Typography variant="overline" className={classes.title2}>
                                    --- Please fill in the quantity delivered from the vendor to you and the quantity you received, for each of the items. <br />
                                    --- Please enter 0 if none was delivered or received.
                                </Typography>
                            </Paper>

                            <>
                                <Typography variant="overline" className={classes.title2}>
                                    Receiving and Inspection Items
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper0}>
                                    {state.items.map((sitem, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                        >
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Description of Item"
                                                    name="description"
                                                    value={sitem.description || ""}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="PO Quoted Unit Amount"
                                                    name="quotedAmount"
                                                    disabled
                                                    value={sitem.quotedAmount || ""}
                                                    onChange={handleVendorItemChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Quantity Ordered"
                                                    name="quantityOrdered"
                                                    disabled
                                                    value={sitem.quantityOrdered || ""}
                                                    onChange={handleVendorItemChange(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Quantity Delivered"
                                                    name="quantityDelivered"
                                                    value={sitem.quantityDelivered || ""}
                                                    onChange={handleVendorItemChange(i)}
                                                />
                                            </Grid> 
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Quantity Received"
                                                    name="quantityReceived"
                                                    value={sitem.quantityReceived || ""}
                                                    onChange={handleVendorItemChange(i)}
                                                />
                                            </Grid>                           
                                        </Grid>
                                    ))}
                                </Paper>
                            </>

                            <Grid container alignItems="center" wrap="nowrap" spacing={1} style={{ marginBottom: 5 }}>
                                <Grid item>
                                    <Typography variant="overline" className={classes.title2}>
                                        Audit Trail
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Icon className="fas fa-clipboard-list" color="disabled" />
                                </Grid>
                            </Grid>

                            <Paper variant="outlined" square className={classes.paper0}>
                                <Grid container justify="space-between" alignItems="center" spacing={2}>
                                    {state.status === "draft" && (
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Created By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.createdAt}</span>
                                            </Paper>
                                        </Grid>
                                    )}
                                    {state.status !== "draft" && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Submitted By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.submittedAt}</span>
                                                </Paper>
                                            </Grid>
                                            {(state.rejectedAt && state.rejectedBy) && (
                                                <>
                                                    <Grid item xs={12} md={12}>
                                                        <Paper
                                                            className={classes.auditContainer}
                                                            component="div"
                                                            elevation={1}
                                                            variant="outlined"
                                                            square
                                                        >
                                                            <span className={classes.auditTitle}>Rejected By</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.rejectedAt}</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.auditTitle}>Comment</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.rejectComment}</span>
                                                        </Paper>
                                                    </Grid>
                                                </>
                                            )}
                                            {(state.declinedAt && state.declinedBy) && (
                                                <>
                                                    <Grid item xs={12} md={12}>
                                                        <Paper
                                                            className={classes.auditContainer}
                                                            component="div"
                                                            elevation={1}
                                                            variant="outlined"
                                                            square
                                                        >
                                                            <span className={classes.auditTitle}>Declined By</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.declinedAt}</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.auditTitle}>Comment</span>
                                                            <Divider orientation="vertical" flexItem />
                                                            <span className={classes.audit}>{state.declineComment}</span>
                                                        </Paper>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Paper>

                            {isCanEdit ? (
                                <Grid container justify="center">
                                    <Grid item>
                                        <ButtonGroup>
                                            <Button
                                                className={classes.saveBtn}
                                                color="primary"
                                                variant="contained"
                                                disabled={loading}
                                                onClick={handleSave}
                                            >
                                                {loading ? "Loading..." : "Save Changes"}
                                            </Button>
                                            <Button
                                                type="submit"
                                                className={classes.saveBtn2}
                                                variant="contained"
                                                onClick={handleSubmitHandler}
                                                disabled={loading}
                                            >
                                                Submit
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            ) : null}

                        </div>
                    </Paper>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditReceiving;
