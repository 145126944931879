module.exports = {    
    // Asset Acquisition
    CREATE_ASSET_ACQUISITION: "CREATE_ASSET_ACQUISITION",
    CREATE_ASSET_ACQUISITION_REQUEST: "CREATE_ASSET_ACQUISITION_REQUEST",
    CREATE_ASSET_ACQUISITION_FAIL: "CREATE_ASSET_ACQUISITION_FAIL",

    GET_ALL_ASSET_ACQUISITIONS: "GET_ALL_ASSET_ACQUISITIONS",
    GET_ALL_ASSET_ACQUISITIONS_REQUEST: "GET_ALL_ASSET_ACQUISITIONS_REQUEST",
    GET_ALL_ASSET_ACQUISITIONS_FAIL: "GET_ALL_ASSET_ACQUISITIONS_FAIL",

    GET_ASSET_ACQUISITION: "GET_ASSET_ACQUISITION",
    GET_ASSET_ACQUISITION_REQUEST: "GET_ASSET_ACQUISITION_REQUEST",
    GET_ASSET_ACQUISITION_FAIL: "GET_ASSET_ACQUISITION_FAIL",

    GET_AUTHORIZED_ASSET_ACQUISITIONS: "GET_AUTHORIZED_ASSET_ACQUISITIONS",
    GET_AUTHORIZED_ASSET_ACQUISITIONS_REQUEST: "GET_AUTHORIZED_ASSET_ACQUISITIONS_REQUEST",
    GET_AUTHORIZED_ASSET_ACQUISITIONS_FAIL: "GET_AUTHORIZED_ASSET_ACQUISITIONS_FAIL",

    UPDATE_ASSET_ACQUISITION: "UPDATE_ASSET_ACQUISITION",
    UPDATE_ASSET_ACQUISITION_REQUEST: "UPDATE_ASSET_ACQUISITION_REQUEST",
    UPDATE_ASSET_ACQUISITION_FAIL: "UPDATE_ASSET_ACQUISITION_FAIL",

    DELETE_ASSET_ACQUISITION: "DELETE_ASSET_ACQUISITION",
    DELETE_ASSET_ACQUISITION_REQUEST: "DELETE_ASSET_ACQUISITION_REQUEST",
    DELETE_ASSET_ACQUISITION_FAIL: "DELETE_ASSET_ACQUISITION_FAIL",

    SUBMIT_ASSET_ACQUISITION: "SUBMIT_ASSET_ACQUISITION",
    SUBMIT_ASSET_ACQUISITION_REQUEST: "SUBMIT_ASSET_ACQUISITION_REQUEST",
    SUBMIT_ASSET_ACQUISITION_FAIL: "SUBMIT_ASSET_ACQUISITION_FAIL",

    APPROVE_ASSET_ACQUISITION: "APPROVE_ASSET_ACQUISITION",
    APPROVE_ASSET_ACQUISITION_REQUEST: "APPROVE_ASSET_ACQUISITION_REQUEST",
    APPROVE_ASSET_ACQUISITION_FAIL: "APPROVE_ASSET_ACQUISITION_FAIL",

    DISAPPROVE_ASSET_ACQUISITION: "DISAPPROVE_ASSET_ACQUISITION",
    DISAPPROVE_ASSET_ACQUISITION_REQUEST: "DISAPPROVE_ASSET_ACQUISITION_REQUEST",
    DISAPPROVE_ASSET_ACQUISITION_FAIL: "DISAPPROVE_ASSET_ACQUISITION_FAIL",

    REVIEW_ASSET_ACQUISITION: "REVIEW_ASSET_ACQUISITION",
    REVIEW_ASSET_ACQUISITION_REQUEST: "REVIEW_ASSET_ACQUISITION_REQUEST",
    REVIEW_ASSET_ACQUISITION_FAIL: "REVIEW_ASSET_ACQUISITION_FAIL",

    REJECT_ASSET_ACQUISITION: "REJECT_ASSET_ACQUISITION",
    REJECT_ASSET_ACQUISITION_REQUEST: "REJECT_ASSET_ACQUISITION_REQUEST",
    REJECT_ASSET_ACQUISITION_FAIL: "REJECT_ASSET_ACQUISITION_FAIL",

    ACCEPT_ASSET_ACQUISITION: "ACCEPT_ASSET_ACQUISITION",
    ACCEPT_ASSET_ACQUISITION_REQUEST: "ACCEPT_ASSET_ACQUISITION_REQUEST",
    ACCEPT_ASSET_ACQUISITION_FAIL: "ACCEPT_ASSET_ACQUISITION_FAIL",

    DECLINE_ASSET_ACQUISITION: "DECLINE_ASSET_ACQUISITION",
    DECLINE_ASSET_ACQUISITION_REQUEST: "DECLINE_ASSET_ACQUISITION_REQUEST",
    DECLINE_ASSET_ACQUISITION_FAIL: "DECLINE_ASSET_ACQUISITION_FAIL",

    AUTHORIZE_ASSET_ACQUISITION: "AUTHORIZE_ASSET_ACQUISITION",
    AUTHORIZE_ASSET_ACQUISITION_REQUEST: "AUTHORIZE_ASSET_ACQUISITION_REQUEST",
    AUTHORIZE_ASSET_ACQUISITION_FAIL: "AUTHORIZE_ASSET_ACQUISITION_FAIL",

    DEAUTHORIZE_ASSET_ACQUISITION: "DEAUTHORIZE_ASSET_ACQUISITION",
    DEAUTHORIZE_ASSET_ACQUISITION_REQUEST: "DEAUTHORIZE_ASSET_ACQUISITION_REQUEST",
    DEAUTHORIZE_ASSET_ACQUISITION_FAIL: "DEAUTHORIZE_ASSET_ACQUISITION_FAIL",

    RESET_ASSET_ACQUISITION: "RESET_ASSET_ACQUISITION",


    // Asset Requisition
    CREATE_ASSET_REQUISITION: "CREATE_ASSET_REQUISITION",
    CREATE_ASSET_REQUISITION_REQUEST: "CREATE_ASSET_REQUISITION_REQUEST",
    CREATE_ASSET_REQUISITION_FAIL: "CREATE_ASSET_REQUISITION_FAIL",

    GET_ALL_ASSET_REQUISITIONS: "GET_ALL_ASSET_REQUISITIONS",
    GET_ALL_ASSET_REQUISITIONS_REQUEST: "GET_ALL_ASSET_REQUISITIONS_REQUEST",
    GET_ALL_ASSET_REQUISITIONS_FAIL: "GET_ALL_ASSET_REQUISITIONS_FAIL",

    GET_ASSET_REQUISITION: "GET_ASSET_REQUISITION",
    GET_ASSET_REQUISITION_REQUEST: "GET_ASSET_REQUISITION_REQUEST",
    GET_ASSET_REQUISITION_FAIL: "GET_ASSET_REQUISITION_FAIL",

    UPDATE_ASSET_REQUISITION: "UPDATE_ASSET_REQUISITION",
    UPDATE_ASSET_REQUISITION_REQUEST: "UPDATE_ASSET_REQUISITION_REQUEST",
    UPDATE_ASSET_REQUISITION_FAIL: "UPDATE_ASSET_REQUISITION_FAIL",

    DELETE_ASSET_REQUISITION: "DELETE_ASSET_REQUISITION",
    DELETE_ASSET_REQUISITION_REQUEST: "DELETE_ASSET_REQUISITION_REQUEST",
    DELETE_ASSET_REQUISITION_FAIL: "DELETE_ASSET_REQUISITION_FAIL",

    SUBMIT_ASSET_REQUISITION: "SUBMIT_ASSET_REQUISITION",
    SUBMIT_ASSET_REQUISITION_REQUEST: "SUBMIT_ASSET_REQUISITION_REQUEST",
    SUBMIT_ASSET_REQUISITION_FAIL: "SUBMIT_ASSET_REQUISITION_FAIL",

    APPROVE_ASSET_REQUISITION: "APPROVE_ASSET_REQUISITION",
    APPROVE_ASSET_REQUISITION_REQUEST: "APPROVE_ASSET_REQUISITION_REQUEST",
    APPROVE_ASSET_REQUISITION_FAIL: "APPROVE_ASSET_REQUISITION_FAIL",

    DISAPPROVE_ASSET_REQUISITION: "DISAPPROVE_ASSET_REQUISITION",
    DISAPPROVE_ASSET_REQUISITION_REQUEST: "DISAPPROVE_ASSET_REQUISITION_REQUEST",
    DISAPPROVE_ASSET_REQUISITION_FAIL: "DISAPPROVE_ASSET_REQUISITION_FAIL",

    REVIEW_ASSET_REQUISITION: "REVIEW_ASSET_REQUISITION",
    REVIEW_ASSET_REQUISITION_REQUEST: "REVIEW_ASSET_REQUISITION_REQUEST",
    REVIEW_ASSET_REQUISITION_FAIL: "REVIEW_ASSET_REQUISITION_FAIL",

    REJECT_ASSET_REQUISITION: "REJECT_ASSET_REQUISITION",
    REJECT_ASSET_REQUISITION_REQUEST: "REJECT_ASSET_REQUISITION_REQUEST",
    REJECT_ASSET_REQUISITION_FAIL: "REJECT_ASSET_REQUISITION_FAIL",

    ACCEPT_ASSET_REQUISITION: "ACCEPT_ASSET_REQUISITION",
    ACCEPT_ASSET_REQUISITION_REQUEST: "ACCEPT_ASSET_REQUISITION_REQUEST",
    ACCEPT_ASSET_REQUISITION_FAIL: "ACCEPT_ASSET_REQUISITION_FAIL",

    DECLINE_ASSET_REQUISITION: "DECLINE_ASSET_REQUISITION",
    DECLINE_ASSET_REQUISITION_REQUEST: "DECLINE_ASSET_REQUISITION_REQUEST",
    DECLINE_ASSET_REQUISITION_FAIL: "DECLINE_ASSET_REQUISITION_FAIL",


    AUTHORIZE_ASSET_REQUISITION: "AUTHORIZE_ASSET_REQUISITION",
    AUTHORIZE_ASSET_REQUISITION_REQUEST: "AUTHORIZE_ASSET_REQUISITION_REQUEST",
    AUTHORIZE_ASSET_REQUISITION_FAIL: "AUTHORIZE_ASSET_REQUISITION_FAIL",

    DEAUTHORIZE_ASSET_REQUISITION: "DEAUTHORIZE_ASSET_REQUISITION",
    DEAUTHORIZE_ASSET_REQUISITION_REQUEST: "DEAUTHORIZE_ASSET_REQUISITION_REQUEST",
    DEAUTHORIZE_ASSET_REQUISITION_FAIL: "DEAUTHORIZE_ASSET_REQUISITION_FAIL",

    
    RESET_ASSET_REQUISITION: "RESET_ASSET_REQUISITION",

};
