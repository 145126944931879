import * as types from "../types/inventory";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/inventory`;

//////////////////////////////////////////////
///////////// Store Management ///////////////
//////////////////////////////////////////////
export const createAStore = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_STORE_REQUEST,
        });
        const { data } = await axios.post(`${URL}/store`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_STORE,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllStores = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_STORES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/store`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_STORES,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_STORES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAStore = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_STORE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/store/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_STORE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_STORE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateAStore = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_STORE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/store/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_STORE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_STORE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteAStore = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_STORE_REQUEST,
        });
        await axios.delete(`${URL}/store/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_STORE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_STORE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
///////////// Location Management ////////////
//////////////////////////////////////////////
export const createLocation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_LOCATION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/location`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_LOCATION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getLocations = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_LOCATIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/location`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_LOCATIONS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_LOCATIONS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getLocation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_LOCATION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/location/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_LOCATION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_LOCATION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getLocationItems = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_LOCATION_ITEMS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/location/items/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_LOCATION_ITEMS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_LOCATION_ITEMS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateLocation = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_LOCATION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/location/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_LOCATION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_LOCATION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteLocation = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_LOCATION_REQUEST,
        });
        await axios.delete(`${URL}/location/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_LOCATION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_LOCATION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
//////////// Inventory Categories ////////////
//////////////////////////////////////////////
export const createInventoryCategory = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_INVENTORY_CATEGORY_REQUEST,
        });
        const { data } = await axios.post(`${URL}/category`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_INVENTORY_CATEGORY,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_INVENTORY_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
export const getAllInventoryCategories = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_INVENTORY_CATEGORIES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/category`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_INVENTORY_CATEGORIES,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_INVENTORY_CATEGORIES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getInventoryCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_INVENTORY_CATEGORY_REQUEST,
        });
        const { data } = await axios.get(`${URL}/category/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_INVENTORY_CATEGORY,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_INVENTORY_CATEGORY_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getInventoryCategoryItems = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_INVENTORY_CATEGORY_ITEMS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/item/category/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_INVENTORY_CATEGORY_ITEMS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_INVENTORY_CATEGORY_ITEMS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
export const updateInventoryCategory = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_INVENTORY_CATEGORY_REQUEST,
        });
        const { data } = await axios.put(`${URL}/category/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_INVENTORY_CATEGORY,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_INVENTORY_CATEGORY_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteInventoryCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_INVENTORY_CATEGORY_REQUEST,
        });
        await axios.delete(`${URL}/category/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_INVENTORY_CATEGORY,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_INVENTORY_CATEGORY_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
/////////////// Inventory Item ///////////////
//////////////////////////////////////////////
export const createInventoryItem = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_INVENTORY_ITEM_REQUEST,
        });
        const { data } = await axios.post(`${URL}/item`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_INVENTORY_ITEM,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_INVENTORY_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllInventoryItems = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_INVENTORY_ITEMS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/item`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_INVENTORY_ITEMS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_INVENTORY_ITEMS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getInventoryItem = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_INVENTORY_ITEM_REQUEST,
        });
        const { data } = await axios.get(`${URL}/item/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_INVENTORY_ITEM,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_INVENTORY_ITEM_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateInventoryItem = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_INVENTORY_ITEM_REQUEST,
        });
        const { data } = await axios.put(`${URL}/item/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_INVENTORY_ITEM,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_INVENTORY_ITEM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteInventoryItem = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_INVENTORY_ITEM_REQUEST,
        });
        await axios.delete(`${URL}/item/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_INVENTORY_ITEM,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_INVENTORY_ITEM_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
//////// Receiving and Inspection ////////////
//////////////////////////////////////////////
export const createReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.post(`${URL}/receipt`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_INVENTORY_RECEIPT,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_INVENTORY_RECEIPT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_INVENTORY_RECEIPT,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteReceipt = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_INVENTORY_RECEIPT_REQUEST,
        });
        await axios.delete(`${URL}/receipt/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_INVENTORY_RECEIPT,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllReceipts = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_INVENTORY_RECEIPTS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receipt`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_INVENTORY_RECEIPTS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_INVENTORY_RECEIPTS_FAIL,
            payload: error
        });
    }
};

export const getReceipt = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.get(`${URL}/receipt/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_INVENTORY_RECEIPT,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_INVENTORY_RECEIPT_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_INVENTORY_RECEIPT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_INVENTORY_RECEIPT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_INVENTORY_RECEIPT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_INVENTORY_RECEIPT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const receiveReceipt = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.RECEIVE_INVENTORY_RECEIPT_REQUEST,
        });
        const { data } = await axios.put(`${URL}/receipt/receive/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.RECEIVE_INVENTORY_RECEIPT,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.RECEIVE_INVENTORY_RECEIPT_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
/////////// Inventory Requisition ////////////
//////////////////////////////////////////////
export const createRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_REQUISITION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/requisition`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_REQUISITION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const getInventoryReport = (query) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_QUERY_REQUISITIONS_REQUEST,
        });
        const data  = await axios.post(`${URL}/query/requisition`, query, {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_QUERY_REQUISITIONS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_QUERY_REQUISITIONS_FAIL,
            error:
            error.response && error.response.data.error
                ? error.response.data.error
                : error.request
                ? error.request.data.error
                : error.message,
        });
    }
};

export const checkRequisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_CHECK_REQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/check/requisition`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_CHECK_REQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_CHECK_REQUISITIONS_FAIL,
            payload: error
        });
    }
};

export const getRequisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_REQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_REQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_REQUISITIONS_FAIL,
            payload: error
        });
    }
};

export const getRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_REQUISITION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_REQUISITION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_REQUISITION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_REQUISITION_REQUEST,
        });
        await axios.delete(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_REQUISITION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const consentRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CONSENT_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/consent/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CONSENT_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CONSENT_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const refuseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REFUSE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REFUSE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REFUSE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const confirmRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CONFIRM_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CONFIRM_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CONFIRM_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const issueRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ISSUE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/issue/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ISSUE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ISSUE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const cancelRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CANCEL_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/cancel/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CANCEL_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CANCEL_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const transferRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.TRANSFER_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/transfer/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.TRANSFER_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.TRANSFER_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const collectRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.COLLECT_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/collect/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.COLLECT_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.COLLECT_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const leaveRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.LEAVE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/abandon/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.LEAVE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.LEAVE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const receiveRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.RECEIVE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/receive/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.RECEIVE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.RECEIVE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const returnRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.RETURN_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/return/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.RETURN_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.RETURN_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const completeRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.COMPLETE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/complete/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.COMPLETE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.COMPLETE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

//////////////////////////////////////////////
///////////////// Gate Pass //////////////////
//////////////////////////////////////////////
export const createGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_GATEPASS_REQUEST,
        });
        const { data } = await axios.post(`${URL}/gatepass`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_GATEPASS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllGatePasses = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_GATEPASSES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/gatepass`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_GATEPASSES,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_GATEPASSES_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getGatePass = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_GATEPASS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/gatepass/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_GATEPASS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_GATEPASS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_GATEPASS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteGatePass = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_GATEPASS_REQUEST,
        });
        await axios.delete(`${URL}/gatepass/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_GATEPASS,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const reviewGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const grantGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.GRANT_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/grant/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GRANT_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GRANT_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const completeGatePass = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.COMPLETE_GATEPASS_REQUEST,
        });
        const { data } = await axios.put(`${URL}/gatepass/complete/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.COMPLETE_GATEPASS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.COMPLETE_GATEPASS_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


//////////////////////////////////////////////
////////// Goods Received Note ///////////////
//////////////////////////////////////////////
export const createGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_GRN_REQUEST,
        });
        const { data } = await axios.post(`${URL}/grn`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.CREATE_GRN_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAllGRNS = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_GRNS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/grn`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_GRNS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_GRNS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getGRN = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_GRN_REQUEST,
        });
        const { data } = await axios.get(`${URL}/grn/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_GRN,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_GRN_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_GRN,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const submitGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteGRN = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_GRN_REQUEST,
        });
        await axios.delete(`${URL}/grn/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_GRN,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const acceptGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const approveGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const rejectGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const declineGRN = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_GRN_REQUEST,
        });
        const { data } = await axios.put(`${URL}/grn/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_GRN,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_GRN_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
