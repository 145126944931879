import {
  GET_ALL_USERS,
  GET_PPM_USERS,
  GET_USER,
  LOGIN_USER,
  VERIFY_USER,
  LOGIN_USER_ERROR,
  GET_PROFILE,
  LOGOUT,
  ERROR,
  CREATE_USER_ACCOUNT,
  GET_USER_BY_TOKEN,
  SET_PASSWORD_BY_TOKEN,
  UPDATE_USER,
  DELETE_USER,
  DISABLE_USER,
  UPLOAD_USER_PHOTO,
  UPDATE_USER_PROFILE,
  UPLOAD_USER_PHOTO_ERROR,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_TWO_FACTOR,
  USERS_ERROR,
  AUTH_ERROR,
  RESEND_PASSWORD_LINK,
  ENABLE_2FA,
  ENABLE_2FA_REQUEST,
  ENABLE_2FA_FAIL,
  DISABLE_2FA,
  DISABLE_2FA_FAIL,
  DISABLE_2FA_REQUEST,
  BULK_RESEND_PASSWORD_LINK,
  BULK_RESEND_PASSWORD_LINK_REQUEST,
  BULK_RESEND_PASSWORD_LINK_FAIL,
  UPLOAD_SIGNATURE,
  UPLOAD_SIGNATURE_FAIL,
  UPLOAD_SIGNATURE_REQUEST,
  REMOVE_SIGNATURE,
  REMOVE_SIGNATURE_FAIL,
  REMOVE_SIGNATURE_REQUEST,
  REMOVE_PROFILE_PICTURE,
  REMOVE_PROFILE_PICTURE_FAIL,
  REMOVE_PROFILE_PICTURE_REQUEST,
} from "../types";

const initialState = {
  user: {},
  users: [],
  ppmusers: [],
  profile: {},
  error: null,
  uploadError: null,
  authError: null,
  photo: "",
  message: "",
  loading: false,
  signature: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
      };
    case VERIFY_USER:
      return {
        ...state,
        user: action.payload,
      };

    case LOGOUT:
      return "LOGOUT USER";
    // return {
    //   // ...state,
    //   // user: {},
    //   // users: [],
    //   // profile: {},
    //   // error: null,
    // };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload.data,
      };
    case GET_PPM_USERS:
      return {
        ...state,
        ppmusers: action.payload.data,
      };

    case CREATE_USER_ACCOUNT:
      return {
        ...state,
        users: [action.payload.data, ...state.users],
      };
    case UPLOAD_USER_PHOTO:
      return {
        ...state,
      };
    case UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        message: "Your Password has been changed successfully.",
        // profile: action.payload.message,
      };
    case UPDATE_USER_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_USER_PASSWORD_RESET":
      return {
        ...state,
        error: null,
      };
    case SET_PASSWORD_BY_TOKEN:
      return {
        ...state,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.data,
      };
    case GET_USER_BY_TOKEN:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        message: action.payload,
      };
    case UPDATE_TWO_FACTOR:
      return {
        ...state,
        message: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case DISABLE_USER:
      return {
        ...state,
        message: action.payload,
      };
    case RESEND_PASSWORD_LINK:
      return {
        ...state,
        message: action.payload.message,
      };

    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case USERS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.error,
      };
    case UPLOAD_USER_PHOTO_ERROR:
      return {
        ...state,
        uploadError: action.error,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case ENABLE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ENABLE_2FA:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case ENABLE_2FA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DISABLE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DISABLE_2FA:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case DISABLE_2FA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BULK_RESEND_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BULK_RESEND_PASSWORD_LINK:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case BULK_RESEND_PASSWORD_LINK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_SIGNATURE:
      return {
        ...state,
        loading: false,
        signature: action.payload.userImage,
      };
    case UPLOAD_SIGNATURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REMOVE_SIGNATURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_SIGNATURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case REMOVE_SIGNATURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case REMOVE_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PROFILE_PICTURE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case REMOVE_PROFILE_PICTURE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "RESET_PROFILE_PICTURE_MESSAGE":
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
};

export default usersReducer;
